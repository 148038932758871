import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/solid";
import { CakeIcon, GiftIcon } from "@heroicons/react/outline";
import { RadioGroup } from "@headlessui/react";
import { loadStripe } from "@stripe/stripe-js";
import amplitude from "amplitude-js";

import Navbar from "./Navbar";

// const stripePromise = loadStripe("pk_test_XSkknx7URDZSHH1oMJUpLkVg00DlIUgbCb"); // DEV
const stripePromise = loadStripe("pk_live_RbyYFBGfLGhcINXV8lX91QJB001ulsAc9i");

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const frequencies = [
  {
    price: "$20",
    value: "monthly",
    label: "Monthly",
    priceSuffix: "/month",
    priceID: "price_1Nw9BBFFY57ee5ETn7NZ6aCL",
    // priceID: "price_1Ibau6FFY57ee5ETPpKDnCVS", // DEV
  },
  {
    price: "$13",
    value: "annually",
    label: "Annually",
    priceSuffix: "/year",
    priceID: "price_1NvrCsFFY57ee5ETyeIZPTiQ",
    // priceID: "price_1NvrRRFFY57ee5ETLnJiBQhA", // DEV
  },
];

const Pricing = () => {
  let siderRef = useRef();

  useEffect(() => {
    amplitude.getInstance().logEvent("viewed pricing page");
  }, []);

  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);
  const [frequency, setFrequency] = useState(frequencies[1]);

  const tiers = [
    {
      name: "Lite",
      id: "tier-lite",
      onClickFunc: null,
      priceMonthly: "Free",
      showPerMonth: false,
      description: "For individuals who are just getting started.",
      features: [
        "15 meetings per month",
        "Unlimited meeting length",
        "AI generated notes and action items",
        "Video recordings expire after 2 months",
      ],
      mostPopular: false,
      ctaText: "",
    },
    {
      name: "Pro",
      id: "tier-pro",
      onClickFunc: () => initiateStripeCheckout(userEmail, frequency),
      priceMonthly: frequency.price,
      showPerMonth: true,
      description: "For rockstars and with full calendars.",
      features: [
        "Unlimited meetings per month",
        "Unlimited meeting length",
        "AI generated notes and action items",
        "Video recorings expire after 1 year",
        "Up to 500 GBs of storage per team",
      ],
      mostPopular: true,
      ctaText: "Buy Plan",
    },
    {
      name: "Team",
      id: "tier-business",
      onClickFunc: () => {
        amplitude.getInstance().logEvent("clicked contact us");
        window.open("https://www.scribbl.co/pages/contact", "_blank");
      },
      priceMonthly: "Contact Us",
      showPerMonth: false,
      description: "For teams that are serious about meetings.",
      features: [
        "Unlimited meetings per month",
        "Unlimited meeting length",
        "AI generated notes and action items",
        "Video recordings never expire",
        "Unlimited team size",
        "Unlimited meetings per team",
        "Unified billing",
        "Dedicated customer success",
      ],
      mostPopular: false,
      ctaText: "Contact Us",
    },
  ];

  return (
    <Navbar
      setUserEmail={setUserEmail}
      setUser={setUser}
      isPublic={false}
      siderRef={siderRef}
    >
      <div className="bg-white py-10 overflow-y-scroll w-full">
        {new Date() < new Date("2024-02-01") && (
          <div className="flex -mt-10 items-center gap-x-6 bg-brand-green-lighter1 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
            <p className="text-sm leading-6 text-white">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <GiftIcon
                  className="h-4 w-4 inline-block mr-1.5 mt-[-3px]"
                  aria-hidden="true"
                />
                NEW YEARS PROMOTION! Get $60 off{" "}
                <strong>Annual Scribbl Pro</strong> plans ($8 /user/mo) use code{" "}
                <strong>SCRIBBL2024</strong>. Offer lasts until Jan 31.&nbsp;
              </a>
            </p>
            <div className="flex flex-1 justify-end"></div>
          </div>
        )}
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Get the Most Out of Your Meetings
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            Upgrade to get unlimited meetings and other great features.
          </p>
          <div
            className="bg-brand-green/5 border border-brand-green-lighter2 rounded-lg mt-8 text-center p-2"
            style={{ minHeight: "56px" }}
          >
            <span className="inline-block m-1.5 text-brand-green mr-2 text-sm">
              <CakeIcon
                className="h-5 w-5 inline-block text-brand-green mr-2"
                aria-hidden="true"
              />
              Earn 15 premium credits for free! Write us a 5 star review. Once
              completed send mike@scribbl.co a screenshot of the review.
            </span>
            <a
              href="https://chrome.google.com/webstore/detail/google-meet-transcripts-b/kmjmlilenakedodldceipdnmmnfkahni"
              target="_blank"
              rel="noreferrer"
              className=" inline-block bg-brand-green text-white shadow-sm hover:bg-brand-green-lighter1 rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-green cursor-pointer"
            >
              <img
                alt="Chrome store icon"
                className="w-4 inline mr-1.5"
                src="/chrome-icon.svg"
              ></img>
              Chrome Store
            </a>
          </div>

          <div className="mt-16 flex justify-center">
            <RadioGroup
              value={frequency}
              onChange={setFrequency}
              className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
            >
              <RadioGroup.Label className="sr-only">
                Payment frequency
              </RadioGroup.Label>
              {frequencies.map((option) => (
                <RadioGroup.Option
                  key={option.value}
                  value={option}
                  className={({ checked }) =>
                    classNames(
                      checked ? "bg-brand-green text-white" : "text-gray-500",
                      "cursor-pointer rounded-full px-2.5 py-1"
                    )
                  }
                >
                  <span>{option.label}</span>
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </div>
          <div className="isolate mx-auto mt-8 grid max-w-md grid-cols-1 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {tiers.map((tier, tierIdx) => (
              <div
                key={tier.id}
                className={classNames(
                  tier.mostPopular ? "lg:z-10 lg:rounded-b-none" : "lg:mt-8",
                  tierIdx === 0 ? "lg:rounded-r-none" : "",
                  tierIdx === tiers.length - 1 ? "lg:rounded-l-none" : "",
                  "flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10"
                )}
              >
                <div>
                  <div className="flex items-center justify-between gap-x-4">
                    <h3
                      id={tier.id}
                      className={classNames(
                        tier.mostPopular ? "text-brand-green" : "text-gray-900",
                        "text-lg font-semibold leading-8"
                      )}
                    >
                      {tier.name}
                    </h3>
                    {tier.mostPopular ? (
                      <p className="rounded-full bg-brand-green/10 px-2.5 py-1 text-xs font-semibold leading-5 text-brand-green">
                        Most popular
                      </p>
                    ) : null}
                  </div>
                  <p className="mt-4 text-sm leading-6 text-gray-600">
                    {tier.description}
                  </p>
                  <p className="mt-6 flex items-baseline gap-x-1">
                    <span className="text-4xl font-bold tracking-tight text-gray-900">
                      {tier.priceMonthly}
                    </span>
                    {tier.showPerMonth && (
                      <span className="text-sm font-semibold leading-6 text-gray-600">
                        /user /month
                      </span>
                    )}
                  </p>
                  <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className="h-6 w-5 flex-none text-brand-green"
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
                {tier.ctaText && (
                  <a
                    onClick={tier.onClickFunc}
                    aria-describedby={tier.id}
                    target="_blank"
                    className={classNames(
                      tier.mostPopular
                        ? "bg-brand-green text-white shadow-sm hover:bg-brand-green-lighter1"
                        : "text-brand-green ring-1 ring-inset ring-brand-green hover:bg-gray-50",
                      "mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-green cursor-pointer"
                    )}
                  >
                    {tier.ctaText}
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default Pricing;

async function initiateStripeCheckout(userEmail, frequency) {
  if (userEmail) {
    amplitude.getInstance().logEvent("clicked buy plan");
    const stripe = await stripePromise;

    let checkoutParams = {
      priceId: frequency.priceID,
      quantity: 1,
      clientReferenceId: "",
    };

    if (window.Rewardful && window.Rewardful.referral) {
      checkoutParams.clientReferenceId = window.Rewardful.referral;
    }

    const response = await fetch("https://backend.scribbl.co/checkout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(checkoutParams),
      credentials: "include", // This line ensures cookies are sent with the request
    });

    const session = await response.json();
    const result = await stripe.redirectToCheckout({
      sessionId: session.sessionId,
    });

    if (result.error) {
      // Handle any errors that occurred during redirection
      console.error(result.error.message);
    }
  } else {
    console.error("userEmail is null");
  }
}
