import React from "react";
import { RewindIcon, FastForwardIcon } from "@heroicons/react/outline";

import { PlayIcon, PauseIcon } from "@heroicons/react/solid";

const VideoControls = (props) => {
  return (
    <div className="col-span-1 flex flex-col justify-center items-center">
      <div className="flex justify-center">
        <RewindIcon
          className="ml-2 -mr-1 h-10 w-10 text-gray-400 cursor-pointer hover:hover:text-gray-500"
          aria-hidden="true"
          onClick={() =>
            (props.videoPlayerRef.current.currentTime =
              props.videoPlayerRef.current.currentTime -
              props.videoPlayerRef.current.duration / 20)
          }
        />
        {props.videoPlayerRef.current && props.videoPlayerRef.current.paused ? (
          <PlayIcon
            className="ml-2 -mr-1 h-10 w-10 text-brand-green-lighter1 cursor-pointer hover:text-brand-green"
            aria-hidden="true"
            onClick={() => {
              props.videoPlayerRef.current.play();
              props.setCurrentTab(props.tabs, props.setTabs, "Transcript");
            }}
          />
        ) : (
          <PauseIcon
            className="ml-2 -mr-1 h-10 w-10 text-brand-green-lighter1 cursor-pointer hover:text-brand-green"
            onClick={() => props.videoPlayerRef.current.pause()}
          />
        )}
        <FastForwardIcon
          className="ml-2 -mr-1 h-10 w-10 text-gray-400 cursor-pointer hover:text-gray-500"
          aria-hidden="true"
          style={{
            cursor: "pointer",
          }}
          onClick={() =>
            (props.videoPlayerRef.current.currentTime =
              props.videoPlayerRef.current.currentTime +
              props.videoPlayerRef.current.duration / 20)
          }
        />
      </div>
      {/* Video speed levels */}
      <div className="ml-4 flex flex-col items-center justify-center">
        <div className="flex items-center">
          <span
            className={
              props.videoPlayerRef.current.playbackRate === 1
                ? "px-1 text-brand-green-lighter2"
                : "px-1 text-gray-400 hover:text-brand-green-lighter2"
            }
            style={
              props.videoPlayerRef.current.playbackRate === 1
                ? {
                    fontWeight: "900",
                    cursor: "pointer",
                  }
                : {
                    fontWeight: "900",
                    cursor: "pointer",
                  }
            }
            onClick={() => {
              if (props.videoPlayerRef.current) {
                props.videoPlayerRef.current.playbackRate = 1;
              }
            }}
          >
            1x
          </span>
          <span
            className={
              props.videoPlayerRef.current.playbackRate === 1.5
                ? "px-1 text-brand-green-lighter2"
                : "px-1 text-gray-400 hover:text-brand-green-lighter2"
            }
            style={
              props.videoPlayerRef.current.playbackRate === 1.5
                ? {
                    fontWeight: "900",
                    cursor: "pointer",
                  }
                : {
                    fontWeight: "900",
                    cursor: "pointer",
                  }
            }
            onClick={() => {
              if (props.videoPlayerRef.current) {
                props.videoPlayerRef.current.playbackRate = 1.5;
              }
            }}
          >
            1.5x
          </span>
          <span
            className={
              props.videoPlayerRef.current.playbackRate === 2
                ? "px-1 text-brand-green-lighter2"
                : "px-1 text-gray-400 hover:text-brand-green-lighter2"
            }
            style={
              props.videoPlayerRef.current.playbackRate === 2
                ? {
                    fontWeight: "900",
                    cursor: "pointer",
                  }
                : {
                    fontWeight: "900",
                    cursor: "pointer",
                  }
            }
            onClick={() => {
              if (props.videoPlayerRef.current) {
                props.videoPlayerRef.current.playbackRate = 2;
              }
            }}
          >
            2x
          </span>
          <span
            className={
              props.videoPlayerRef.current.playbackRate === 3
                ? "px-1 text-brand-green-lighter2"
                : "px-1 text-gray-400 hover:text-brand-green-lighter2"
            }
            style={
              props.videoPlayerRef.current.playbackRate === 3
                ? {
                    fontWeight: "900",
                    cursor: "pointer",
                  }
                : {
                    fontWeight: "900",
                    cursor: "pointer",
                  }
            }
            onClick={() => {
              if (props.videoPlayerRef.current) {
                props.videoPlayerRef.current.playbackRate = 3;
              }
            }}
          >
            3x
          </span>
        </div>
      </div>
    </div>
  );
};

export default VideoControls;
