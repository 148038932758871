import React, {useState, useEffect} from "react";
import { ChatAlt2Icon } from "@heroicons/react/outline";
//Function that will convert the "date" so it can be sorted
const ParseTime = (time) => {
  const [timePart, modifier] = time.split(" ");
  let [hours, minutes] = timePart.split(":");
  hours = parseInt(hours, 10);
  minutes = parseInt(minutes, 10);
  // add 12 hours to PM times
  if (modifier === "PM" && hours < 12){
    hours += 12;
  }
  //subtract 12 hours from midnight
  if (modifier === "PM" && hours === 12){
    hours = 0;
  }

  return new Date(0, 0, 0, hours, minutes);
};
const MeetingChat = (props) => {
  //Using a state variable to implement the sorting logic
  const[sortedMessages, setSortedMessages] = useState([]);
  useEffect(() => {
    if(props.chatMessages) {
      const sorted = [...props.chatMessages].sort((a,b)=> {
        const time1 = ParseTime(a.date);
        const time2 = ParseTime(b.date);
        return time1 - time2;
      });
      setSortedMessages(sorted);
    }
  }, [props.chatMessages]);
  const renderTextWithLinks = (inputText) => {
    // Regular expression to match http and https URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Split the text into parts based on URLs
    const parts = inputText.split(urlRegex);

    return parts.map((part, index) =>
      urlRegex.test(part) ? (
        <a
          href={part}
          key={index}
          target="_blank"
          rel="noopener noreferrer"
          className="text-brand-green"
        >
          {part}
        </a>
      ) : (
        part
      )
    );
  };
  return (
    <div className="m-3 rounded-md bg-white flex px-4 py-3 border border-gray-200 sm:px-6">
      <div className="w-full -ml-4 -mt-2 p-3 min-h-[250px]">
        {sortedMessages && sortedMessages.length !== 0 ? (
          <div>
            {sortedMessages.map((chatMessage) => {
              return (
                <div className="text-sm mb-5" key={chatMessage.ID}>
                  <div>
                    <div className="font-bold text-gray-700 inline mr-3">
                      {chatMessage.user}
                    </div>
                    <div className="text-gray-500 inline">
                      {chatMessage.date}
                    </div>
                  </div>
                  <div className="text-gray-700 break-words">
                    {renderTextWithLinks(chatMessage.message)}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="">
            {" "}
            <ChatAlt2Icon
              className="h-12 w-12 text-gray-400 mx-auto mt-16"
              aria-hidden="true"
            />
            <div className="text-center text-gray-700 mt-3 text-sm">
              No meeting chat was recorded for this meeting.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MeetingChat;
