import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Navbar from "./Navbar";
import amplitude from "amplitude-js";
import {
  PlayIcon,
  PauseIcon,
  RewindIcon,
  FastForwardIcon,
} from "@heroicons/react/solid";

const SharedClip = () => {
  let videoPlayerRef = useRef();

  const { uuid } = useParams();

  const [sharedClip, setSharedClip] = useState(null);
  // On Component Mount, get the shared clip
  useEffect(() => {
    async function asyncGetSetClip() {
      const sharedClip = await getSharedClipAndRecording(uuid);
      setSharedClip(sharedClip);
      var eventProperties = {
        clip_id: sharedClip.clip.ID,
        recording_uuid: sharedClip.recording.uuid,
      };
      amplitude.getInstance().logEvent("er clip viewed", eventProperties);
    }
    asyncGetSetClip();
  }, []);

  return (
    <Navbar isPublic={true}>
      {sharedClip && sharedClip.clip && !sharedClip.clip.isProcessing ? (
        <div className="flex flex-col justify-center m-auto">
          <h3 className="text-3xl text-center font-extrabold">
            {sharedClip.clip.name}
          </h3>
          {/* Video */}
          <video
            style={{ maxHeight: "55vh", minHeight: "55vh" }}
            controls
            ref={videoPlayerRef}
            src={sharedClip.clipURL}
            onTimeUpdate={() => {
              // if (updateVideoTime) {
              //   setCurrentVideoTime(videoPlayerRef.current.currentTime);
              // }
            }}
          ></video>
          {/* Playbar section */}
          <div className="pt-2">
            {/* Video Control panel */}
            {videoPlayerRef.current && (
              <div className="pt-3 flex justify-center">
                {/* Video controls */}
                <div className="col-span-1 flex flex-col justify-center items-center">
                  <div className="flex justify-center">
                    <RewindIcon
                      className="ml-3 -mr-1 h-16 w-16 text-stone-600 cursor-pointer"
                      aria-hidden="true"
                      onClick={() =>
                        (videoPlayerRef.current.currentTime =
                          videoPlayerRef.current.currentTime -
                          videoPlayerRef.current.duration / 20)
                      }
                    />
                    {videoPlayerRef.current && videoPlayerRef.current.paused ? (
                      <PlayIcon
                        className="ml-3 -mr-1 h-16 w-16 text-brand-red cursor-pointer"
                        aria-hidden="true"
                        onClick={() => videoPlayerRef.current.play()}
                      />
                    ) : (
                      <PauseIcon
                        className="ml-3 -mr-1 h-16 w-16 text-brand-red  cursor-pointer"
                        onClick={() => videoPlayerRef.current.pause()}
                      />
                    )}
                    <FastForwardIcon
                      className="ml-3 -mr-1 h-16 w-16 text-stone-600 cursor-pointer"
                      aria-hidden="true"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        (videoPlayerRef.current.currentTime =
                          videoPlayerRef.current.currentTime +
                          videoPlayerRef.current.duration / 20)
                      }
                    />
                  </div>
                  {/* Video speed levels */}
                  <div className="flex flex-col items-center justify-center">
                    <div className="flex items-center">
                      <span
                        className={
                          videoPlayerRef.current.playbackRate === 1
                            ? "px-1 text-brand-red"
                            : "px-1 text-stone-400 hover:text-brand-red"
                        }
                        style={
                          videoPlayerRef.current.playbackRate === 1
                            ? {
                                fontSize: "large",
                                fontWeight: "900",
                                cursor: "pointer",
                              }
                            : {
                                fontSize: "large",
                                fontWeight: "900",
                                cursor: "pointer",
                              }
                        }
                        onClick={() => {
                          if (videoPlayerRef.current) {
                            videoPlayerRef.current.playbackRate = 1;
                          }
                        }}
                      >
                        1x
                      </span>
                      <span
                        className={
                          videoPlayerRef.current.playbackRate === 1.5
                            ? "px-1 text-brand-red"
                            : "px-1 text-stone-400 hover:text-brand-red"
                        }
                        style={
                          videoPlayerRef.current.playbackRate === 1.5
                            ? {
                                fontSize: "large",
                                fontWeight: "900",
                                cursor: "pointer",
                              }
                            : {
                                fontSize: "large",
                                fontWeight: "900",
                                cursor: "pointer",
                              }
                        }
                        onClick={() => {
                          if (videoPlayerRef.current) {
                            videoPlayerRef.current.playbackRate = 1.5;
                          }
                        }}
                      >
                        1.5x
                      </span>
                      <span
                        className={
                          videoPlayerRef.current.playbackRate === 2
                            ? "px-1 text-brand-red"
                            : "px-1 text-stone-400 hover:text-brand-red"
                        }
                        style={
                          videoPlayerRef.current.playbackRate === 2
                            ? {
                                fontSize: "large",
                                fontWeight: "900",
                                cursor: "pointer",
                              }
                            : {
                                fontSize: "large",
                                fontWeight: "900",
                                cursor: "pointer",
                              }
                        }
                        onClick={() => {
                          if (videoPlayerRef.current) {
                            videoPlayerRef.current.playbackRate = 2;
                          }
                        }}
                      >
                        2x
                      </span>
                      <span
                        className={
                          videoPlayerRef.current.playbackRate === 3
                            ? "px-1 text-brand-red"
                            : "px-1 text-stone-400 hover:text-brand-red"
                        }
                        style={
                          videoPlayerRef.current.playbackRate === 3
                            ? {
                                fontSize: "large",
                                fontWeight: "900",
                                cursor: "pointer",
                              }
                            : {
                                fontSize: "large",
                                fontWeight: "900",
                                cursor: "pointer",
                              }
                        }
                        onClick={() => {
                          if (videoPlayerRef.current) {
                            videoPlayerRef.current.playbackRate = 3;
                          }
                        }}
                      >
                        3x
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        sharedClip &&
        sharedClip.clip &&
        sharedClip.clip.isProcessing && (
          <div className="w-full min-h-full pt-16 pb-12 flex flex-col bg-white">
            <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex-shrink-0 flex justify-center">
                <a href="/" className="inline-flex">
                  <span className="sr-only">Scribbl logo</span>
                  <img
                    className="h-28 w-auto"
                    src="/Logo_OnWhite.png"
                    alt="Scribbl Logo"
                  />
                </a>
              </div>
              <div className="py-16">
                <div className="text-center">
                  <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                    Your clip is still processing
                  </h1>
                  <p className="mt-2 text-base text-gray-500">
                    We're still processing your clip, this shouldn't take long
                    (just a couple minutes).
                    <p className="text-muted">
                      Try refreshing the page in a moment.
                    </p>
                  </p>
                  <div className="mt-6">
                    <a
                      href="/"
                      className="text-base font-medium text-brand-green hover:text-brand-red"
                    >
                      Go back home<span aria-hidden="true"> &rarr;</span>
                    </a>
                  </div>
                </div>
              </div>
            </main>
            <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
              <nav className="flex justify-center space-x-4">
                <a
                  href="mailto:mike@scribbl.co"
                  className="text-sm font-medium text-gray-500 hover:text-gray-600"
                >
                  Contact Support
                </a>
                {/* <span
                  className="inline-block border-l border-gray-300"
                  aria-hidden="true"
                /> */}
                {/* <a
                  href="#"
                  className="text-sm font-medium text-gray-500 hover:text-gray-600"
                >
                  Status
                </a>
                <span
                  className="inline-block border-l border-gray-300"
                  aria-hidden="true"
                />
                <a
                  href="#"
                  className="text-sm font-medium text-gray-500 hover:text-gray-600"
                >
                  Twitter
                </a> */}
              </nav>
            </footer>
          </div>
        )
      )}
    </Navbar>
  );
};

export default SharedClip;

const getSharedClipAndRecording = async (uuid) => {
  try {
    // put fetch here
    const getSharedClipResponse = await fetch(
      `https://backend.scribbl.co/enhanced-recording/${uuid}/share-clip`,
      {
        method: "GET",
        credentials: "include", // This line ensures cookies are sent with the request
      }
    );
    const sharedClip = await getSharedClipResponse.json();
    return sharedClip;
  } catch (exception) {
    console.log(exception);
  }
};
