import React from "react";

const TranscriptSearch = (props) => {
  let currentIndex = 0;
  props.searchResults.forEach((result, index) => {
    if (result.currentItem) {
      currentIndex = index;
    }
  });

  return (
    <div>
      <div className="relative w-full max-w-[600px]">
        <div>
          {/* Tx search */}
          <div
            style={{
              border: "1px solid lightgray",
              borderRadius: "3px",
              boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.25)",
            }}
            className="flex items-center w-full h-[40px] bg-white"
          >
            <input
              onFocus={() => {
                if (props.setCurrentTab) {
                  props.setCurrentTab(props.tabs, props.setTabs, "Transcript");
                }
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter" && e.shiftKey) {
                  prevResult(props, currentIndex);
                } else if (e.key === "Enter") {
                  nextResult(props, currentIndex);
                }
              }}
              className="borderless w-full"
              autoComplete="off"
              type="text"
              name="search-transcript"
              id="search-tx"
              placeholder="Search Transcript"
              value={props.searchTerm}
              onChange={(e) => {
                props.setSearchTerm(e.target.value);
              }}
            />
            <span
              className="pr-[14px] text-[gray] w-[39px]"
              style={{ fontSize: "14px" }}
            >
              {props.searchResults.length ? currentIndex + 1 : 0}/
              {props.searchResults.length}
            </span>
            <div
              className="h-[70%]"
              style={{
                borderLeft: "1px solid lightgray",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            ></div>
            {/* Up arrow */}
            <button
              onClick={() => {
                prevResult(props, currentIndex);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="gray"
                className="w-4 h-4 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 15.75l7.5-7.5 7.5 7.5"
                />
              </svg>
            </button>
            {/* Down arrow */}
            <button
              onClick={() => {
                nextResult(props, currentIndex);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="gray"
                className="w-4 h-4 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </button>

            {/* Clear search */}
            <button onClick={() => props.setSearchTerm("")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="gray"
                className="w-4 h-4 mx-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TranscriptSearch;

const nextResult = (props, currentIndex) => {
  if (currentIndex + 1 < props.searchResults.length) {
    props.searchResults[currentIndex + 1].currentItem = true;
    props.searchResults[currentIndex].currentItem = false;
    const nextID = props.searchResults[currentIndex + 1].ID;

    // go through topics and update the scroll tos
    props.topics.forEach((topic) => {
      topic.transcriptItems.forEach((txItem, index) => {
        txItem.scrollTo = false;
        txItem.currentItem = false;

        if (nextID === txItem.ID) {
          topic.open = true;
          txItem.scrollTo = true;
          txItem.currentItem = true;
          const updatedTxItem = structuredClone(txItem);
          topic.transcriptItems[index] = updatedTxItem;
        }
      });
    });

    const updatedTopics = structuredClone(props.topics);
    props.setTopics(updatedTopics);
  }
};

const prevResult = (props, currentIndex) => {
  if (currentIndex - 1 >= 0) {
    props.searchResults[currentIndex - 1].currentItem = true;
    props.searchResults[currentIndex].currentItem = false;
    const nextID = props.searchResults[currentIndex - 1].ID;

    // go through topics and update the scroll tos
    props.topics.forEach((topic) => {
      topic.transcriptItems.forEach((txItem, index) => {
        txItem.scrollTo = false;
        txItem.currentItem = false;

        if (nextID === txItem.ID) {
          txItem.scrollTo = true;
          txItem.currentItem = true;
          const updatedTxItem = structuredClone(txItem);
          topic.transcriptItems[index] = updatedTxItem;
        }
      });
    });

    const updatedTopics = structuredClone(props.topics);
    props.setTopics(updatedTopics);
  }
};
