import React, { useState, useEffect, useRef } from "react";
import {
  BriefcaseIcon,
  ClipboardCheckIcon,
  UserCircleIcon,
  CollectionIcon,
} from "@heroicons/react/outline";

const CollectionTypeStep = ({
  collectionTitle,
  setCollectionTitle,
  collectionType,
  setCollectionType,
}) => {
  const [hasFocus, setHasFocus] = useState(true);
  const titleInputRef = useRef(null);

  useEffect(() => {
    if (titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, []);

  const collectionTypes = [
    {
      name: "Client Collection",
      icon: BriefcaseIcon,
      description: "Organize meetings and insights for a specific client",
    },
    {
      name: "Product Research",
      icon: ClipboardCheckIcon,
      description: "Gather feedback and data for product development",
    },
    {
      name: "1 on 1",
      icon: UserCircleIcon,
      description: "Track individual meetings with team members",
    },
    {
      name: "Simple meeting list",
      icon: CollectionIcon,
      description: "A general collection for various meetings",
    },
  ];

  return (
    <div className="space-y-6">
      <div className="text-center">
        <h2 className="text-2xl font-bold text-gray-900">
          Create New Collection
        </h2>
        <p className="mt-2 text-sm text-gray-500 max-w-md mx-auto">
          Choose a type and give your collection a name to get started.
        </p>
      </div>
      <div className="space-y-4">
        <div>
          <label
            htmlFor="collectionTitle"
            className="block text-sm font-medium text-gray-700"
          >
            Collection Title
          </label>
          <input
            type="text"
            id="collectionTitle"
            ref={titleInputRef}
            value={collectionTitle}
            autoComplete="off"
            onChange={(e) => setCollectionTitle(e.target.value)}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            className={`mt-1 block w-full border ${
              collectionTitle.trim() === ""
                ? "border-red-500"
                : "border-gray-300"
            } rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-brand-turq focus:border-brand-turq sm:text-sm`}
            placeholder="Enter collection title"
          />
          {collectionTitle.trim() === "" && hasFocus === false && (
            <p className="mt-2 text-sm text-red-600">
              Collection title is required
            </p>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-8">
        {collectionTypes.map(({ name, icon: Icon, description }) => (
          <button
            key={name}
            onClick={() => setCollectionType(name)}
            className={`relative flex flex-col items-start p-4 rounded-lg transition-all duration-200 ease-in-out ${
              collectionType === name
                ? "bg-brand-turq bg-opacity-10 border-2 border-brand-turq"
                : "bg-white border border-gray-200 hover:border-brand-turq hover:bg-brand-turq hover:bg-opacity-5"
            }`}
          >
            <div
              className={`rounded-full p-2 ${
                collectionType === name
                  ? "bg-brand-turq"
                  : "bg-brand-turq bg-opacity-10"
              }`}
            >
              <Icon
                className={`h-6 w-6 ${
                  collectionType === name ? "text-white" : "text-brand-turq"
                }`}
                aria-hidden="true"
              />
            </div>
            <h3 className="mt-3 text-lg font-medium text-gray-900">{name}</h3>
            <p className="mt-1 text-sm text-gray-500">{description}</p>
            <span
              className={`absolute top-2 right-2 h-3 w-3 rounded-full ${
                collectionType === name
                  ? "bg-brand-turq"
                  : "bg-transparent border border-gray-300"
              }`}
              aria-hidden="true"
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default CollectionTypeStep;
