import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import amplitude from "amplitude-js";

import "../index.css";

const OnboardingCreateTeam = (props) => {
  const navigate = useNavigate();

  const [teamName, setTeamName] = useState("");
  const [allowJoin, setAllowJoin] = useState(true);
  const [domain, setDomain] = useState(null);

  useEffect(() => {
    function getDomainFromEmail() {
      if (props.email) {
        let domainName = props.email.split("@").pop();
        setDomain(domainName);
      }
    }

    amplitude.getInstance().logEvent("er onboarding create team step");
    getDomainFromEmail();
  }, []);

  useEffect(() => {
    if (props.orgObj) {
      props.nextStep("inviteTeam");
    }
  }, [props.orgObj]);

  async function createOrg() {
    // TODO: later we should disable the button so it doesn't attempt to send the request again
    const joinOrgResponse = await fetch(`https://backend.scribbl.co/orgs`, {
      method: "POST",
      body: JSON.stringify({
        email: props.email,
        orgName: teamName,
        isPrivate: !allowJoin,
        OrgDomain: domain,
      }),
      credentials: "include", // This line ensures cookies are sent with the request
    });

    var newOrg = await joinOrgResponse.json();
    amplitude.getInstance().logEvent("er onboarding create team success");
    props.setOrg(newOrg);
  }

  return (
    <div className="flex">
      <div className="grow flex justify-center flex-col items-center">
        <div className="w-[27rem]">
          <span className="text-center font-thin text-4xl text-gray-600">
            Create a new team
          </span>
          <div className="mt-6">
            <div>
              <label
                htmlFor="teamName"
                className="block text-sm font-medium text-gray-700"
              >
                Company or Organization Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="teamName"
                  className="shadow-sm focus:border-brand-green block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Enter your company or organization's name"
                  value={teamName}
                  onChange={(event) => setTeamName(event.target.value)}
                />
              </div>
            </div>
          </div>
          {!props.usingEmailProvider && (
            <div className="relative flex items-start mt-6">
              <div className="flex items-center h-5">
                <input
                  id="allowTeamJoin"
                  aria-describedby="allow-team-join"
                  name="allowTeamJoin"
                  type="checkbox"
                  className="h-4 w-4 text-brand-green border-gray-300 rounded"
                  checked={allowJoin}
                  onChange={(event) => setAllowJoin(event.target.checked)}
                />
              </div>
              <div className="ml-3 text-sm">
                <span id="comments-description" className="text-gray-500">
                  Allow users who sign up with an{" "}
                  <span className="font-semibold">@{domain}</span> email to join
                  my team.
                </span>
              </div>
            </div>
          )}
          <div className="mt-6 text-right">
            {props.usingEmailProvider || !props.hasTeamsToJoin ? (
              <button
                onClick={() => {
                  amplitude
                    .getInstance()
                    .logEvent("er onboarding create team skip");
                  props.nextStep("downloadExtension");
                }}
                type="button"
                className="mr-3 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Skip
              </button>
            ) : (
              <button
                onClick={() => props.nextStep("joinTeam")}
                type="button"
                className="mr-3 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Back
              </button>
            )}
            <button
              onClick={() => createOrg()}
              type="button"
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker2 focus:outline-none"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingCreateTeam;
