import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import amplitude from "amplitude-js";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";

import "../index.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const OnboardingInviteTeam = (props) => {
  const navigate = useNavigate();
  const [org, setOrg] = useState(null);
  const [emails, setEmails] = useState([]);

  const ONBOARDING_METHOD_CHROME_STORE = 2; // user signed up via chrome store

  // the user should only land here if they have a valid org associated

  useEffect(() => {
    async function fetchOrg() {
      // if the user creates an org, this won't be the most up to date org which is another reason why
      // we should use the org in props in that scenario
      console.log("fetching org"); //remove
      const orgID = props.userObj.org.ID;
      const orgResponse = await fetch(
        `https://backend.scribbl.co/orgs/${orgID}?email=${props.email}`,
        {
          method: "GET",
          credentials: "include", // This line ensures cookies are sent with the request
        }
      );

      let orgResp = await orgResponse.json();
      console.log("fetching org response", orgResp); //remove
      setOrg(orgResp);
    }

    amplitude.getInstance().logEvent("er onboarding invite team step");

    if (props.orgObj) {
      setOrg(props.orgObj);
      console.log("the org is ", props.orgObj);
    } else {
      fetchOrg();
    }
  }, []);

  async function sendInvites() {
    const sendInviteResponse = fetch(`https://backend.scribbl.co/orgs/invite`, {
      method: "POST",
      body: JSON.stringify({
        email: props.email,
        orgID: org.ID,
        emailAddresses: emails,
      }),
      credentials: "include", // This line ensures cookies are sent with the request
    });

    sendInviteResponse.then((response) => {
      if (response.ok) {
        amplitude.getInstance().logEvent("er onboarding invite team success");
        if (
          props.onboardingMethod &&
          props.onboardingMethod === ONBOARDING_METHOD_CHROME_STORE
        ) {
          props.nextStep("tryInMeet");
        } else {
          props.nextStep("downloadExtension");
        }
      }
    });
  }

  return (
    <div className="flex">
      <div className="grow flex justify-center flex-col items-center">
        {org && (
          <div className="w-[27rem]">
            <div className="font-thin text-4xl text-gray-600">
              Invite your team to{" "}
              <span className="font-medium">{org.name}</span>
            </div>
            <span className="font-thin text-md text-gray-500">
              Invite team members to view and contribute to secure meetings in
              your team library.
            </span>
            <div className="mt-6">
              <div className="font-semibold text-sm mb-2 text-gray-600">
                Email Addresses
              </div>
              <ReactMultiEmail
                placeholder="Enter email adresses of your teammates"
                emails={emails}
                className="font-sans text-xl"
                style={{ minHeight: "150px" }}
                onChange={(e) => {
                  setEmails(e);
                }}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      {email}
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                      </span>
                    </div>
                  );
                }}
              />
            </div>
            <div className="mt-6 text-right">
              <button
                onClick={() => {
                  amplitude
                    .getInstance()
                    .logEvent("er onboarding invite team skip");
                  if (
                    props.onboardingMethod &&
                    props.onboardingMethod === ONBOARDING_METHOD_CHROME_STORE
                  ) {
                    props.nextStep("tryInMeet");
                  } else {
                    props.nextStep("downloadExtension");
                  }
                }}
                type="button"
                className="mr-3 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Skip for now
              </button>
              <button
                onClick={() => sendInvites()}
                type="button"
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker2 focus:outline-none"
              >
                Send Invites
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingInviteTeam;
