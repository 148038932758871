import React, { useState, useEffect, Fragment } from "react";
import { ExternalLinkIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import { Transition, Menu } from "@headlessui/react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import amplitude from "amplitude-js";

let hoverbarTimeout = null;

const SpeakerBars = (props) => {
  const [screenSharingChunks, setScreenSharingChunks] = useState([]);

  const [previewImage, setPreviewImage] = useState(null);

  const [hoverBoxLeft, setHoverBoxLeft] = useState(0);
  const [hoverBoxTop, setHoverBoxTop] = useState(0);

  const [staticHoverLine, setStaticHoverLine] = useState(0);

  const [thumbnailMap, setThumbnailMap] = useState(null);

  const [seeMoreSpeakers, setSeeMoreSpeakers] = useState(false);

  useEffect(() => {
    if (props.recording) {
      // Speaker + SS chunks
      const speakerChunks = calculateSpeakerChunks(
        props.recording,
        props.videoPlayerRef
      );
      props.setSpeakerChunksMap(speakerChunks);

      const ssChunks = calculateScreenSharingChunks(
        props.recording,
        props.videoPlayerRef
      );
      setScreenSharingChunks(ssChunks);

      // Thumbnails
      let newThumbnailMap = {};
      props.recording.thumbnailURLs &&
        props.recording.thumbnailURLs.forEach((thumbnailURL) => {
          const dashSplits = new URL(thumbnailURL).pathname.split("-");
          const imageNumber = dashSplits[dashSplits.length - 1].replace(
            ".png",
            ""
          );
          newThumbnailMap[imageNumber] = thumbnailURL;
        });
      setThumbnailMap(newThumbnailMap);
    }
  }, []);

  useEffect(() => {
    setStaticHoverLine(
      calculateStaticHoverLine(props.videoPlayerRef, props.hoverBoxRef)
    );
  }, [props.videoPlayerRef.current.currentTime]);

  function calculateSpeakerChunks(recording, videoPlayerRef) {
    let speakerChunks = {};

    let recordingOffset = recording.recordingTimeOffset;
    if (!recordingOffset) {
      recordingOffset = recording.start_time; //Is start time the start time of the topic relative to the meeting or recording. We may need to use recording.time??
    }

    // Create entry for speaker in map if not exists
    recording.transcriptItems.forEach((txItem) => {
      if (!speakerChunks[txItem.speaker]) {
        speakerChunks[txItem.speaker] = {
          chunks: [],
          totalDuration: 0,
          name: txItem.speaker,
        };
      }
    });

    Object.values(speakerChunks).forEach((speaker) => {
      let speakerChunksInProgress = [];
      recording.transcriptItems.forEach((txItem) => {
        if (txItem.speaker === speaker.name) {
          speakerChunksInProgress.push({
            start: txItem.itemStart - recordingOffset,
            // If end & start are the same, add a second to the end
            finish:
              txItem.itemStart === txItem.itemEnd
                ? txItem.itemEnd - recordingOffset + 1
                : txItem.itemEnd - recordingOffset,
            type: "speaking",
          });
        }
      });

      // Sort by start time
      speakerChunksInProgress.sort((a, b) => (a.start > b.start ? 1 : -1));

      // go back and fill in empty spots
      let currentPosition = 0;

      for (let i = 0; i < speakerChunksInProgress.length; i++) {
        const chunkInProgress = speakerChunksInProgress[i];

        if (chunkInProgress.start > currentPosition) {
          // add a chunk prior
          speakerChunksInProgress.splice(i, 0, {
            start: currentPosition,
            finish: chunkInProgress.start,
            type: "blank",
          });
          i = i - 1;
        }

        // if last item, check if end spot needed
        if (
          i === speakerChunksInProgress.length &&
          chunkInProgress.finish < videoPlayerRef.current.duration
        ) {
          // add one last chunk to the endsz
          speakerChunksInProgress.push({
            start: chunkInProgress.finish - recordingOffset,
            finish: videoPlayerRef.current.duration,
            type: "blank",
          });
        }
        currentPosition = chunkInProgress.finish;
      }
      speaker.chunks = speakerChunksInProgress;
    });

    return speakerChunks;
  }

  function calculateScreenSharingChunks(recording, videoPlayerRef) {
    let ssChunks = [];

    let recordingOffset = recording.recordingTimeOffset;
    if (!recordingOffset) {
      recordingOffset = recording.start_time;
    }

    if (recording.screenSharingData) {
      recording.screenSharingData.forEach((ssData, index) => {
        if (
          index + 1 === recording.screenSharingData.length &&
          ssData.sharingEnded === 0
        ) {
          ssData.sharingEnded =
            videoPlayerRef.current.duration + recordingOffset;
        }
        ssChunks.push({
          start: ssData.sharingStarted - recordingOffset,
          finish: ssData.sharingEnded - recordingOffset,
          type: "speaking",
        });
      });
    }

    // Sort by start time
    ssChunks.sort((a, b) => (a.start > b.start ? 1 : -1));

    // go back and fill in empty spots
    let currentPosition = 0;

    for (let i = 0; i < ssChunks.length; i++) {
      const chunkInProgress = ssChunks[i];

      if (chunkInProgress.start > currentPosition) {
        // add a chunk prior
        ssChunks.splice(i, 0, {
          start: currentPosition,
          finish: chunkInProgress.start,
          type: "blank",
        });
        i = i - 1;
      }

      // if last item, check if end spot needed
      if (
        i === ssChunks.length &&
        chunkInProgress.finish < videoPlayerRef.current.duration
      ) {
        // add one last chunk to the endsz
        ssChunks.push({
          start: chunkInProgress.finish - recordingOffset,
          finish: videoPlayerRef.current.duration,
          type: "blank",
        });
      }
      currentPosition = chunkInProgress.finish;
    }
    return ssChunks;
  }

  function calculateStaticHoverLine(videoPlayerRef, hoverBoxRef) {
    const videoPercent =
      videoPlayerRef.current.currentTime / videoPlayerRef.current.duration;
    return hoverBoxRef.current.clientWidth * videoPercent;
  }

  return (
    <div className="pt-2 relative">
      {previewImage && hoverBoxLeft && (
        <img
          alt="Preview of video at hovered position"
          style={{
            position: "absolute",
            left: `${hoverBoxLeft - 80}px`,
            top: `-80px`,
          }}
          src={previewImage}
        />
      )}

      {/* Speakers playbar section (wrapped in hoverbar) */}
      {props.speakerChunksMap && (
        <div className="py-2 bg-gray-50 border border-gray-100 rounded-md ">
          {props.topicsBar && (
            <TopicsBar
              hoverBoxRef={props.hoverBoxRef}
              topicChunks={props.topicChunks}
              recording={props.recording}
              videoPlayerRef={props.videoPlayerRef}
              topics={props.topics}
              isPublic={props.isPublic}
              setEditingTopicIndex={props.setEditingTopicIndex}
              editingTopicIndex={props.editingTopicIndex}
              setEditingTopicTimes={props.setEditingTopicTimes}
              setTopicChunkToDelete={props.setTopicChunkToDelete}
              setOpenDeleteTopicModal={props.setOpenDeleteTopicModal}
              videoDuration={props.videoDuration}
            />
          )}
          <div
            ref={props.hoverBoxRef}
            className="flex flex-col min-h-[65px]"
            onClick={(e) => {
              const refX = props.hoverBoxRef.current.getBoundingClientRect().x;

              const hoveredPercentage =
                (e.clientX - refX) /
                props.hoverBoxRef.current.getBoundingClientRect().width;

              props.videoPlayerRef.current.currentTime =
                props.videoPlayerRef.current.duration * hoveredPercentage;
              setPreviewImage(null);
            }}
            onMouseLeave={(e) => {
              clearTimeout(hoverbarTimeout);
              hoverbarTimeout = null;
              setPreviewImage(null);
              setHoverBoxTop(null);
              setHoverBoxLeft(null);
              setStaticHoverLine(
                calculateStaticHoverLine(
                  props.videoPlayerRef,
                  props.hoverBoxRef
                )
              );
            }}
            onMouseMove={(e) => {
              if (!hoverbarTimeout) {
                hoverbarTimeout = setTimeout(() => {
                  clearTimeout(hoverbarTimeout);
                  hoverbarTimeout = null;

                  // Jesus christ.  This sucked to figure out
                  const refX =
                    props.hoverBoxRef.current.getBoundingClientRect().x;

                  const hoveredPercentage =
                    (e.clientX - refX) / props.hoverBoxRef.current.offsetWidth;
                  const imageNumberToShow = parseInt(
                    props.videoPlayerRef.current.duration * hoveredPercentage
                  );
                  if (
                    imageNumberToShow > 0 &&
                    thumbnailMap[imageNumberToShow]
                  ) {
                    setTimeout(() => {
                      setPreviewImage(thumbnailMap[imageNumberToShow]);
                    }, 500);

                    setHoverBoxTop(
                      props.hoverBoxRef.current.getBoundingClientRect().top -
                        90 * 2
                    );

                    setHoverBoxLeft(
                      e.clientX -
                        props.hoverBoxRef.current.getBoundingClientRect().x
                    );
                  }
                }, 50);
              }
            }}
          >
            {/* Dynamic black bar */}
            <div
              className=""
              style={
                hoverBoxLeft && hoverBoxTop
                  ? {
                      borderLeft: "3px solid #374151",
                      height: props.hoverBoxRef.current
                        ? props.hoverBoxRef.current.getBoundingClientRect()
                            .height
                        : 0,
                      position: "absolute",
                      top: `${
                        props.hoverBoxRef.current.getBoundingClientRect().y
                      }`,
                      left: `${hoverBoxLeft}px`,
                      zIndex: 1000,
                    }
                  : {}
              }
            ></div>
            {/* Static black bar */}
            <div
              className="flex"
              style={
                !hoverBoxLeft && !hoverBoxTop
                  ? {
                      borderLeft: "3px solid #374151",
                      height: props.hoverBoxRef.current
                        ? props.hoverBoxRef.current.getBoundingClientRect()
                            .height
                        : 0,
                      position: "absolute",
                      left: `${staticHoverLine}px`,
                      zIndex: 1000,
                    }
                  : {}
              }
            ></div>
            {/* Screen sharing chunks */}
            {screenSharingChunks.length ? (
              <div className="w-full flex flex-col text-sm mt-1">
                <span className="text-gray-700 font-medium mb-0.5">
                  Screen Sharing
                </span>
                <div className="w-full flex h-2.5 bg-gray-100">
                  {screenSharingChunks.map((chunk, index) => {
                    let barClasses = "";
                    if (chunk.type === "speaking") {
                      barClasses += "bg-gray-400 rounded-xl";
                    } else {
                      barClasses += "bg-gray-100";
                    }
                    return (
                      <div
                        className={barClasses}
                        key={index}
                        style={{
                          width: `${
                            ((chunk.finish - chunk.start) /
                              props.videoPlayerRef.current.duration) *
                            100
                          }%`,
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}

            {/* Speaker bars */}
            {Object.values(props.speakerChunksMap).map(
              (speakerEntry, index) => {
                if (
                  (!seeMoreSpeakers &&
                    (screenSharingChunks.length ? 1 : 0) + index + 1 <= 3) ||
                  seeMoreSpeakers
                ) {
                  return (
                    <div
                      key={index}
                      className="w-full flex flex-col text-sm mt-1"
                    >
                      <span className="text-gray-700 font-medium mb-0.5">
                        {speakerEntry.name}
                      </span>
                      <div className="w-full flex h-2.5 bg-gray-100">
                        {Object.values(speakerEntry.chunks).map(
                          (chunk, index) => {
                            let barClasses = "";
                            if (chunk.type === "speaking") {
                              barClasses += "bg-gray-400 rounded-xl";
                            } else {
                              barClasses += "bg-gray-100";
                            }
                            return (
                              <div
                                key={index}
                                className={barClasses}
                                style={{
                                  width: `${
                                    ((chunk.finish - chunk.start) /
                                      props.videoPlayerRef.current.duration) *
                                    100
                                  }%`,
                                }}
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                }
              }
            )}
          </div>
          <div className="text-right mt-3">
            {!seeMoreSpeakers ? (
              <span>
                {Object.keys(props.speakerChunksMap).length > 2 && (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setSeeMoreSpeakers(true);
                    }}
                    className="text-gray-500 font-medium"
                  >
                    See More
                  </a>
                )}
              </span>
            ) : (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSeeMoreSpeakers(false);
                }}
                className="text-gray-500 font-medium"
              >
                See Less
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeakerBars;
// Topics bar
const TopicsBar = (props) => {
  const MOMENT_UG_PIN = 5;

  const [editingTopicUUID, setEditingTopicUUID] = useState(null);
  const [isCreatingTopic, setIsCreatingTopic] = useState(false);
  const [pins, setPins] = useState([]);

  useEffect(() => {
    if (props.recording) {
      const p = [];
      props.recording.moments.forEach((moment) => {
        if (moment.type === MOMENT_UG_PIN) {
          p.push(moment);
        }
      });
      setPins(p);
    }
  }, []);

  useEffect(() => {
    if (props.editingTopicIndex) {
      let topicUUID = getUUIDfromTopicIndex();
      setEditingTopicUUID(topicUUID);
    } else {
      setEditingTopicUUID(null);
    }
  }, [props.editingTopicIndex]);

  useEffect(() => {
    let creatingTopic = false;
    props.topicChunks.forEach((element) => {
      if (element.creatingTopic) {
        creatingTopic = true;
      }
    });
    setIsCreatingTopic(creatingTopic);
  }, [props.topicChunks]);

  function getUUIDfromTopicIndex() {
    let identifier = null;
    props.topics.forEach((element, index) => {
      if (index === props.editingTopicIndex) {
        identifier = element.identifier;
      }
    });
    return identifier;
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function playTopic(topicChunk) {
    props.videoPlayerRef.current.currentTime = topicChunk.start;
    props.videoPlayerRef.current.play();
  }

  async function copyTopicLink(topicChunk) {
    if (!navigator.clipboard) {
      console.log("clipboard copy not supported by browser");
      // Clipboard API not available
      return;
    }
    try {
      await navigator.clipboard.writeText(
        `${window.location.protocol}//${window.location.host}/recording/${props.recording.uuid}/topic/${topicChunk.topicUUID}`
      );
    } catch (err) {
      console.log("Failed to copy!", err);
    }
  }

  function openTopicInNewWindow(topicChunk) {
    window.open(
      `${window.location.protocol}//${window.location.host}/recording/${props.recording.uuid}/topic/${topicChunk.topicUUID}`,
      "_blank"
    );
  }

  function editTopicAtIndex(topicChunk) {
    props.topics.forEach((element, index) => {
      if (element.identifier === topicChunk.topicUUID) {
        props.setEditingTopicIndex(index);
        const editingTopic = props.topics[index];
        props.setEditingTopicTimes([editingTopic.start, editingTopic.end]);

        var eventProperties = {
          recording_uuid: props.recording.uuid,
          topic_uuid: editingTopic.identifier,
          origin: "topics bar",
        };
        amplitude.getInstance().logEvent("clicked edit topic", eventProperties);
      }
    });
  }
  return (
    <div>
      {props.topicChunks.length || pins.length ? (
        <div className="relative w-full flex flex-col text-sm">
          {/* Pinned moments */}
          {/* From https://www.svgrepo.com/svg/471785/pin-01?edit=true */}
          {pins.map((topic, index) => {
            if (topic.type === MOMENT_UG_PIN) {
              const pinStyle = { position: "absolute", display: "none" };
              if (
                props.videoPlayerRef.current.duration &&
                props.hoverBoxRef.current
              ) {
                pinStyle.display = "block";

                let pinLeft =
                  ((topic.time - props.recording.recordingTimeOffset) /
                    props.videoDuration) *
                  props.hoverBoxRef.current.getBoundingClientRect().width;
                // Account for half of the width of the svg (to center it)
                pinLeft = pinLeft - 10;

                pinStyle.left = `${pinLeft}px`;
              }
              return (
                <svg
                  key={index}
                  style={pinStyle}
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0004 15L12.0004 22M8.00043 7.30813V9.43875C8.00043 9.64677 8.00043 9.75078 7.98001 9.85026C7.9619 9.93852 7.93194 10.0239 7.89095 10.1042C7.84474 10.1946 7.77977 10.2758 7.64982 10.4383L6.08004 12.4005C5.4143 13.2327 5.08143 13.6487 5.08106 13.9989C5.08073 14.3035 5.21919 14.5916 5.4572 14.7815C5.73088 15 6.26373 15 7.32943 15H16.6714C17.7371 15 18.27 15 18.5437 14.7815C18.7817 14.5916 18.9201 14.3035 18.9198 13.9989C18.9194 13.6487 18.5866 13.2327 17.9208 12.4005L16.351 10.4383C16.2211 10.2758 16.1561 10.1946 16.1099 10.1042C16.0689 10.0239 16.039 9.93852 16.0208 9.85026C16.0004 9.75078 16.0004 9.64677 16.0004 9.43875V7.30813C16.0004 7.19301 16.0004 7.13544 16.0069 7.07868C16.0127 7.02825 16.0223 6.97833 16.0357 6.92937C16.0507 6.87424 16.0721 6.8208 16.1149 6.71391L17.1227 4.19423C17.4168 3.45914 17.5638 3.09159 17.5025 2.79655C17.4489 2.53853 17.2956 2.31211 17.0759 2.1665C16.8247 2 16.4289 2 15.6372 2H8.36368C7.57197 2 7.17611 2 6.92494 2.1665C6.70529 2.31211 6.55199 2.53853 6.49838 2.79655C6.43707 3.09159 6.58408 3.45914 6.87812 4.19423L7.88599 6.71391C7.92875 6.8208 7.95013 6.87424 7.96517 6.92937C7.97853 6.97833 7.98814 7.02825 7.99392 7.07868C8.00043 7.13544 8.00043 7.19301 8.00043 7.30813Z"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              );
            }
          })}
          <span className="text-gray-700 font-medium mb-0.5">Topics</span>
          <div className="w-full flex h-2.5 bg-gray-100">
            {props.topicChunks.map((chunk, index) => {
              let barClasses = "";
              if (chunk.type === "speaking") {
                barClasses +=
                  "bg-brand-green-lighter3 rounded-xl cursor-pointer hover:bg-brand-green-lighter2 h-2.5 hover:drop-shadow";
                if (
                  (editingTopicUUID && editingTopicUUID !== chunk.topicUUID) ||
                  (isCreatingTopic && !chunk.creatingTopic)
                ) {
                  barClasses += " bg-gray-400";
                }
              } else {
                barClasses += "bg-gray-100";
              }

              // With tooltip
              return chunk.type === "speaking" ? (
                <Tippy
                  popperOptions={{ strategy: "fixed" }}
                  key={index}
                  theme="light"
                  trigger="mouseenter click"
                  interactive={true}
                  maxWidth={400}
                  content={
                    <div>
                      <span className="inline-block px-0.5 mr-2 text-ellipsis overflow-hidden whitespace-nowrap font-semibold max-w-[215px]">
                        #{chunk.name}
                      </span>
                      <span className="inline-block align-top">
                        <button
                          className="inline-block p-0.5 hover:bg-gray-100 rounded"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            playTopic(chunk);
                            var eventProperties = {
                              recording_uuid: props.recording.uuid,
                              topic_uuid: chunk.topicUUID,
                            };
                            amplitude
                              .getInstance()
                              .logEvent("clicked play topic", eventProperties);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-4 h-4 inline-block"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                            />
                          </svg>
                        </button>
                        {!chunk.creatingTopic && (
                          <button
                            className="inline-block p-0.5 hover:bg-gray-100 rounded"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              openTopicInNewWindow(chunk);
                              var eventProperties = {
                                recording_uuid: props.recording.uuid,
                                topic_uuid: chunk.topicUUID,
                              };
                              amplitude
                                .getInstance()
                                .logEvent(
                                  "clicked open topic",
                                  eventProperties
                                );
                            }}
                          >
                            <ExternalLinkIcon
                              className="h-4 w-4 inline-block"
                              aria-hidden="true"
                            />
                          </button>
                        )}
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            {!chunk.creatingTopic && (
                              <Menu.Button className="inline-block p-0.5 hover:bg-gray-100 rounded">
                                <DotsVerticalIcon
                                  className="h-4 w-4 inline-block"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            )}
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                      )}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        copyTopicLink(chunk);
                                        var eventProperties = {
                                          recording_uuid: props.recording.uuid,
                                          topic_uuid: chunk.topicUUID,
                                        };
                                        amplitude
                                          .getInstance()
                                          .logEvent(
                                            "clicked copy topic link",
                                            eventProperties
                                          );
                                      }}
                                    >
                                      Copy Topic Link
                                    </a>
                                  )}
                                </Menu.Item>
                                {!props.isPublic && (
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          editTopicAtIndex(chunk);
                                        }}
                                      >
                                        Edit Topic
                                      </a>
                                    )}
                                  </Menu.Item>
                                )}
                                {!props.isPublic &&
                                  props.editingTopicIndex === null && (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href="#"
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700",
                                            "block px-4 py-2 text-sm"
                                          )}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            props.setTopicChunkToDelete(chunk);
                                            props.setOpenDeleteTopicModal(true);
                                          }}
                                        >
                                          Delete Topic
                                        </a>
                                      )}
                                    </Menu.Item>
                                  )}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </span>
                    </div>
                  }
                >
                  <div
                    className={barClasses}
                    key={index}
                    style={{
                      width: `${
                        ((chunk.finish - chunk.start) / props.videoDuration) *
                        100
                      }%`,
                    }}
                  ></div>
                </Tippy>
              ) : (
                // No tooltip
                <div
                  className={barClasses}
                  key={index}
                  style={{
                    width: `${
                      ((chunk.finish - chunk.start) / props.videoDuration) * 100
                    }%`,
                  }}
                ></div>
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
