import React, { useState, useEffect, useRef } from "react";
import { BriefcaseIcon } from "@heroicons/react/outline";
import amplitude from "amplitude-js";

const AutomationStep = ({ automationRules, setAutomationRules }) => {
  const [recurringMeetings, setRecurringMeetings] = useState([]);
  const [loadingRecurring, setLoadingRecurring] = useState(true);

  const [participants, setParticipants] = useState([]);
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [participantInput, setParticipantInput] = useState("");
  const [showParticipantDropdown, setShowParticipantDropdown] = useState(false);
  const participantInputRef = useRef(null);

  const [domains, setDomains] = useState([]);
  const [filteredDomains, setFilteredDomains] = useState([]);
  const [domainInput, setDomainInput] = useState("");
  const [showDomainDropdown, setShowDomainDropdown] = useState(false);
  const domainInputRef = useRef(null);
  const excludedDomains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "aol.com",
    "icloud.com",
  ];

  const [activeTab, setActiveTab] = useState("recurring");

  const formatRecurringMeetingDetails = (meeting) => {
    const startTime = new Date(meeting.startTime);
    const endTime = new Date(meeting.endTime);

    const formatTime = (date) => {
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    };

    const formatDay = (date) => {
      return date.toLocaleDateString([], { weekday: "long" });
    };

    const timeString = `${formatTime(startTime)} - ${formatTime(endTime)}`;
    const dayString = formatDay(startTime);

    let recurrenceString = "";
    if (meeting.recurrence && meeting.recurrence.length > 0) {
      const rrule = meeting.recurrence[0];
      if (rrule.includes("WEEKLY")) {
        recurrenceString = "Weekly";
      } else if (rrule.includes("DAILY")) {
        recurrenceString = "Daily";
      } else if (rrule.includes("MONTHLY")) {
        recurrenceString = "Monthly";
      } else if (rrule.includes("YEARLY")) {
        recurrenceString = "Yearly";
      }
    }

    return `${dayString}, ${timeString} | ${recurrenceString}`;
  };

  useEffect(() => {
    const fetchRecurringMeetings = async () => {
      setLoadingRecurring(true);

      try {
        const response = await fetch(
          "https://backend.scribbl.co/recurring-meetings",
          {
            credentials: "include",
          }
        );
        if (response.ok) {
          const data = await response.json();
          setRecurringMeetings(data);
        } else {
          console.error("Failed to fetch recurring meetings");
        }
      } catch (error) {
        console.error("Error fetching recurring meetings:", error);
      } finally {
        setLoadingRecurring(false);
      }
    };

    const fetchRecentAndUpcomingParticipants = async () => {
      try {
        const response = await fetch(
          "https://backend.scribbl.co/participants/recent-and-upcoming",
          {
            credentials: "include",
          }
        );
        if (response.ok) {
          const data = await response.json();
          const sortedParticipants = data.sort((a, b) =>
            (a.name || a.email).localeCompare(b.name || b.email)
          );
          setParticipants(sortedParticipants);
          setFilteredParticipants(sortedParticipants);

          const uniqueDomains = [
            ...new Set(
              data.map((participant) => participant.email.split("@")[1])
            ),
          ].filter((domain) => !excludedDomains.includes(domain));
          const sortedDomains = sortDomains(uniqueDomains);
          setDomains(sortedDomains);
          setFilteredDomains(sortedDomains);
        } else {
          console.error("Failed to fetch participants");
        }
      } catch (err) {
        console.error("Error fetching participants:", err);
      }
    };

    fetchRecurringMeetings();
    fetchRecentAndUpcomingParticipants();
  }, []);

  const addAutomationRule = (ruleType, value) => {
    setAutomationRules([...automationRules, { ruleType, value }]);
  };

  const removeAutomationRule = (index) => {
    setAutomationRules(automationRules.filter((_, i) => i !== index));
  };

  const handleParticipantInputChange = (e) => {
    const input = e.target.value;
    setParticipantInput(input);
    setShowParticipantDropdown(true);

    const filtered = participants.filter(
      (participant) =>
        participant.name.toLowerCase().includes(input.toLowerCase()) ||
        participant.email.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredParticipants(filtered);
  };

  const handleParticipantSelect = (email) => {
    addAutomationRule("email", email);
    setParticipantInput("");
    setShowParticipantDropdown(false);
  };

  const handleParticipantInputKeyDown = (e) => {
    if (
      e.key === "Enter" &&
      participantInput.includes("@") &&
      participantInput.includes(".")
    ) {
      e.preventDefault();
      handleParticipantSelect(participantInput);
    }
  };

  const sortDomains = (domains) => {
    return domains.sort((a, b) => a.localeCompare(b));
  };

  const handleDomainInputChange = (e) => {
    const input = e.target.value;
    setDomainInput(input);
    setShowDomainDropdown(true);

    const filtered = domains.filter((domain) =>
      domain.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredDomains(filtered);
  };

  const handleDomainSelect = (domain) => {
    if (!excludedDomains.includes(domain)) {
      addAutomationRule("domain", domain);
      setDomainInput("");
      setShowDomainDropdown(false);
    }
  };

  const handleDomainInputKeyDown = (e) => {
    if (e.key === "Enter" && domainInput.includes(".")) {
      e.preventDefault();
      handleDomainSelect(domainInput);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        participantInputRef.current &&
        !participantInputRef.current.contains(event.target)
      ) {
        setShowParticipantDropdown(false);
      }
      if (
        domainInputRef.current &&
        !domainInputRef.current.contains(event.target)
      ) {
        setShowDomainDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="space-y-4 h-[500px] flex flex-col">
      <div className="text-center">
        <h2 className="text-2xl font-bold text-gray-900">Automation Rules</h2>
        <p className="mt-2 text-sm text-gray-500 max-w-md mx-auto">
          Set up rules to automatically add meetings to this collection.
        </p>
      </div>

      <div className="flex space-x-2 border-b border-gray-200">
        <button
          className={`py-2 px-4 font-medium text-sm focus:outline-none ${
            activeTab === "recurring"
              ? "text-brand-turq border-b-2 border-brand-turq"
              : "text-gray-500 hover:text-gray-700"
          }`}
          onClick={() => setActiveTab("recurring")}
        >
          Recurring Meetings
        </button>
        <button
          className={`py-2 px-4 font-medium text-sm focus:outline-none ${
            activeTab === "participants"
              ? "text-brand-turq border-b-2 border-brand-turq"
              : "text-gray-500 hover:text-gray-700"
          }`}
          onClick={() => setActiveTab("participants")}
        >
          Participants
        </button>
        <button
          className={`py-2 px-4 font-medium text-sm focus:outline-none ${
            activeTab === "domains"
              ? "text-brand-turq border-b-2 border-brand-turq"
              : "text-gray-500 hover:text-gray-700"
          }`}
          onClick={() => setActiveTab("domains")}
        >
          Email Domains
        </button>
      </div>

      <div className="flex-grow overflow-y-auto">
        {activeTab === "recurring" && (
          <div className="space-y-2">
            <p className="text-sm text-gray-600">
              Select recurring meetings to automatically add. These meetings are
              pulled from your calendar:
            </p>
            {loadingRecurring ? (
              <div className="flex flex-col items-center justify-center h-40 bg-gray-50 rounded-lg">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-brand-turq"></div>
                <p className="text-gray-500 mt-2">
                  Loading recurring meetings...
                </p>
              </div>
            ) : recurringMeetings.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-40 bg-gray-50 rounded-lg">
                <BriefcaseIcon className="h-12 w-12 text-gray-400 mb-2" />
                <p className="text-gray-500 text-center">
                  No recurring meetings found in your calendar.
                </p>
                <p className="text-sm text-gray-400 mt-1">
                  Try scheduling some recurring meetings and check back later.
                </p>
              </div>
            ) : (
              <div className="max-h-60 overflow-y-auto">
                {recurringMeetings.map((meeting) => (
                  <div
                    key={meeting.id}
                    className="flex items-center justify-between bg-white p-2 rounded-lg shadow mb-2"
                  >
                    <div>
                      <h4 className="font-semibold text-sm">
                        {meeting.summary}
                      </h4>
                      <p className="text-xs text-gray-500">
                        {formatRecurringMeetingDetails(meeting)}
                      </p>
                    </div>
                    <button
                      onClick={() =>
                        addAutomationRule("recurring_meeting", meeting.id)
                      }
                      className="bg-brand-turq hover:bg-brand-green-lighter1 text-white font-bold py-1 px-2 rounded text-xs"
                    >
                      Add
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {activeTab === "participants" && (
          <div className="space-y-2">
            <p className="text-sm text-gray-600">
              Add email addresses to include meetings with these participants:
            </p>
            <div className="relative" ref={participantInputRef}>
              <input
                type="text"
                value={participantInput}
                onChange={handleParticipantInputChange}
                onKeyDown={handleParticipantInputKeyDown}
                onFocus={() => setShowParticipantDropdown(true)}
                placeholder="Enter or select participant email"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand-turq"
              />
              {showParticipantDropdown && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-40 overflow-y-auto">
                  {filteredParticipants.map((participant) => (
                    <div
                      key={participant.email}
                      onClick={() => handleParticipantSelect(participant.email)}
                      className="p-2 hover:bg-gray-100 cursor-pointer text-sm"
                    >
                      {participant.name} ({participant.email})
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        {activeTab === "domains" && (
          <div className="space-y-2">
            <p className="text-sm text-gray-600">
              Add email domains to include meetings with participants from these
              domains:
            </p>
            <div className="relative" ref={domainInputRef}>
              <input
                type="text"
                value={domainInput}
                onChange={handleDomainInputChange}
                onKeyDown={handleDomainInputKeyDown}
                onFocus={() => setShowDomainDropdown(true)}
                placeholder="Enter or select email domain"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand-turq"
              />
              {showDomainDropdown && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-40 overflow-y-auto">
                  {filteredDomains.map(
                    (domain) =>
                      domain !== "gmail" && (
                        <div
                          key={domain}
                          onClick={() => handleDomainSelect(domain)}
                          className="p-2 hover:bg-gray-100 cursor-pointer text-sm"
                        >
                          {domain}
                        </div>
                      )
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="mt-4 border-t pt-4">
        <h3 className="font-medium text-gray-900 mb-2">
          Active Automation Rules:
        </h3>
        <div className="space-y-2 max-h-32 overflow-y-auto">
          {automationRules.length === 0 ? (
            <p className="text-sm text-gray-500 italic">
              No rules added yet. Add rules above to see them here.
            </p>
          ) : (
            <div className="grid grid-cols-2 gap-2">
              {automationRules.map((rule, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between bg-gray-100 p-2 rounded-md text-sm"
                >
                  <span className="truncate">
                    {rule.ruleType === "recurring_meeting"
                      ? recurringMeetings.find((m) => m.id === rule.value)
                          ?.summary || "Recurring Meeting"
                      : rule.ruleType === "email"
                      ? rule.value
                      : `@${rule.value}`}
                  </span>
                  <button
                    onClick={() => removeAutomationRule(index)}
                    className="text-red-600 hover:text-red-800 text-xs ml-2"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AutomationStep;
