import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import {
  PencilIcon,
  TrashIcon,
  SelectorIcon,
  PlusIcon,
  XIcon,
} from "@heroicons/react/outline";

import { CogIcon } from "@heroicons/react/solid"; // For loading spinner
import {
  Title,
  TextBlock,
  BulletList,
  NumberList,
  CollapsibleList,
} from "./GenericComponents";

import amplitude from "amplitude-js";

const CustomOutput = ({ meeting }) => {
  const [customQuery, setCustomQuery] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [previewOutput, setPreviewOutput] = useState("");
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Add a state to track if editing

  const [backToConfigMode, setBackToConfigMode] = useState(false); // Add a state to track if editing

  const [generatedOutput, setGeneratedOutput] = useState("");
  const [generatedOutputTitle, setGeneratedOutputTitle] = useState("");

  useEffect(() => {
    fetchTemplates();
  }, []); // Empty dependency array for component mount only

  // useEffect for processing data
  useEffect(() => {
    if (meeting?.CustomOutputGenerations?.length > 0 && templates.length > 0) {
      // Find the most recent CustomOutputGeneration
      const mostRecentOutput = meeting.CustomOutputGenerations.reduce(
        (latest, current) => {
          return new Date(latest.CreatedAt) > new Date(current.CreatedAt)
            ? latest
            : current;
        }
      );

      setGeneratedOutput(mostRecentOutput.output);

      // Find the corresponding template
      const correspondingTemplate = templates.find(
        (template) => template.ID === mostRecentOutput.CustomOutputTemplateID
      );

      if (correspondingTemplate) {
        setGeneratedOutputTitle(correspondingTemplate.name);
      }
    }
  }, [meeting, templates]); // Dependency array includes 'meeting' and 'templates'

  // Fetch templates when the component mounts
  const fetchTemplates = async () => {
    try {
      const response = await fetch(
        "https://backend.scribbl.co/custom-outputs/templates",
        {
          method: "GET",
          credentials: "include",
        }
      );
      const data = await response.json();
      setTemplates(data);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  const generatePreviewOutput = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://backend.scribbl.co/custom-outputs/preview-query",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: customQuery,
            enhancedRecordingID: meeting.ID,
          }),
        }
      );
      const data = await response.json();
      setPreviewOutput(data.customOutputGeneration);

      amplitude.getInstance().logEvent("custom output ai preview generation");
    } catch (error) {
      console.error("Error fetching preview:", error);
    }
    setIsLoading(false);
  };

  const generateTemplateOutput = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://backend.scribbl.co/custom-outputs/apply-template",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            templateID: selectedTemplateId,
            meetingID: meeting.ID,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setGeneratedOutput(data.output); // Assuming 'data.output' is the format returned from the backend
        const correspondingTemplate = templates.find(
          (template) => template.ID === selectedTemplateId
        );
        if (correspondingTemplate) {
          setGeneratedOutputTitle(correspondingTemplate.name); // Set the title
        }
        setBackToConfigMode(false); // Switch back to viewing mode

        amplitude.getInstance().logEvent("custom output ai applied generation");
      } else {
        console.error("Error generating output:", await response.text());
      }
    } catch (error) {
      console.error("Error generating output:", error);
    }
    setIsLoading(false);
  };

  // Handle save for both new template and updating existing template
  const handleSave = async () => {
    const url = isEditing
      ? `https://backend.scribbl.co/custom-outputs/templates/${selectedTemplateId}` // PUT URL for updating
      : "https://backend.scribbl.co/custom-outputs/save-template"; // POST URL for creating

    const method = isEditing ? "PUT" : "POST";

    try {
      const saveResponse = await fetch(url, {
        method: method,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: templateName,
          query: customQuery,
          generatedOutput: previewOutput,
          enhancedRecordingID: meeting.ID,
        }),
      });

      if (saveResponse.ok) {
        closeModal();
        fetchTemplates(); // Refetch templates to update the list
        console.log("setting go 1");
        setGeneratedOutput(previewOutput);
        setGeneratedOutputTitle(templateName); // Set the title
        setBackToConfigMode(false);
      } else {
        console.error("Error saving template:", await saveResponse.text());
      }
    } catch (error) {
      console.error("Error saving template:", error);
    }
  };

  const renderCustomOutput = (output) => {
    if (!output) return null;

    const content = output.content;
    return (
      <div>
        {content.title && <Title text={content.title.text} />}
        {content.textBlock && <TextBlock text={content.textBlock.text} />}
        {content.bulletList && <BulletList items={content.bulletList.items} />}
        {content.numberList && <NumberList items={content.numberList.items} />}
        {content.collapsibleList && (
          <CollapsibleList items={content.collapsibleList.items} />
        )}
      </div>
    );
  };

  // Function to open the modal with the template details for editing
  const handleEditTemplate = async (templateId) => {
    const templateToEdit = templates.find(
      (template) => template.ID === templateId
    );
    if (templateToEdit) {
      setTemplateName(templateToEdit.name);
      setCustomQuery(templateToEdit.query);
      setSelectedTemplateId(templateId);
      setIsEditing(true);
      setPreviewOutput(templateToEdit.generatedOutput); // Assuming you store the generated output in your template
      setIsModalOpen(true);
    }
  };

  const handleDeleteTemplate = async (templateId) => {
    if (window.confirm("Are you sure you want to delete this template?")) {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://backend.scribbl.co/custom-outputs/templates/${templateId}`,
          {
            method: "DELETE",
            credentials: "include",
          }
        );

        if (response.ok) {
          fetchTemplates(); // Refetch templates to update the list
        } else {
          console.error("Error deleting template:", await response.text());
        }
      } catch (error) {
        console.error("Error deleting template:", error);
      }
      setIsLoading(false);
    }
  };

  const handleSelectChange = (value) => {
    if (value === "new") {
      openModal();
    } else {
      setSelectedTemplateId(value);
    }
  };

  const openModal = () => {
    setSelectedTemplateId(""); // Reset selection when opening modal
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCustomQuery("");
    setPreviewOutput("");
    setTemplateName("");
    setIsEditing(false); // Reset editing state when closing modal
  };

  return (
    <div className="flex flex-col items-center m-3 rounded-md bg-white px-4 py-3 border border-dashed border-gray-200 sm:px-6">
      {generatedOutput && backToConfigMode === false ? (
        <div className="flex flex-col relative m-3 rounded-md bg-white flex px-4 py-3 border border-dashed border-gray-200 sm:px-6">
          <div className="mb-2 text-lg font-semibold">
            {generatedOutputTitle || "Custom Output"} {/* Fallback title */}
          </div>
          <PencilIcon
            className="w-5 h-5 text-gray-600 absolute top-2 right-2 cursor-pointer"
            onClick={() => setBackToConfigMode(true)}
          />
          {renderCustomOutput(JSON.parse(generatedOutput))}
        </div>
      ) : (
        <div className="w-full relative">
          {backToConfigMode && (
            <button
              aria-label="Edit"
              className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center rounded-full bg-gray-500 text-white hover:bg-gray-600 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              onClick={() => setBackToConfigMode(false)}
              disabled={isLoading}
            >
              <XIcon className="w-5 h-5" />
            </button>
          )}
          <div className="text-[16px] pt-3 text-[#4b5563] mb-3">
            Are Meeting Notes and Action Items not enough? Use a Custom GPT
            Output
          </div>
          {templates.length > 0 ? (
            <div className="w-full relative">
              <Listbox value={selectedTemplateId} onChange={handleSelectChange}>
                {({ open }) => (
                  <>
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-md shadow-sm border border-gray-300 cursor-default focus:outline-none focus:ring-1 focus:ring-brand-green-darker2 focus:border-brand-green-darker2">
                      <span className="block truncate">
                        {selectedTemplateId
                          ? templates.find(
                              (template) => template.ID === selectedTemplateId
                            )?.name
                          : "Select a template"}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        {templates.map((template) => (
                          <Listbox.Option
                            key={template.ID}
                            value={template.ID}
                            as={Fragment}
                          >
                            {({ selected, active }) => (
                              <div
                                className={`cursor-default select-none relative py-2 pl-3 pr-4 ${
                                  active
                                    ? "bg-brand-gray-lighter3"
                                    : "text-gray-900"
                                }`}
                              >
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {template.name}
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                  <PencilIcon
                                    className="w-5 h-5 text-gray-600 ml-3 cursor-pointer"
                                    onClick={(e) => {
                                      handleEditTemplate(template.ID);
                                    }}
                                  />
                                  <TrashIcon
                                    className="w-5 h-5 text-red-600 ml-3 cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent Listbox from closing
                                      handleDeleteTemplate(template.ID);
                                    }}
                                  />
                                </span>
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                        <Listbox.Option
                          key="new"
                          value="new"
                          as={Fragment}
                          disabled={isModalOpen}
                        >
                          {({ active }) => (
                            <div
                              className={`cursor-default select-none relative py-2 pl-3 pr-4 ${
                                active
                                  ? "bg-brand-gray-lighter3"
                                  : "text-gray-900"
                              }`}
                            >
                              <span className="flex items-center">
                                <PlusIcon className="w-5 h-5 text-gray-600 mr-3" />
                                Add new template
                              </span>
                            </div>
                          )}
                        </Listbox.Option>
                      </Listbox.Options>
                    </Transition>
                  </>
                )}
              </Listbox>
              <button
                type="button"
                className="mt-2 text-[13px] cursor-pointer rounded-md bg-brand-green px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-green-darker2 w-full flex justify-center items-center"
                onClick={generateTemplateOutput}
                disabled={selectedTemplateId === "" || isLoading}
              >
                {isLoading ? (
                  <CogIcon className="animate-spin h-5 w-5 mr-2" />
                ) : null}
                {isLoading ? "Generating..." : "Generate Output"}
              </button>
            </div>
          ) : (
            <button
              type="button"
              className="text-[13px] rounded-md bg-brand-green px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-green-darker2 w-full"
              onClick={openModal}
            >
              Create Custom Output
            </button>
          )}
          {/* Modal */}
          <Transition.Root show={isModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                      <div className="flex justify-between items-start border-b-2 pb-2">
                        <Dialog.Title className="font-medium leading-tight text-xl">
                          {isEditing
                            ? "Edit Custom Output"
                            : "Create a New Custom Output"}
                        </Dialog.Title>
                        <XIcon
                          className="ml-3 -mr-1 h-7 w-7 cursor-pointer"
                          aria-hidden="true"
                          onClick={closeModal}
                        />
                      </div>
                      <div className="mt-4">
                        {/* Input Fields */}
                        <label
                          htmlFor="templateName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          What do you want to name your output?
                        </label>
                        <input
                          type="text"
                          name="templateName"
                          id="templateName"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-brand-green-lighter3 focus:ring-opacity-50"
                          onChange={(e) => setTemplateName(e.target.value)}
                          value={templateName}
                        />
                        <label
                          htmlFor="outputDescription"
                          className="block mt-4 text-sm font-medium text-gray-700"
                        >
                          Please describe what information you'd like to extract
                          from your meeting
                        </label>
                        <textarea
                          id="outputDescription"
                          name="outputDescription"
                          rows="3"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-brand-green-lighter3 focus:ring-opacity-50"
                          placeholder="Example: Can you pull out all of the important themes that came up during this meeting?"
                          value={customQuery}
                          onChange={(e) => setCustomQuery(e.target.value)}
                        ></textarea>
                      </div>
                      {(previewOutput || isLoading) && (
                        <label className="mt-4 block text-sm font-medium text-gray-700">
                          Output Preview:
                        </label>
                      )}
                      {previewOutput ? (
                        <div className="m-3 rounded-md bg-white flex px-4 py-3 border border-dashed border-gray-200 sm:px-6">
                          {renderCustomOutput(JSON.parse(previewOutput))}
                        </div>
                      ) : (
                        isLoading && (
                          <div className=" flex justify-center items-center">
                            <CogIcon
                              className="animate-spin h-5 w-5 text-gray-600"
                              aria-hidden="true"
                            />
                          </div>
                        )
                      )}

                      <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                        {isEditing || previewOutput ? (
                          <>
                            <button
                              type="button"
                              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-green text-base font-medium text-white hover:bg-brand-green-darker2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={generatePreviewOutput}
                              disabled={isLoading}
                            >
                              {isLoading
                                ? "Generating..."
                                : "Regenerate Output"}
                            </button>
                            <button
                              type="button"
                              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={handleSave}
                            >
                              Save
                            </button>
                          </>
                        ) : (
                          <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-green text-base font-medium text-white hover:bg-brand-green-darker2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={generatePreviewOutput}
                            disabled={isLoading}
                          >
                            {isLoading ? "Generating..." : "Preview Output"}
                          </button>
                        )}
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      )}
    </div>
  );
};

export default CustomOutput;
