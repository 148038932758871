import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import { Switch } from "@headlessui/react";
import { DocumentTextIcon, LinkIcon } from "@heroicons/react/solid";
import amplitude from "amplitude-js";

const HubspotIntegration = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hubspotConnected, setHubspotConnected] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState("disconnected");
  const [settings, setSettings] = useState({
    addMeetingNotes: true,
    createCompanyCollections: true,
    muteEmailNotifications: false,
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    checkHubspotConnection();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    if (code) {
      handleHubspotCallback(code);
    }
  }, [location]);

  useEffect(() => {
    if (user) {
      setLoading(false);

      amplitude
        .getInstance()
        .logEvent("Integrations hubspot integration visited");
    }
  }, [user, hubspotConnected, connectionStatus]);

  const checkHubspotConnection = async () => {
    try {
      const response = await fetch("https://backend.scribbl.co/hubspot/status", {
        method: "GET",
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setHubspotConnected(data.connected);
        setConnectionStatus(data.connected ? "connected" : "disconnected");
        if (data.connected) {
          setSettings({
            addMeetingNotes: data.addMeetingNotes,
            createCompanyCollections: data.createCompanyCollections,
            muteEmailNotifications: data.muteEmailNotifications,
          });
        }
      }
    } catch (error) {
      console.error("Error checking HubSpot connection:", error);
      setConnectionStatus("error");
    }
  };

  const handleHubspotCallback = async (code) => {
    try {
      setConnectionStatus("connecting");
      const response = await fetch(
        `https://backend.scribbl.co/hubspot/callback?code=${code}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (data.status === "success") {
          await checkHubspotConnection();
          // Remove the code from the URL

          amplitude.getInstance().logEvent("Integrations hubspot connected");

          navigate("/integrations/hubspot", { replace: true });
        } else {
          throw new Error("Failed to exchange code for token");
        }
      } else {
        throw new Error("Failed to exchange code for token");
      }
    } catch (error) {
      console.error("Error handling HubSpot callback:", error);
      setConnectionStatus("error");
    }
  };

  const handleConnectHubSpot = async () => {
    try {
      setConnectionStatus("connecting");
      const response = await fetch("https://backend.scribbl.co/hubspot/connect", {
        method: "GET",
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        window.location.href = data.authUrl;
      }
    } catch (error) {
      console.error("Error connecting to HubSpot:", error);
      setConnectionStatus("error");
    }
  };

  const handleDisconnectHubSpot = async () => {
    try {
      setConnectionStatus("disconnecting");
      const response = await fetch("https://backend.scribbl.co/hubspot/disconnect", {
        method: "POST",
        credentials: "include",
      });
      if (response.ok) {
        setHubspotConnected(false);
        setConnectionStatus("disconnected");
        setSettings({
          addMeetingNotes: false,
          createCompanyCollections: false,
          muteEmailNotifications: false,
        });
      } else {
        throw new Error("Failed to disconnect from HubSpot");
      }
    } catch (error) {
      console.error("Error disconnecting from HubSpot:", error);
      setConnectionStatus("error");
    }
  };

  const handleSettingChange = async (setting, value) => {
    const newSettings = { ...settings, [setting]: value };
    setSettings(newSettings);

    try {
      const response = await fetch("https://backend.scribbl.co/hubspot/settings", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newSettings),
      });

      if (!response.ok) {
        throw new Error("Failed to update HubSpot settings");
      }
    } catch (error) {
      console.error("Error updating HubSpot settings:", error);
      // Revert the setting change if the update failed
      setSettings((prevSettings) => ({
        ...prevSettings,
        [setting]: !value,
      }));
    }
  };

  const renderConnectionStatus = () => {
    switch (connectionStatus) {
      case "connected":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
            <span className="w-2 h-2 mr-2 bg-green-400 rounded-full"></span>
            Connected
          </span>
        );
      case "disconnected":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
            <span className="w-2 h-2 mr-2 bg-gray-400 rounded-full"></span>
            Disconnected
          </span>
        );
      case "connecting":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
            <svg
              className="animate-spin -ml-1 mr-2 h-4 w-4 text-blue-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Connecting
          </span>
        );
      case "disconnecting":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
            <svg
              className="animate-spin -ml-1 mr-2 h-4 w-4 text-yellow-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Disconnecting
          </span>
        );
      case "error":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-red-100 text-red-800">
            <span className="w-2 h-2 mr-2 bg-red-400 rounded-full"></span>
            Error
          </span>
        );
      default:
        return null;
    }
  };

  const SettingToggle = ({ setting, label, description }) => (
    <div className="flex items-center justify-between py-4 border-b border-gray-200 last:border-b-0">
      <div className="pr-4">
        <h4 className="text-sm font-medium text-gray-900">{label}</h4>
        <p className="text-sm text-gray-500 mt-1">{description}</p>
      </div>
      <Switch
        checked={settings[setting]}
        onChange={(value) => handleSettingChange(setting, value)}
        className={`${
          settings[setting] ? "bg-brand-green-darker2" : "bg-gray-200"
        } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green-darker2`}
      >
        <span className="sr-only">Enable setting</span>
        <span
          className={`${
            settings[setting] ? "translate-x-5" : "translate-x-0"
          } pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
        >
          <span
            className={`${
              settings[setting]
                ? "opacity-0 ease-out duration-100"
                : "opacity-100 ease-in duration-200"
            } absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
            aria-hidden="true"
          >
            <svg
              className="h-3 w-3 text-gray-400"
              fill="none"
              viewBox="0 0 12 12"
            >
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span
            className={`${
              settings[setting]
                ? "opacity-100 ease-in duration-200"
                : "opacity-0 ease-out duration-100"
            } absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
            aria-hidden="true"
          >
            <svg
              className="h-3 w-3 text-brand-green-darker2"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </Switch>
    </div>
  );

  return (
    <Navbar setUser={setUser} loading={loading}>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-bold text-gray-900">
            HubSpot Integration
          </h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="120"
            height="60"
            viewBox="0 0 24.576 12.28"
            preserveAspectRatio="xMidYMid"
          >
            <path
              d="M.744 4.04h.805v1.79h1.698V4.04h.806v4.4h-.806V6.605H1.55V8.43H.744zM6.604 7a.67.67 0 0 1-.667.667.67.67 0 0 1-.667-.667v-1.9h-.763V7a1.43 1.43 0 0 0 2.86 0v-1.9h-.763V7m2.895-1.868a1.17 1.17 0 0 0-.898.36V4.04h-.766v2.692c0 1.008.73 1.698 1.547 1.698.9 0 1.707-.703 1.707-1.65 0-.935-.735-1.65-1.6-1.65zm.02 2.6c-.52 0-.916-.433-.916-.94s.397-.94.916-.94c.44 0 .837.434.837.94s-.397.94-.837.94zm2.858-2.397c0-.385.257-.507.537-.507.226 0 .525.17.72.38l.5-.586c-.25-.336-.757-.568-1.172-.568-.83 0-1.43.483-1.43 1.283 0 1.484 1.826 1.014 1.826 1.845 0 .256-.25.482-.537.482-.452 0-.6-.22-.806-.452l-.556.574c.354.434.794.654 1.32.654.788 0 1.423-.49 1.423-1.252 0-1.65-1.826-1.136-1.826-1.85m3.843-.236c-.82 0-1.547.7-1.547 1.698v2.7h.766v-1.46c.255.25.52.36.898.36.855 0 1.6-.715 1.6-1.65 0-.947-.798-1.65-1.708-1.65zm.136 2.6c-.52 0-.916-.434-.916-.94s.397-.94.916-.94c.44 0 .837.434.837.94s-.397.94-.837.94zm7.21.043c-.452 0-.58-.195-.58-.495V5.903h.702V5.23h-.702v-.886l-.775.348v2.7c0 .7.476 1.038 1.13 1.038a1.57 1.57 0 0 0 .306-.024l.19-.696-.27.012"
              fill="#45535e"
            />
            <path
              d="M21.543 5.942c-.147-.253-.353-.455-.612-.608a1.64 1.64 0 0 0-.619-.214v-.803a.59.59 0 0 0 .365-.555c0-.337-.27-.6-.607-.6a.61.61 0 0 0-.612.61c0 .247.132.46.357.555v.803c-.18.026-.367.082-.538.17l-2.162-1.642c.016-.057.028-.116.028-.178 0-.373-.302-.676-.676-.676s-.675.303-.675.676.302.676.676.676a.67.67 0 0 0 .347-.098l.14.107 1.94 1.398c-.103.094-.198.2-.275.322a1.5 1.5 0 0 0-.25.809v.06c0 .206.04.4.106.585.06.16.145.305.25.437l-.644.646a.52.52 0 0 0-.701.49.52.52 0 0 0 .522.522.52.52 0 0 0 .522-.522c0-.054-.008-.107-.024-.157l.666-.666a1.71 1.71 0 0 0 .296.162 1.73 1.73 0 0 0 .692.145h.046a1.63 1.63 0 0 0 .79-.2c.26-.142.465-.336.62-.583a1.53 1.53 0 0 0 .24-.824v-.015c0-.297-.07-.57-.213-.82zM20.73 7.34c-.18.2-.388.325-.623.325h-.04c-.134 0-.265-.037-.394-.104a.91.91 0 0 1-.345-.314c-.093-.132-.144-.275-.144-.428V6.77c0-.15.03-.292.1-.426a.92.92 0 0 1 .321-.351c.137-.1.282-.134.445-.134h.015a.9.9 0 0 1 .418.096c.134.072.244.17.33.3a.95.95 0 0 1 .147.42l.004.095a.83.83 0 0 1-.236.569z"
              fill="#f8761f"
            />
          </svg>
        </div>

        <div className="bg-white shadow sm:rounded-lg mb-8 overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Connection Status
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Connect Scribbl with your HubSpot account to sync meeting
                  notes.
                </p>
              </div>
              {renderConnectionStatus()}
            </div>
            <div className="mt-5">
              {!hubspotConnected && user && user.orgID ? (
                <button
                  type="button"
                  onClick={handleConnectHubSpot}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green-darker2"
                >
                  <LinkIcon className="mr-2 h-5 w-5" />
                  Connect to HubSpot
                </button>
              ) : (
                user &&
                user.orgID && (
                  <button
                    type="button"
                    onClick={handleDisconnectHubSpot}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green-darker2"
                  >
                    Disconnect from HubSpot
                  </button>
                )
              )}
            </div>
          </div>
        </div>

        {hubspotConnected && (
          <div className="bg-white shadow sm:rounded-lg mb-8 overflow-hidden">
            <div className="px-4 py-5 sm:p-6">
              <h2 className="text-lg leading-6 font-medium text-gray-900 mb-4 flex items-center">
                <DocumentTextIcon className="h-6 w-6 text-blue-500 mr-2" />
                Meeting Notes Sync
              </h2>
              <p className="text-sm text-gray-500 mb-6">
                Configure how Scribbl syncs your meeting notes with HubSpot.
              </p>
              <div className="space-y-4">
                <SettingToggle
                  setting="addMeetingNotes"
                  label="Add meeting notes to Hubspot"
                  description="Add Scribbl's AI meeting notes to HubSpot as CRM Notes. Automatically links to relevant contacts, companies, and deals, enriching your HubSpot data with AI-powered meeting intelligence."
                />
                <SettingToggle
                  setting="createCompanyCollections"
                  label="Create collections for HubSpot companies"
                  description="Automatically organize meetings into collections based on HubSpot company associations"
                />
                <SettingToggle
                  setting="muteEmailNotifications"
                  label="Mute Email Notifications"
                  description="Disable email notifications for CRM-related actions like company collection creation"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Navbar>
  );
};

export default HubspotIntegration;
