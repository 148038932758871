import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  Fragment,
} from "react";

import { PlayIcon } from "@heroicons/react/solid";
import {
  LinkIcon,
  PencilAltIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  HashtagIcon,
} from "@heroicons/react/outline";

import ReactTooltip from "react-tooltip";
import { Dialog, Transition, Combobox } from "@headlessui/react";
import { useOutsideClick } from "../../helpers";

import "react-multi-email/style.css";

import amplitude from "amplitude-js";

var moment = require("moment");
let lastScrollTop = 0;

const MOMENT_BLANK = -1;
const MOMENT_UG_NOTE = 1;
const MOMENT_UG_HIGHLIGHT = 2;
const MOMENT_UG_ACTION_ITEM = 3;
const MOMENT_UG_TOPIC = 4;
const MOMENT_UG_PIN = 5;

const Transcript = (props) => {
  let videoPlayerRef = useRef();
  let transcriptParentRef = useRef();
  let comboboxRef = useRef();

  const [comboboxOpen, setComboboxOpen] = useState(false);

  const [transcriptParentWidth, setTranscriptParentWidth] = useState(0);
  let [saveModalOpen, setSaveModalOpen] = useState(false);

  const [scrollWithVideo, setScrollWithVideo] = useState(true);

  const [scrollTo, setScrollTo] = useState(0);

  const [topicLabels, setTopicLabels] = useState([]);
  const [editTopicName, setEditTopicName] = useState("");

  const [query, setQuery] = useState("");
  const [transcriptParentHeight, setTranscriptParentHeight] = useState(0);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const findNearestTxItemAndSetScrollTo = () => {
    if (
      scrollWithVideo &&
      props.recording &&
      props.recording.transcriptItems &&
      props.recording.transcriptItems.length
    ) {
      let willBeHandled = false;
      props.recording.transcriptItems.forEach((txItem) => {
        if (
          props.currentVideoTime >
            txItem.itemStart - props.recording.recordingTimeOffset &&
          props.currentVideoTime <
            txItem.itemEnd - props.recording.recordingTimeOffset &&
          scrollWithVideo
        ) {
          willBeHandled = true;
        }
      });
      if (willBeHandled === false && scrollWithVideo) {
        let closestTxItem = props.recording.transcriptItems[0];

        props.recording.transcriptItems.forEach((txItem) => {
          if (
            Math.abs(
              props.videoPlayerRef.current.currentTime -
                (closestTxItem.itemStart - props.recording.recordingTimeOffset)
            ) >
            Math.abs(
              props.videoPlayerRef.current.currentTime -
                (txItem.itemStart - props.recording.recordingTimeOffset)
            )
          ) {
            closestTxItem = txItem;
          }
        });
        closestTxItem.scrollTo = true;
        let theone = structuredClone(closestTxItem.ID);
        setScrollTo(theone);
      }
    }
  };

  // When sync to video is clicked:
  // - if we're not in the start : end of a txItem
  // - find the nearest txItem to scroll to
  useEffect(() => {
    findNearestTxItemAndSetScrollTo();
  }, [scrollWithVideo]);

  // - if we're not in the start : end of a txItem
  // - find the nearest txItem to scroll to
  useEffect(() => {
    // The updating constantly when the video playing is to much
    // This is for when the video is paused, and a point in the speaker bars without a
    // txItem is clicked
    if (props.videoPlayerRef.current.paused) {
      findNearestTxItemAndSetScrollTo();
    }
  }, [props.currentVideoTime]);

  useEffect(() => {
    if (props.recording) {
      // Coming from a topic, we don't pass structureTopicData because there is only one topic
      if (props.structureTopicsData) {
        const topics = props.structureTopicsData(
          props.recording,
          props.videoDuration
        );
        props.setTopics(topics);
      }
    }
  }, [props.recording]);

  async function copyShareLinkToClipboard(recordingUUID, topicUUID) {
    if (!navigator.clipboard) {
      console.log("clipboard copy not supported by browser");
      // Clipboard API not available
      return;
    }
    try {
      await navigator.clipboard.writeText(
        `${window.location.protocol}//${window.location.host}/recording/${recordingUUID}/topic/${topicUUID}`
      );
    } catch (err) {
      console.log("failed to copy link:" + err);
    }
  }

  async function createTopicBackend(topic, email, recordingUUID, offset) {
    const createTopicResp = await fetch(
      `https://backend.scribbl.co/topics?email=${email}&recordingUUID=${recordingUUID}`,
      {
        method: "POST",
        body: JSON.stringify({
          start_time: topic.start + offset,
          end_time: topic.end + offset,
          name: topic.name,
        }),
        credentials: "include", // This line ensures cookies are sent with the request
      }
    );
    const respJson = await createTopicResp.json();
    return respJson;
  }

  async function updateTopicBackend(topic, email, recordingUUID, offset) {
    const updateTopicResp = await fetch(
      `https://backend.scribbl.co/topics/${topic.identifier}?email=${email}&recordingUUID=${recordingUUID}`,
      {
        method: "PUT",
        body: JSON.stringify({
          start_time: topic.start + offset,
          end_time: topic.end + offset,
          name: topic.name,
        }),
        credentials: "include", // This line ensures cookies are sent with the request
      }
    );
    await updateTopicResp.json();
  }

  function setTopicStart(topicID, txItem) {
    props.recording.moments.forEach((topic) => {
      if (topic.ID === topicID) {
        topic.start_time = txItem.itemStart;

        const updatedTopics = props.structureTopicsData(
          props.recording,
          props.videoDuration
        );
        props.setTopics(updatedTopics);

        const topicChunks = props.calculateTopicChunks(
          props.recording,
          videoPlayerRef
        );

        const utc = structuredClone(topicChunks);
        props.setTopicChunks(utc);
      }
    });
  }

  function setTopicEnd(topicID, txItem) {
    lastScrollTop = transcriptParentRef.current.scrollTop;

    props.recording.moments.forEach((topic) => {
      if (topic.ID === topicID) {
        topic.end_time = txItem.itemStart;
      }
    });
    const updatedTopics = props.structureTopicsData(
      props.recording,
      props.videoDuration
    );

    const newUT = structuredClone(updatedTopics);
    props.setTopics(newUT);
    const topicChunks = props.calculateTopicChunks(
      props.recording,
      videoPlayerRef
    );

    // HOLY FUCK THIS LINE OF CODE TOOK FOREVER TO FIGURE OUT
    // When hitting set topic end, we see a jump in the scroll that is extremely disorienting.
    // This fixes that bug.  I have no idea why it happens, but at least this fixes it.
    // I hope to reclaim the 3 days this bug took from me in the future.
    setTimeout(() => {
      transcriptParentRef.current.scrollTop = lastScrollTop;
    }, 5);

    const utc = structuredClone(topicChunks);
    props.setTopicChunks(utc);
  }

  let showCollapse = false;
  props.topics.forEach((topic) => {
    if (topic.open === true) {
      showCollapse = true;
    }
  });

  useEffect(() => {
    const topic = props.topics[props.editingTopicIndex];
    if (topic) {
      const topicName = topic.name ? topic.name : "";
      setEditTopicName(topicName);
    }
  }, [saveModalOpen]);

  useEffect(() => {
    if (props.userEmail) {
      async function getTopicLabels() {
        const topicLabels = await fetchTopicLabels(props.userEmail);
        if (topicLabels) {
          setTopicLabels(topicLabels);
        }
      }

      getTopicLabels();
    }
  }, [comboboxOpen]);

  useEffect(() => {
    calculateElementHeight();
  }, [window.innerHeight]);

  useOutsideClick(comboboxRef, () => {
    setComboboxOpen(false);
  });

  const fetchTopicLabels = async (userEmail) => {
    try {
      const topicsReq = await fetch(
        `https://backend.scribbl.co/user/topics/labels?email=${userEmail}`,
        {
          method: "GET",
          credentials: "include", // This line ensures cookies are sent with the request
        }
      );

      const topics = await topicsReq.json();
      if (topics.isError) {
        console.log(topics.reason);
        return;
      }
      return topics;
    } catch (exception) {
      console.log(exception);
      return null;
    }
  };

  const filteredTopicSearch =
    query === ""
      ? topicLabels
      : topicLabels.filter((topicLabel) => {
          return topicLabel.name.toLowerCase().includes(query.toLowerCase());
        });

  useEffect(() => {
    calculateElementHeight();
    calculateElementWidth();
    window.addEventListener("resize", calculateElementHeight);
    window.addEventListener("resize", calculateElementWidth);
    return () => {
      window.removeEventListener("resize", calculateElementHeight);
      window.removeEventListener("resize", calculateElementWidth);
    };
  }, []);

  function calculateElementWidth() {
    setTranscriptParentWidth(transcriptParentRef.current.offsetWidth);
  }

  function calculateElementHeight() {
    if (transcriptParentRef.current) {
      /* calculates the height of the transcript window */
      let height =
        window.innerHeight -
        (transcriptParentRef.current.getBoundingClientRect().top +
          window.scrollY);
      setTranscriptParentHeight(height);
    }
  }

  function getTopicColor(topic, topicIndex) {
    if (props.editingTopicIndex) {
      if (topic.creatingTopic || props.editingTopicIndex === topicIndex) {
        return "brand-green-lighter3";
      } else {
        return "gray-400";
      }
    } else {
      return "brand-green-lighter3";
    }
  }

  return (
    // Transcript Component
    <div className="relative pt-3 flex flex-col">
      {scrollWithVideo === false && showCollapse === true && (
        <button
          type="button"
          style={{
            marginLeft: "-62.5px",
            width: "125px",
            position: "absolute",
            zIndex: "10",
            bottom: props.editingTopicIndex ? "70px" : "15px",
            left: "50%",
          }}
          className="inline-flex items-center px-3.5 py-2 border border-transparent text-sm leading-4 font-medium rounded-full shadow-sm text-white bg-brand-green-lighter2 hover:bg-brand-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green-darker2"
          onClick={() => setScrollWithVideo(true)}
        >
          Sync with video
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"
            />
          </svg>
        </button>
      )}

      <div>
        <div
          onWheel={() => setScrollWithVideo(false)}
          className="overflow-auto"
          ref={transcriptParentRef}
          style={
            props.editingTopicIndex
              ? {
                  maxHeight: `${transcriptParentHeight}px`,
                  paddingBottom: "70px",
                }
              : { maxHeight: `${transcriptParentHeight}px` }
          }
        >
          {props.topics.map((topic, index) => {
            return (
              (topic.type === MOMENT_UG_TOPIC ||
                topic.type === MOMENT_BLANK) && (
                <div key={index}>
                  <div>
                    {/* Non topics */}
                    {topic.isTopic === false &&
                      topic.transcriptItems.length > 0 && (
                        <div>
                          <div className="px-2">
                            {topic.open &&
                              topic.transcriptItems.map((txItem, index) => {
                                return (
                                  <TranscriptItem
                                    key={index}
                                    currentVideoTime={props.currentVideoTime}
                                    txItem={txItem}
                                    recording={props.recording}
                                    videoPlayerRef={props.videoPlayerRef}
                                    isEditing={false}
                                    setTopicStart={setTopicStart}
                                    setTopicEnd={setTopicEnd}
                                    isPublic={props.isPublic}
                                    topic={topic}
                                    editingTopicIndex={props.editingTopicIndex}
                                    setEditingTopicIndex={
                                      props.setEditingTopicIndex
                                    }
                                    setEditingTopicTimes={
                                      props.setEditingTopicTimes
                                    }
                                    setTopicChunks={props.setTopicChunks}
                                    calculateTopicChunks={
                                      props.calculateTopicChunks
                                    }
                                    topics={props.topics}
                                    inTopic={topic.isTopic}
                                    addNewTopic={props.addNewTopic}
                                    scrollWithVideo={scrollWithVideo}
                                    scrollTo={scrollTo}
                                    setScrollTo={setScrollTo}
                                    searchTerm={props.searchTerm}
                                    setSearchTerm={props.setSearchTerm}
                                    transcriptParentRef={transcriptParentRef}
                                  />
                                );
                              })}
                          </div>
                        </div>
                      )}
                    {/* Topic topics */}
                    {topic.isTopic && topic.transcriptItems.length > 0 && (
                      <div
                        className={`border-${getTopicColor(
                          topic,
                          index
                        )} border-2`}
                        style={{
                          background: "rgba(100, 189, 189, 0.1)",
                        }}
                      >
                        {/* Header */}
                        <div
                          style={{
                            position: "sticky",
                            alignSelf: "flex-start",
                            top: 0,
                            zIndex: 1,
                          }}
                          className={`flex items-center justify-between bg-${getTopicColor(
                            topic,
                            index
                          )} h-8`}
                        >
                          {/* Toggle & Title */}
                          <div className="flex items-center">
                            {/* Toggle */}
                            <>
                              {topic.open ? (
                                <button
                                  onClick={() => {
                                    topic.open = false;
                                    const newTopic = structuredClone(topic);
                                    props.topics[index] = newTopic;
                                    const updatedTopics = structuredClone(
                                      props.topics
                                    );
                                    props.setTopics(updatedTopics);
                                  }}
                                >
                                  <ChevronDownIcon
                                    className="h-4 w-4 text-white inline ml-1 mr-2 my-auto"
                                    aria-hidden="true"
                                  />
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    topic.open = true;
                                    const updatedTopics = structuredClone(
                                      props.topics
                                    );
                                    props.setTopics(updatedTopics);
                                  }}
                                >
                                  <ChevronRightIcon
                                    className="h-4 w-4 text-white inline ml-1 mr-2 my-auto"
                                    aria-hidden="true"
                                  />
                                </button>
                              )}
                            </>
                            {/* Title */}
                            <span
                              title={topic.name ? topic.name : "Unnamed topic"}
                              className="text-lg font-bold text-white text-ellipsis overflow-hidden whitespace-nowrap max-w-[160px] xl:max-w-[240px] 2xl:max-w-[320px]"
                            >
                              <span>#</span>
                              {topic.name ? topic.name : "Unnamed topic"}
                            </span>
                            <span
                              style={{ paddingTop: "2.5px" }}
                              className="pl-2 text-white text-sm hidden lg:inline-block"
                            >
                              {/* There's inconsistencies with how the time is set in the topics page vs recording page.  One has the offset accounted for and the other doesn't */}
                              {formatTranscriptItemTime(
                                topic.CreatedAt
                                  ? topic.start
                                  : topic.start +
                                      props.recording.recordingTimeOffset,
                                props.recording.recordingTimeOffset
                              )}
                              {" - "}
                              {formatTranscriptItemTime(
                                topic.CreatedAt
                                  ? topic.end
                                  : topic.end +
                                      props.recording.recordingTimeOffset,
                                props.recording.recordingTimeOffset
                              )}
                            </span>
                          </div>
                          {/* Copy & Edit */}
                          {!props.editingTopicIndex &&
                            props.topicsPage === false && (
                              <span className="">
                                <ReactTooltip
                                  id={"tooltip" + topic.id}
                                  effect="solid"
                                  event="click"
                                  delayHide={1000}
                                  afterShow={() => {
                                    ReactTooltip.hide();
                                    copyShareLinkToClipboard(
                                      props.recording.uuid,
                                      topic.identifier
                                    );
                                  }}
                                />
                                {/* Edit topic button */}
                                <button
                                  data-tip="Link Copied!"
                                  data-for={"tooltip" + topic.id}
                                  data-type="light"
                                  data-place="top"
                                  className="mr-3"
                                  onClick={(e) => {
                                    console.log("clicked copy link");
                                    copyShareLinkToClipboard(
                                      props.recording.uuid,
                                      topic.identifier
                                    );
                                  }}
                                >
                                  <LinkIcon
                                    className="h-4 w-4 text-white"
                                    aria-hidden="true"
                                  />{" "}
                                </button>
                                {!props.isPublic && (
                                  <button
                                    className="mr-4"
                                    type="button"
                                    onClick={() => {
                                      props.setEditingTopicIndex(index);

                                      const editingTopic = props.topics[index];
                                      props.setEditingTopicTimes([
                                        editingTopic.start,
                                        editingTopic.end,
                                      ]);

                                      var eventProperties = {
                                        recording_uuid: props.recording.uuid,
                                        topic_uuid: editingTopic.identifier,
                                        origin: "transcript",
                                      };
                                      amplitude
                                        .getInstance()
                                        .logEvent(
                                          "clicked edit topic",
                                          eventProperties
                                        );
                                    }}
                                  >
                                    <PencilAltIcon
                                      className="h-4 w-4 text-white"
                                      aria-hidden="true"
                                    />
                                  </button>
                                )}
                              </span>
                            )}
                        </div>
                        {/* TX items */}
                        <div className="px-2">
                          {topic.open &&
                            topic.transcriptItems.map((txItem, index) => {
                              return (
                                <TranscriptItem
                                  key={index}
                                  currentVideoTime={props.currentVideoTime}
                                  txItem={txItem}
                                  recording={props.recording}
                                  videoPlayerRef={props.videoPlayerRef}
                                  isEditing={false}
                                  setTopicStart={setTopicStart}
                                  setTopicEnd={setTopicEnd}
                                  topic={topic}
                                  editingTopicIndex={props.editingTopicIndex}
                                  setEditingTopicIndex={
                                    props.setEditingTopicIndex
                                  }
                                  setTopicChunks={props.setTopicChunks}
                                  calculateTopicChunks={
                                    props.calculateTopicChunks
                                  }
                                  topics={props.topics}
                                  inTopic={topic.isTopic}
                                  addNewTopic={props.addNewTopic}
                                  scrollWithVideo={scrollWithVideo}
                                  scrollTo={scrollTo}
                                  setScrollTo={setScrollTo}
                                  searchTerm={props.searchTerm}
                                  setSearchTerm={props.setSearchTerm}
                                  transcriptParentRef={transcriptParentRef}
                                />
                              );
                            })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )
            );
          })}
        </div>
      </div>
      {props.editingTopicIndex && (
        <div
          className="pb-3 pt-3 bg-white fixed bottom-0 z-10 border-t border-gray-200"
          style={{ width: transcriptParentWidth + "px" }}
        >
          <div className="grid grid-cols-2">
            <span className="mt-1.5">
              <button
                className="h-10 ml-3 my-auto justify-self-end inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => {
                  let momentIndex = null;
                  props.recording.moments.forEach((moment, index) => {
                    // really dumb.  .includes() doesn't exist on type numbers
                    // so we're just casting to a string to check
                    // we literally could just check if it's a string instead of a number 😂
                    if (`${moment.ID}`.includes("new-topic")) {
                      momentIndex = index;
                    }

                    if (
                      moment.ID === props.topics[props.editingTopicIndex].id
                    ) {
                      moment.start_time =
                        props.editingTopicTimes[0] +
                        props.recording.recordingTimeOffset;
                      moment.end_time =
                        props.editingTopicTimes[1] +
                        +props.recording.recordingTimeOffset;
                      const newM = structuredClone(moment);
                      props.recording.moments[index] = newM;
                    }
                  });

                  if (momentIndex || momentIndex === 0) {
                    props.recording.moments.splice(momentIndex, 1);
                  }

                  const newRecording = structuredClone(props.recording);
                  const topics = props.structureTopicsData(
                    newRecording,
                    props.videoDuration
                  );
                  props.setTopics(topics);

                  const newTC = props.calculateTopicChunks(
                    props.recording,
                    props.videoPlayerRef
                  );
                  props.setTopicChunks(newTC);

                  props.setEditingTopicIndex(null);
                  props.setEditingTopicTimes([0, 0]);
                }}
              >
                Cancel
              </button>
            </span>
            <span className="text-right mt-1.5">
              <button
                className="h-10 mr-3 my-auto justify-self-end inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => {
                  if (props.topics[props.editingTopicIndex]) {
                    props.videoPlayerRef.current.currentTime =
                      props.topics[props.editingTopicIndex].start;
                    props.videoPlayerRef.current.play();
                  }
                }}
              >
                Play
              </button>
              {/* Save topic  */}
              <button
                className="h-10 mr-3 my-auto justify-self-end inline-flex items-center px-4 py-2 border border-brand-green shadow-sm text-sm font-medium rounded-md text-white bg-brand-green hover:bg-brand-green-darker2"
                onClick={() => {
                  setSaveModalOpen(true);
                }}
              >
                Save
              </button>
            </span>
          </div>
        </div>
      )}
      {/* Save modal */}
      <Transition.Root show={saveModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setSaveModalOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  style={{ width: "544px" }}
                  className="relative transform overflow-visible rounded-lg bg-white p-5 text-left shadow-xl transition-all "
                >
                  {/* X out button */}
                  <div className="flex justify-end">
                    <button
                      style={{ borderRadius: "1000px" }}
                      className="p-[10px] hover:bg-gray-200"
                      onClick={() => setSaveModalOpen(false)}
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.469828 0.469177C0.610453 0.328726 0.801078 0.249837 0.999828 0.249837C1.19858 0.249837 1.3892 0.328726 1.52983 0.469177L6.99983 5.93918L12.4698 0.469177C12.5385 0.39549 12.6213 0.336388 12.7133 0.295396C12.8053 0.254404 12.9046 0.232362 13.0053 0.230585C13.106 0.228809 13.206 0.247333 13.2994 0.285054C13.3928 0.322775 13.4776 0.37892 13.5489 0.450138C13.6201 0.521357 13.6762 0.606191 13.714 0.699579C13.7517 0.792967 13.7702 0.892997 13.7684 0.993699C13.7666 1.0944 13.7446 1.19372 13.7036 1.28571C13.6626 1.37771 13.6035 1.46052 13.5298 1.52918L8.05983 6.99918L13.5298 12.4692C13.6035 12.5378 13.6626 12.6206 13.7036 12.7126C13.7446 12.8046 13.7666 12.904 13.7684 13.0047C13.7702 13.1054 13.7517 13.2054 13.714 13.2988C13.6762 13.3922 13.6201 13.477 13.5489 13.5482C13.4776 13.6194 13.3928 13.6756 13.2994 13.7133C13.206 13.751 13.106 13.7695 13.0053 13.7678C12.9046 13.766 12.8053 13.7439 12.7133 13.703C12.6213 13.662 12.5385 13.6029 12.4698 13.5292L6.99983 8.05918L1.52983 13.5292C1.38765 13.6617 1.19961 13.7338 1.00531 13.7304C0.811005 13.7269 0.625619 13.6482 0.488206 13.5108C0.350793 13.3734 0.272082 13.188 0.268653 12.9937C0.265225 12.7994 0.337348 12.6114 0.469828 12.4692L5.93983 6.99918L0.469828 1.52918C0.329378 1.38855 0.250488 1.19793 0.250488 0.999177C0.250488 0.800426 0.329378 0.609802 0.469828 0.469177Z"
                          fill="#374151"
                        />
                      </svg>
                    </button>
                  </div>

                  {/* Header (Name your topic) */}
                  <div className="flex justify-center">
                    <span
                      style={{
                        fontFamily: "'DM Sans'",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      Name your Topic
                    </span>
                  </div>
                  {/* Sub header (Hint: ...) */}
                  <div className="flex justify-center">
                    <span
                      style={{
                        fontFamily: "'DM Sans'",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "24px",
                        paddingTop: "12px",
                      }}
                    >
                      Hint: Reuse topic names to better analyze those topics
                      later.
                    </span>
                  </div>
                  {/* Search */}
                  <div className="text-center items-center w-full">
                    <div className="pb-3">
                      <div className="relative mt-5 justify-center flex items-center">
                        {/* Combo box */}
                        <Combobox
                          as="div"
                          ref={comboboxRef}
                          className="w-full"
                          open={comboboxOpen}
                          value={editTopicName}
                          onChange={(selection) => {
                            setEditTopicName(selection.name);
                            setComboboxOpen(false);
                          }}
                        >
                          {({ open }) => {
                            return (
                              <div className="relative w-full mt-1">
                                <HashtagIcon
                                  className="pointer-events-none absolute top-2 left-4 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <Combobox.Input
                                  placeholder="Name your topic"
                                  className="w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-5 shadow-sm focus:border-brand-green-lighter3 focus:outline-none focus:ring-1 focus:ring-brand-green-lighter3 sm:text-sm"
                                  onChange={(event) =>
                                    setQuery(event.target.value)
                                  }
                                  onKeyDown={(event) => {
                                    if (event.key === "Escape") {
                                      event.preventDefault();
                                      event.target.blur();
                                    }
                                  }}
                                  onFocus={(event) => {
                                    if (event.target.value === "") {
                                      setComboboxOpen(true);
                                    }
                                  }}
                                />

                                {(open || comboboxOpen) && (
                                  <Combobox.Options
                                    static
                                    id="testid"
                                    className="absolute z-10 mt-1 max-h-[20rem] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                  >
                                    <h2
                                      style={{ textAlign: "start" }}
                                      className="bg-gray-100 text-start py-2.5 px-4 text-xs font-semibold text-gray-900"
                                    >
                                      Create new Topic
                                    </h2>

                                    {query ? (
                                      <Combobox.Option
                                        key="keyword-option"
                                        value={{ name: query, type: "keyword" }}
                                        className={({ active }) =>
                                          classNames(
                                            "relative cursor-default select-none py-2 pl-3 pr-9",
                                            active
                                              ? "bg-brand-green-lighter3 text-white font-semibold"
                                              : "text-gray-900"
                                          )
                                        }
                                      >
                                        {({ active, selected }) => (
                                          <>
                                            <div className="flex">
                                              <div>
                                                <span
                                                  className={classNames(
                                                    "truncate",
                                                    selected && "font-semibold"
                                                  )}
                                                >
                                                  {`"${query}"`}
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </Combobox.Option>
                                    ) : (
                                      <span
                                        style={{ textAlign: "start" }}
                                        className={classNames(
                                          "my-2 flex ml-2 truncate text-gray-500",
                                          "text-gray-500"
                                        )}
                                      >
                                        Start typing to create a new Topic
                                      </span>
                                    )}
                                    <h2
                                      style={{ textAlign: "start" }}
                                      className="bg-gray-100 text-start py-2.5 px-4 text-xs font-semibold text-gray-900"
                                    >
                                      Existing Topics
                                    </h2>
                                    {filteredTopicSearch.map((topic) => (
                                      <Combobox.Option
                                        key={topic.ID}
                                        value={{
                                          name: topic.name,
                                          type: "topic",
                                          labelID: topic.ID,
                                        }}
                                        className={({ active }) =>
                                          classNames(
                                            "relative cursor-default select-none py-2 pl-3 pr-9",
                                            active
                                              ? "bg-brand-green-lighter3 text-white font-semibold"
                                              : "text-gray-900"
                                          )
                                        }
                                      >
                                        {({ active, selected }) => (
                                          <>
                                            <div className="flex">
                                              <span
                                                className={classNames(
                                                  "truncate",
                                                  selected && "font-semibold"
                                                )}
                                              >
                                                #{topic.name}
                                              </span>
                                            </div>
                                          </>
                                        )}
                                      </Combobox.Option>
                                    ))}
                                    {filteredTopicSearch.length === 0 && (
                                      <span
                                        style={{ textAlign: "start" }}
                                        className={classNames(
                                          "flex ml-2 truncate text-gray-500",
                                          "text-gray-500"
                                        )}
                                      >
                                        {`No topics match "${query}"`}
                                      </span>
                                    )}
                                  </Combobox.Options>
                                )}
                              </div>
                            );
                          }}
                        </Combobox>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end pt-5">
                    {/* Cancel */}
                    <button
                      type="button"
                      className="w-[68px] inline-flex justify-center items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-green-lighter2 focus:ring-offset-2"
                      onClick={() => setSaveModalOpen(false)}
                    >
                      Cancel
                    </button>
                    {/* Create Topic Modal Save */}
                    <button
                      type="button"
                      className="w-[68px] ml-3 inline-flex justify-center items-center rounded-md border border-transparent bg-brand-green px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand-green-darker2 focus:outline-none focus:ring-2 focus:ring-brand-green-lighter2 focus:ring-offset-2"
                      onClick={() => {
                        const topic = props.topics[props.editingTopicIndex];
                        if (topic) {
                          if (topic.creatingTopic) {
                            topic.name = editTopicName;

                            let foundTopic = false;
                            topicLabels.forEach((label) => {
                              if (
                                label.cleaned_name ===
                                editTopicName.replace(" ", "")
                              ) {
                                foundTopic = true;
                              }
                            });

                            if (foundTopic === false) {
                              topicLabels.push({
                                name: editTopicName,
                                cleaned_name: editTopicName.replace(" ", ""),
                              });
                              const newTL = structuredClone(topicLabels);
                              setTopicLabels(newTL);
                            }

                            props.recording.moments.forEach((moment, index) => {
                              if (moment.ID === topic.id) {
                                const newID = moment.ID.replace(
                                  "new-topic-",
                                  ""
                                );
                                moment.creatingTopic = false;
                                moment.name = topic.name;
                                const newMoment = structuredClone(moment);
                                newMoment.ID = newID;
                                props.recording.moments[index] = newMoment;
                              }
                            });

                            props.setRecording(props.recording);

                            const createdTopicResponse = createTopicBackend(
                              topic,
                              props.userEmail,
                              props.recording.uuid,
                              props.recording.recordingTimeOffset
                            );

                            createdTopicResponse.then((officialTopic) => {
                              props.recording.moments.forEach(
                                (moment, index) => {
                                  if (moment.identifier === "new-topic") {
                                    props.recording.moments[index] =
                                      officialTopic;
                                  }
                                }
                              );

                              props.setRecording(props.recording);

                              const newTopics = props.structureTopicsData(
                                props.recording,
                                props.videoDuration
                              );

                              props.setTopics(newTopics);
                              props.setEditingTopicIndex(null);
                              props.setEditingTopicTimes([0, 0]);

                              var eventProperties = {
                                recording_uuid: props.recording.uuid,
                                origin: "web app",
                                topic_name: topic.name,
                              };
                              amplitude
                                .getInstance()
                                .logEvent("created new topic", eventProperties);
                            });
                          } else if (topic.id) {
                            // update topic
                            updateTopicBackend(
                              topic,
                              props.userEmail,
                              props.recording.uuid,
                              props.recording.recordingTimeOffset
                            );
                          }
                        }
                        setSaveModalOpen(false);
                        props.setEditingTopicIndex(null);
                        props.setEditingTopicTimes([0, 0]);

                        var eventProperties = {
                          recording_uuid: props.recording.uuid,
                          topic_uuid: topic.identifier,
                          topic_name: topic.name,
                        };
                        amplitude
                          .getInstance()
                          .logEvent("edited topic", eventProperties);
                      }}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default Transcript;

const TranscriptItem = (props) => {
  const ref = useRef();
  const [showEditOverlay, setShowEditOverlay] = useState(false);
  const [isCurrent, setIsCurrent] = useState(false);
  const [createTopicDisplay, setCreateTopicDisplay] = useState("none");

  // props.scrollTo
  useEffect(() => {
    if (props.scrollTo === props.txItem.ID) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      props.setScrollTo(0);
    }
    // }
  }, [props.scrollTo]);

  // Scroll state for "sync to video" button
  useEffect(() => {
    if (props.scrollWithVideo) {
      if (
        props.currentVideoTime >
          props.txItem.itemStart - props.recording.recordingTimeOffset &&
        props.currentVideoTime <
          props.txItem.itemEnd - props.recording.recordingTimeOffset
      ) {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [props.scrollWithVideo]);

  // Scroll behavior for search transcript
  useEffect(() => {
    if (
      props.txItem.scrollTo &&
      ref.current &&
      ((props.scrollWithVideo && props.searchTerm !== "") || props.searchTerm)
    ) {
      props.txItem.scrollTo = false;
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [props.txItem]);

  // Scroll behavior for following along with video
  useEffect(() => {
    if (
      props.currentVideoTime >
        props.txItem.itemStart - props.recording.recordingTimeOffset &&
      props.currentVideoTime <
        props.txItem.itemEnd - props.recording.recordingTimeOffset &&
      props.scrollWithVideo &&
      !props.searchTerm
    ) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      setIsCurrent(true);
    } else {
      setIsCurrent(false);
    }
  }, [props.currentVideoTime]);

  function isEditMode() {
    // You can only edit if you're in the same topic OR a bordering topic where isTopic = false
    if (props.editingTopicIndex) {
      let editingParentTopic =
        props.topics[props.editingTopicIndex] === props.topic ? true : false;

      if (editingParentTopic) {
        return true;
      } else {
        // if the parent topic is a direct neighbor and isTopic = false
        if (props.topic.isTopic === false) {
          try {
            if (
              props.topics[props.editingTopicIndex - 1] === props.topic ||
              props.topics[props.editingTopicIndex + 1] === props.topic
            ) {
              return true;
            }
          } catch (e) {
            return false;
          }
        }
      }
    }
    return false;
  }

  function showSetStartTopic() {
    // You can't set a starting point past an end mark, we'll assume it can be equal
    let shouldShowStart =
      props.txItem.itemStart - props.recording.recordingTimeOffset <=
      props.topics[props.editingTopicIndex].end;

    let alreadyInStart =
      props.topics[props.editingTopicIndex]?.transcriptItems[0] ===
      props.txItem;
    return shouldShowStart && !alreadyInStart;
  }

  function txItemClassStyles() {
    let sharedStyles =
      " w-[100%] border py-1 cursor-pointer px-5 rounded-md relative";
    if (props.txItem.matchesSearchTerm === true) {
      return "bg-yellow-200" + sharedStyles;
    } else if (isCurrent) {
      return "bg-gray-200 border-gray-300" + sharedStyles;
    } else {
      return "bg-white hover:bg-gray-100" + sharedStyles;
    }
  }

  function showSetEndTopic() {
    // You can't set an end mark before a start mark, we'll assume it can be equal
    let shouldShowEnd =
      props.txItem.itemStart - props.recording.recordingTimeOffset >=
      props.topics[props.editingTopicIndex].start;

    let txItems = props.topics[props.editingTopicIndex]?.transcriptItems;

    let alreadyInEnd = txItems[txItems.length - 1] === props.txItem;
    return shouldShowEnd && !alreadyInEnd;
  }

  function showCreateTopic() {
    if (props.isPublic) {
      return false;
    }

    if (
      isEditMode() === false &&
      props.inTopic === false &&
      props.editingTopicIndex === null
    ) {
      return true;
    }

    return false;
  }

  return (
    <div
      onMouseEnter={(e) => {
        setCreateTopicDisplay("flex");
      }}
      onMouseLeave={(e) => {
        setCreateTopicDisplay("none");
      }}
      ref={ref}
      className="w-[100%] py-1 flex flex-col items-center"
    >
      <div
        className={txItemClassStyles()}
        style={{ minHeight: "80px" }}
        onClick={() => {
          let offset = props.recording.recordingTimeOffset;
          if (!offset) {
            offset = props.recording.start_time;
          }
          props.videoPlayerRef.current.currentTime =
            props.txItem.itemStart - offset;
          props.videoPlayerRef.current.play();
        }}
        onMouseEnter={() => {
          if (isEditMode()) {
            setShowEditOverlay(true);
          }
        }}
        onMouseLeave={() => {
          if (isEditMode()) {
            setShowEditOverlay(false);
          }
        }}
      >
        <div className="flex">
          <div className="flex items-center">
            <PlayIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
          </div>

          <div className="flex flex-col pl-3 py-2 text-sm">
            <div>
              <span className="font-bold inline-flex text-gray-800">
                {props.txItem.speaker}
              </span>
              <span className="ml-3 text-sm inline-flex text-gray-700">
                {formatTranscriptItemTime(
                  props.txItem.itemStart,
                  props.recording.recordingTimeOffset
                    ? props.recording.recordingTimeOffset
                    : props.recording.start_time
                )}{" "}
                -{" "}
                {formatTranscriptItemTime(
                  props.txItem.itemEnd,
                  props.recording.recordingTimeOffset
                    ? props.recording.recordingTimeOffset
                    : props.recording.start_time
                )}
              </span>
            </div>
            <div className="flex justify-between text-sm text-gray-800">
              {props.txItem.transcript}
            </div>
          </div>
          {isEditMode() && showEditOverlay && (
            <div
              className="h-full w-full block top-0 left-0 absolute rounded-md"
              style={{ background: "rgba(0, 0, 0, 0.2)" }}
            >
              <div className="grid grid-cols-3 ">
                <div>
                  <button
                    type="button"
                    className="inline-flex mt-5 ml-3 items-center rounded-full border border-transparent bg-white p-2.5 text-white shadow-md hover:bg-gray-50 focus:outline-none focus:shadow-sm "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-4 h-4 text-brand-green"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div></div>
                <div className="text-right">
                  <button
                    type="button"
                    className="w-[110px] mt-1.5 mr-3 text-center inline-block items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      const topicID = props.topics[props.editingTopicIndex].id;
                      props.setTopicStart(topicID, props.txItem);
                    }}
                    disabled={!showSetStartTopic()}
                  >
                    Set Topic Start
                  </button>

                  <button
                    type="button"
                    className="w-[110px] mt-1 mr-3 text-center inline-block items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      const topicID = props.topics[props.editingTopicIndex].id;
                      props.setTopicEnd(topicID, props.txItem);
                    }}
                    disabled={!showSetEndTopic()}
                  >
                    Set Topic End
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function formatTranscriptItemTime(itemStart, recordingTimeOffset) {
  const itemTime =
    itemStart - recordingTimeOffset >= 0 ? itemStart - recordingTimeOffset : 0;
  let startTime = moment("2015-01-01").startOf("day").seconds(itemTime);

  if (startTime.hour() !== 0) {
    return startTime.format("H:mm:ss");
  } else {
    return startTime.format("mm:ss");
  }
}
