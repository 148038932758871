import React, { useState, useEffect } from "react";

import {
  ChatIcon,
  ChevronRightIcon,
  DocumentTextIcon,
  LinkIcon,
  LockClosedIcon,
  MinusIcon,
  UserGroupIcon,
} from "@heroicons/react/solid";
import GoogleDriveFolderPicker from "./GoogleDriveFilePicker";
import amplitude from "amplitude-js";
import { FolderIcon } from "@heroicons/react/outline";

const CollectionIntegrationsTab = ({ collection, user }) => {
  const [slackConnected, setSlackConnected] = useState(false);
  const [slackChannels, setSlackChannels] = useState([]);
  const [selectedSlackChannels, setSelectedSlackChannels] = useState([]);
  const [googleDriveConnected, setGoogleDriveConnected] = useState(false);
  const [selectedGoogleDriveFolders, setSelectedGoogleDriveFolders] = useState(
    []
  );

  useEffect(() => {
    fetchSlackStatus();
    fetchGoogleDriveStatus();
  }, [collection]);

  const fetchSlackStatus = async () => {
    try {
      const response = await fetch("https://backend.scribbl.co/slack/status", {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setSlackConnected(data.connected);
        if (data.connected) {
          fetchSlackChannels();
          fetchSlackChannelsForCollection();
        }
      }
    } catch (error) {
      console.error("Error fetching Slack status:", error);
    }
  };

  const fetchGoogleDriveStatus = async () => {
    try {
      const response = await fetch(
        "https://backend.scribbl.co/google-drive/status",
        {
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setGoogleDriveConnected(data.connected);
        if (data.connected) {
          fetchGoogleDriveFoldersForCollection();
        }
      }
    } catch (error) {
      console.error("Error fetching Google Drive status:", error);
    }
  };

  const handleConnectSlack = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/slack/connect?collection_uuid=${collection.uuid}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();

        amplitude
          .getInstance()
          .logEvent("Collection dashboard: integrations - slack connected");

        window.location.href = data.authUrl;
      }
    } catch (error) {
      console.error("Error connecting to Slack:", error);
    }
  };

  const handleDisconnectSlack = async () => {
    try {
      await fetch("https://backend.scribbl.co/slack/disconnect", {
        method: "POST",
        credentials: "include",
      });
      setSlackConnected(false);
      setSelectedSlackChannels([]);
    } catch (error) {
      console.error("Error disconnecting from Slack:", error);
    }
  };

  const fetchSlackChannels = async () => {
    try {
      const response = await fetch("https://backend.scribbl.co/slack/channels", {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setSlackChannels(data.channels);
      }
    } catch (error) {
      console.error("Error fetching Slack channels:", error);
    }
  };

  const handleAddSlackChannel = async (channelId) => {
    if (selectedSlackChannels.some((channel) => channel.id === channelId)) {
      console.log("This channel is already added.");
      return;
    }

    try {
      await fetch("https://backend.scribbl.co/slack/channel", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ channelId, collectionId: collection.ID }),
      });
      fetchSlackChannelsForCollection();

      amplitude
        .getInstance()
        .logEvent("Collection dashboard: integrations - slack channel added");
    } catch (error) {
      console.error("Error adding Slack channel:", error);
    }
  };

  const handleRemoveSlackChannel = async (channelId) => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/slack/channel/${channelId}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ collectionId: collection.ID }),
        }
      );

      if (response.ok) {
        fetchSlackChannelsForCollection();
      } else {
        const errorData = await response.json();
        console.error("Error removing Slack channel:", errorData.message);
      }
    } catch (error) {
      console.error("Error removing Slack channel:", error);
    }
  };

  const fetchSlackChannelsForCollection = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/slack/channels/${collection.ID}`,
        {
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setSelectedSlackChannels(data.channels);
      }
    } catch (error) {
      console.error("Error fetching Slack channels for collection:", error);
    }
  };

  const handleConnectGoogleDrive = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/google-drive/connect`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (data.status === "success") {
          setGoogleDriveConnected(true);

          amplitude
            .getInstance()
            .logEvent("Collection dashboard: integrations - drive connected");
        }
      }
    } catch (error) {
      console.error("Error connecting to Google Drive:", error);
    }
  };

  const handleDisconnectGoogleDrive = async () => {
    try {
      await fetch("https://backend.scribbl.co/google-drive/disconnect", {
        method: "POST",
        credentials: "include",
      });
      setGoogleDriveConnected(false);
      setSelectedGoogleDriveFolders([]);
    } catch (error) {
      console.error("Error disconnecting from Google Drive:", error);
    }
  };

  const handleSelectGoogleDriveFolder = async (folderId, folderName) => {
    if (selectedGoogleDriveFolders.some((folder) => folder.id === folderId)) {
      console.log("This folder is already added.");
      return;
    }

    try {
      await fetch("https://backend.scribbl.co/google-drive/folder", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          folderId,
          folderName,
          collectionId: collection.ID,
        }),
      });
      fetchGoogleDriveFoldersForCollection();

      amplitude
        .getInstance()
        .logEvent("Collection dashboard: integrations - drive folder added");
    } catch (error) {
      console.error("Error adding Google Drive folder:", error);
    }
  };
  const handleRemoveGoogleDriveFolder = async (folderId) => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/google-drive/folder?folderId=${folderId}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ collectionId: collection.ID }),
        }
      );

      if (response.ok) {
        fetchGoogleDriveFoldersForCollection();
      } else {
        const errorData = await response.json();
        console.error("Error removing Google Drive folder:", errorData.message);
      }
    } catch (error) {
      console.error("Error removing Google Drive folder:", error);
    }
  };

  const fetchGoogleDriveFoldersForCollection = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/google-drive/folders/${collection.ID}`,
        {
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setSelectedGoogleDriveFolders(data.folders);
      }
    } catch (error) {
      console.error(
        "Error fetching Google Drive folders for collection:",
        error
      );
    }
  };

  useEffect(() => {
    if (slackConnected) {
      fetchSlackChannels();
    }
  }, [slackConnected, googleDriveConnected]);

  return (
    <div className="relative min-h-screen">
      {/* This div now has 'relative' and 'min-h-screen' */}
      <div className="space-y-8">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="flex items-center space-x-3 text-lg leading-6 font-medium text-gray-900">
              <span>Integrations</span>
              <div className="flex items-center text-xs text-gray-500 bg-gray-100 rounded-full px-3 py-1">
                Team Feature
              </div>
            </h3>
            <div className="mt-2 text-sm text-gray-500">
              Configure integrations to automatically sync meetings with your
              team's tools
            </div>
            <div className="mt-6 space-y-8">
              {/* Slack Integration Section */}
              <div>
                <h4 className="text-md font-medium text-gray-700 mb-2">
                  Slack Integration
                </h4>
                <p className="text-sm text-gray-500 mb-4">
                  Automatically send meeting notes and action items to your
                  chosen Slack channels for any meetings added to this
                  collection.
                </p>
                <div className="mt-5">
                  {!slackConnected ? (
                    <button
                      type="button"
                      onClick={handleConnectSlack}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-turq hover:bg-brand-green-lighter1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
                    >
                      <LinkIcon className="mr-2 h-5 w-5" />
                      Connect to Slack
                    </button>
                  ) : (
                    <div>
                      <button
                        type="button"
                        onClick={handleDisconnectSlack}
                        className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
                      >
                        Disconnect from Slack
                      </button>
                      <div className="mt-4">
                        <label
                          htmlFor="slack-channel"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Select Slack Channels
                        </label>
                        <select
                          id="slack-channel"
                          name="slack-channel"
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-turq focus:border-brand-turq sm:text-sm rounded-md"
                          onChange={(e) =>
                            handleAddSlackChannel(e.target.value)
                          }
                          value=""
                        >
                          <option value="">Add a channel</option>
                          {slackChannels.map((channel) => (
                            <option key={channel.id} value={channel.id}>
                              {channel.name}
                            </option>
                          ))}
                        </select>
                        <div className="mt-2 space-y-2">
                          {selectedSlackChannels.map((channel) => (
                            <div
                              key={channel.id}
                              className="flex items-center justify-between bg-gray-100 px-3 py-2 rounded-md"
                            >
                              <span>
                                {channel ? channel.name : "Unknown Channel"}
                              </span>
                              <button
                                onClick={() =>
                                  handleRemoveSlackChannel(channel.id)
                                }
                                className="text-red-600 hover:text-red-800"
                              >
                                <MinusIcon className="h-5 w-5" />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* Google Drive Integration Section */}
              <div>
                <h4 className="text-md font-medium text-gray-700 mb-2">
                  Google Drive Integration
                </h4>
                <p className="text-sm text-gray-500 mb-4">
                  Automatically create Google Docs in your selected Google Drive
                  folders with meeting notes and action items. A new Doc is
                  generated for each meeting added to this collection.
                </p>
                <div className="mt-5">
                  {!googleDriveConnected ? (
                    <button
                      type="button"
                      onClick={handleConnectGoogleDrive}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-turq hover:bg-brand-green-lighter1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
                    >
                      <LinkIcon className="mr-2 h-5 w-5" />
                      Connect to Google Drive
                    </button>
                  ) : (
                    <div>
                      <button
                        type="button"
                        onClick={handleDisconnectGoogleDrive}
                        className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
                      >
                        Disconnect from Google Drive
                      </button>
                      <div className="mt-4">
                        <GoogleDriveFolderPicker
                          onFolderSelect={handleSelectGoogleDriveFolder}
                          disabled={!googleDriveConnected}
                        />
                        <div className="mt-2 space-y-2">
                          {selectedGoogleDriveFolders.map((folder) => (
                            <div
                              key={folder.id}
                              className="flex items-center justify-between bg-gray-100 px-3 py-2 rounded-md"
                            >
                              <span>{folder.name}</span>
                              <button
                                onClick={() =>
                                  handleRemoveGoogleDriveFolder(folder.id)
                                }
                                className="text-red-600 hover:text-red-800"
                              >
                                <MinusIcon className="h-5 w-5" />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {(!user?.subscription ||
              (user.subscription.accountType !== 3 &&
                user.subscription.accountType !== 4)) && (
              <IntegrationsUpgradeOverlay user={user} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const IntegrationsUpgradeOverlay = ({ user }) => {
  const handleCalendlyClick = (e) => {
    e.preventDefault();
    // Updated with correct a1 field name for additional details
    const calendlyURL = `https://calendly.com/mike-sallese/meeting-with-mike?name=${
      user.name || ""
    }&email=${
      user.email || ""
    }&a1=collections%20integrations%20tab%20demo%20inquiry`;
    window.open(calendlyURL, "_blank");
  };

  return (
    <div className="absolute inset-0 bg-white/95 backdrop-blur-sm">
      <div className="w-full h-full py-2">
        <div className="max-w-lg mx-auto bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          {/* Header */}
          <div className="text-center p-6 pb-0">
            <h3 className="text-xl font-semibold text-gray-900">
              Keep Your Team's Tools in Sync
            </h3>
            <p className="mt-2 text-sm text-gray-600">
              Automatically share meeting content where your team already works
            </p>
          </div>

          {/* Main Example Card */}
          <div className="p-6">
            <div className="space-y-6">
              {/* Google Drive Integration */}
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <div className="h-8 w-8 rounded-full bg-[#E15C54]/10 flex items-center justify-center">
                    <DocumentTextIcon className="h-4 w-4 text-[#E15C54]" />
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-sm font-medium text-gray-900 mb-1">
                    Google Drive Integration
                  </h4>
                  <p className="text-sm text-gray-600 mb-3">
                    Sync meeting notes, action items, and transcripts to your
                    chosen Google Drive folders.
                  </p>
                  <div className="bg-gray-50 rounded-lg p-3">
                    <div className="flex items-center mb-2">
                      <FolderIcon className="h-4 w-4 text-gray-500 mr-2" />
                      <span className="text-xs font-medium text-gray-900">
                        Sync to any folders
                      </span>
                    </div>
                    <div className="text-xs text-gray-600 space-y-1 ml-6">
                      <p>• Client project folders</p>
                      <p>• Team shared drives</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Slack Integration */}
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <div className="h-8 w-8">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                      <circle
                        cx="16"
                        cy="16"
                        r="16"
                        fill="#E15C54"
                        fillOpacity="0.1"
                      />
                      <g transform="translate(8, 8) scale(0.125)">
                        <path
                          d="M27.2 80c0 7.3-5.9 13.2-13.2 13.2C6.7 93.2.8 87.3.8 80c0-7.3 5.9-13.2 13.2-13.2h13.2V80zm6.6 0c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2v33c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V80z"
                          fill="#E15C54"
                        />
                        <path
                          d="M47 27c-7.3 0-13.2-5.9-13.2-13.2C33.8 6.5 39.7.6 47 .6c7.3 0 13.2 5.9 13.2 13.2V27H47zm0 6.7c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H13.9C6.6 60.1.7 54.2.7 46.9c0-7.3 5.9-13.2 13.2-13.2H47z"
                          fill="#E15C54"
                        />
                        <path
                          d="M99.9 46.9c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H99.9V46.9zm-6.6 0c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V13.8C66.9 6.5 72.8.6 80.1.6c7.3 0 13.2 5.9 13.2 13.2v33.1z"
                          fill="#E15C54"
                        />
                        <path
                          d="M80.1 99.8c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V99.8h13.2zm0-6.6c-7.3 0-13.2-5.9-13.2-13.2 0-7.3 5.9-13.2 13.2-13.2h33.1c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H80.1z"
                          fill="#E15C54"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-sm font-medium text-gray-900 mb-1">
                    Slack Updates
                  </h4>
                  <p className="text-sm text-gray-600 mb-3">
                    Keep your team informed with automated Slack notifications
                  </p>
                  <div className="bg-gray-50 rounded-lg p-3">
                    <div className="text-xs text-gray-600 space-y-1">
                      <div className="flex items-center">
                        <div className="h-1 w-1 rounded-full bg-[#E15C54] mr-2"></div>
                        Meeting notes posted to relevant channels
                      </div>
                      <div className="flex items-center">
                        <div className="h-1 w-1 rounded-full bg-[#E15C54] mr-2"></div>
                        Action items shared with the team
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* CTA */}
            <div className="text-center mt-8">
              <button
                onClick={handleCalendlyClick}
                className="inline-flex items-center px-5 py-2.5 text-sm font-medium rounded-md shadow-sm text-white bg-[#E15C54] hover:bg-[#D14C44] transition-colors duration-200"
              >
                Schedule Your Team Plan Demo
                <ChevronRightIcon className="ml-2 -mr-1 h-5 w-5" />
              </button>
              <p className="mt-3 text-xs text-gray-500">
                Part of the Team Plan
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionIntegrationsTab;
