import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import "../../../index.css";
import CollectionTypeStep from "./CollectionTypeStep";
import AutomationStep from "./AutomationStep";
import SharingStep from "./SharingStep";
import amplitude from "amplitude-js";

const CreateCollectionModal = ({ closeModal, user }) => {
  const [step, setStep] = useState(0);
  const [collectionTitle, setCollectionTitle] = useState("");
  const [collectionDescription, setCollectionDescription] = useState("");
  const [collectionType, setCollectionType] = useState("");
  const [automationRules, setAutomationRules] = useState([]);
  const [contributorOption, setContributorOption] = useState("organization");
  const [selectedContributors, setSelectedContributors] = useState([]);
  const [creatingCollection, setCreatingCollection] = useState(false);
  const [privacyAcknowledged, setPrivacyAcknowledged] = useState(false);

  const navigate = useNavigate();

  const handleNext = () => {
    if (step < 2) {
      setStep(step + 1);
    } else {
      // Check if privacy warning is needed and acknowledged
      const userDomain = user.email.split("@")[1];
      const hasInternalRules = automationRules.some(
        (rule) =>
          (rule.ruleType === "email" &&
            rule.value
              .toLowerCase()
              .endsWith(`@${userDomain.toLowerCase()}`)) ||
          (rule.ruleType === "domain" &&
            rule.value.toLowerCase().includes(userDomain.toLowerCase()))
      );
      const needsAcknowledgment =
        hasInternalRules && contributorOption === "organization";

      if (needsAcknowledgment && !privacyAcknowledged) {
        // Show error or prevent submission
        alert(
          "Please acknowledge the privacy warning before creating the collection"
        );
        return;
      }
      handleCreateCollection();
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleCreateCollection = async () => {
    setCreatingCollection(true);
    try {
      const response = await fetch("https://backend.scribbl.co/collections", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          title: collectionTitle,
          description: collectionDescription,
          type: collectionType,
          automationRules,
          sharedWithAll: contributorOption === "organization",
          sharedWith:
            contributorOption === "organization" ? [] : getSharedWithValue(),
        }),
      });

      if (response.ok) {
        const result = await response.json();
        amplitude.getInstance().logEvent("Created Collection", {
          type: collectionType,
          automationRulesCount: automationRules.length,
        });

        // Log the collection type selection event
        amplitude
          .getInstance()
          .logEvent(
            `Collection Creation Modal: Selected collection type ${collectionType}`
          );

        // Log the share setting selection event
        amplitude
          .getInstance()
          .logEvent(
            `Collection Creation Modal: Selected share setting ${contributorOption}`
          );

        // Log events for each automation rule added
        automationRules.forEach((rule) => {
          amplitude
            .getInstance()
            .logEvent(`Collection Creation Modal: Added ${rule.ruleType} rule`);
        });

        navigate(`/collection/${result.uuid}`);
      } else {
        console.error("Failed to create collection");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setCreatingCollection(false);
    }
  };

  const getSharedWithValue = () => {
    if (collectionType === "1on1") {
      return [user.email];
    } else if (contributorOption === "organization") {
      return "all"; // Use "all" to indicate sharing with the entire organization
    } else if (contributorOption === "just-me") {
      return [user.email];
    } else {
      return selectedContributors.map((u) => u.email);
    }
  };

  const renderStepContent = () => {
    switch (step) {
      case 0:
        return (
          <CollectionTypeStep
            collectionTitle={collectionTitle}
            setCollectionTitle={setCollectionTitle}
            collectionType={collectionType}
            setCollectionType={setCollectionType}
          />
        );
      case 1:
        return (
          <AutomationStep
            automationRules={automationRules}
            setAutomationRules={setAutomationRules}
          />
        );
      case 2:
        return (
          <SharingStep
            collectionType={collectionType}
            contributorOption={contributorOption}
            setContributorOption={setContributorOption}
            selectedContributors={selectedContributors}
            setSelectedContributors={setSelectedContributors}
            automationRules={automationRules}
            user={user}
            onPrivacyAcknowledged={setPrivacyAcknowledged}
          />
        );

      default:
        return null;
    }
  };

  const isNextDisabled = () => {
    switch (step) {
      case 0:
        return !(collectionTitle && collectionType);
      case 1:
        return false;
      case 2:
        const userDomain = user.email.split("@")[1];
        const hasInternalRules = automationRules.some(
          (rule) =>
            (rule.ruleType === "email" &&
              rule.value
                .toLowerCase()
                .endsWith(`@${userDomain.toLowerCase()}`)) ||
            (rule.ruleType === "domain" &&
              rule.value.toLowerCase().includes(userDomain.toLowerCase()))
        );
        const needsAcknowledgment =
          hasInternalRules && contributorOption === "organization";
        return needsAcknowledgment && !privacyAcknowledged;
      default:
        return false;
    }
  };

  return (
    <Transition.Root show={true} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={closeModal}
      >
        {/* Modal content */}
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* Overlay */}
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* Centering trick */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          {/* Modal panel */}
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              {/* Close button */}
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
                  onClick={closeModal}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Step content */}
              {renderStepContent()}

              {/* Next and Back buttons */}
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                {step < 2 ? (
                  <button
                    type="button"
                    className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 ${
                      isNextDisabled()
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-brand-turq hover:bg-brand-green-lighter1"
                    } text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq sm:ml-3 sm:w-auto sm:text-sm`}
                    onClick={handleNext}
                    disabled={isNextDisabled()}
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 ${
                      creatingCollection || isNextDisabled()
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-brand-turq hover:bg-brand-green-lighter1"
                    } text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq sm:ml-3 sm:w-auto sm:text-sm`}
                    onClick={handleNext}
                    disabled={creatingCollection || isNextDisabled()}
                  >
                    {creatingCollection ? "Creating..." : "Create Collection"}
                  </button>
                )}
                {step > 0 && (
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateCollectionModal;
