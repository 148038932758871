import React from "react";

const Avatar = ({ name, email }) => {
  const initials = name
    ? name
        .split(" ")
        .map((n) => n[0])
        .join("")
    : email[0].toUpperCase();

  const bgColors = [
    "bg-[#5A67D8]", // Indigo
    "bg-[#38B2AC]", // Teal/Green
    "bg-[#F56565]", // Rose/Red
    "bg-[#F6E05E]", // Amber/Yellow
  ];
  const bgColor = bgColors[email.charCodeAt(0) % bgColors.length];

  return (
    <div
      className={`h-8 w-8 rounded-full flex items-center justify-center ${bgColor} text-white`}
    >
      <span className="text-sm font-medium">{initials}</span>
    </div>
  );
};

export default Avatar;
