// GoogleDriveFolderPicker.js
import React, { useEffect, useState } from "react";

const GoogleDriveFolderPicker = ({ onFolderSelect, disabled }) => {
  const [pickerApiLoaded, setPickerApiLoaded] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    // Load the Google Picker API
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    script.onload = () => loadPickerApi();
    document.body.appendChild(script);

    fetchAccessToken();

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const loadPickerApi = () => {
    window.gapi.load("picker", { callback: () => setPickerApiLoaded(true) });
  };

  const fetchAccessToken = async () => {
    try {
      const response = await fetch(
        "https://backend.scribbl.co/google-drive/access-token",
        {
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setAccessToken(data.accessToken);
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  const openPicker = () => {
    if (!pickerApiLoaded) return;

    const picker = new window.google.picker.PickerBuilder()
      .addView(
        new window.google.picker.DocsView()
          .setIncludeFolders(true)
          .setSelectFolderEnabled(true)
          .setMimeTypes("application/vnd.google-apps.folder") // Filter for folders only
      )
      .setOAuthToken(accessToken)
      .setCallback(pickerCallback)
      .build();
    picker.setVisible(true);
  };

  const pickerCallback = (data) => {
    if (data.action === window.google.picker.Action.PICKED) {
      const folderId = data.docs[0].id;
      const folderName = data.docs[0].name;
      onFolderSelect(folderId, folderName);
    }
  };

  return (
    <>
      <label className="block text-sm font-medium text-gray-700 mb-2">
        Select Google Drive Folder
      </label>
      <button
        onClick={openPicker}
        disabled={disabled || !pickerApiLoaded}
        className="inline-flex px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq shadow-sm transition-colors duration-150 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Choose from Drive
      </button>
    </>
  );
};

export default GoogleDriveFolderPicker;
