import React, { useEffect, useState } from "react";

// New component: CollectionSettingsViewOnly
const CollectionSettingsViewOnly = ({ collection }) => {
  const [automationRules, setAutomationRules] = useState([]);

  useEffect(() => {
    fetchAutomationRules();
  }, [collection]);

  const fetchAutomationRules = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collection.uuid}/automation-rules`,
        {
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to fetch automation rules");
      const data = await response.json();
      setAutomationRules(sortAlphabetically(data, "value"));
    } catch (err) {
      console.error("Error fetching automation rules:", err);
    }
  };

  const sortAlphabetically = (arr, key = null) => {
    return arr.sort((a, b) => {
      if (key === "name") {
        const valueA = a[key] ? a[key].toLowerCase() : a.email.toLowerCase();
        const valueB = b[key] ? b[key].toLowerCase() : b.email.toLowerCase();
        return valueA.localeCompare(valueB);
      }
      const valueA = key ? a[key].toLowerCase() : a.toLowerCase();
      const valueB = key ? b[key].toLowerCase() : b.toLowerCase();
      return valueA.localeCompare(valueB);
    });
  };

  console.log("automation rules", automationRules);

  return (
    <div className="space-y-8">
      <div className="bg-blue-50 border-l-4 border-blue-400 p-4 rounded-md">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-blue-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm text-blue-700">
              This is a view-only version of the collection settings. Only the
              collection owner can modify these settings.
            </p>
          </div>
        </div>
      </div>
      {/* Collection Title */}
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Collection Title
          </h3>
          <p className="mt-2 text-sm text-gray-700">{collection.title}</p>
        </div>
      </div>

      {/* Automation Rules */}
      {automationRules.length !== 0 && (
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Automation Rules
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              These rules automatically add meetings to this collection when the
              fullowing rules are matched. If any of these rules match a meeting
              that contributors or the owner of the collection join, the meeting
              will be automatically added to the collection.
            </p>

            {/* Recurring Calendar Events Section */}
            {automationRules.filter(
              (rule) => rule.ruleType === "recurring_meeting"
            ).length !== 0 && (
              <div className="mt-6">
                <h4 className="text-md font-medium text-gray-700 mb-2">
                  Recurring Calendar Events
                </h4>
                <ul className="list-disc pl-5 space-y-2">
                  {automationRules
                    .filter((rule) => rule.ruleType === "recurring_meeting")
                    .map((rule) => (
                      <li key={rule.ID}>{rule.name || rule.value}</li>
                    ))}
                </ul>
              </div>
            )}

            {/* Participant Email Addresses Section */}
            {automationRules.filter((rule) => rule.ruleType === "email")
              .length !== 0 && (
              <div className="mt-6">
                <h4 className="text-md font-medium text-gray-700 mb-2">
                  Participant Email Addresses
                </h4>
                <ul className="list-disc pl-5 space-y-2">
                  {automationRules
                    .filter((rule) => rule.ruleType === "email")
                    .map((rule) => (
                      <li key={rule.ID}>{rule.value}</li>
                    ))}
                </ul>
              </div>
            )}

            {/* Email Domains Section */}
            {automationRules.filter((rule) => rule.ruleType === "domain")
              .length !== 0 && (
              <div className="mt-6">
                <h4 className="text-md font-medium text-gray-700 mb-2">
                  Email Domains
                </h4>
                <ul className="list-disc pl-5 space-y-2">
                  {automationRules
                    .filter((rule) => rule.ruleType === "domain")
                    .map((rule) => (
                      <li key={rule.ID}>{rule.value}</li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CollectionSettingsViewOnly;
