import React, { useState, useEffect } from "react";
import amplitude from "amplitude-js";

const OnboardingSharingPreference = ({ nextStep, email, userObj }) => {
  const [settingShareWithAttendees, setSettingShareWithAttendees] =
    useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    amplitude.getInstance().logEvent("er onboarding sharing preference step");
  }, []);

  const saveSharingPreference = async () => {
    setIsLoading(true);
    try {
      const response = await fetch("https://backend.scribbl.co/user/setting", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          settingName: "settingShareWithAttendees",
          settingValue: settingShareWithAttendees,
        }),
        credentials: "include",
      });

      if (response.ok) {
        amplitude
          .getInstance()
          .logEvent("er onboarding sharing preference set", {
            settingShareWithAttendees,
          });
        nextStep("profile");
      } else {
        console.error("Failed to save sharing preference");
      }
    } catch (error) {
      console.error("Error saving sharing preference:", error);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="grow pt-12">
        <div className="w-[27rem] mx-auto">
          <h2 className="mt-8 text-start font-thin text-4xl text-gray-600 mb-8">
            Let's customize your Scribbl experience.
          </h2>
          <div className="mt-8">
            <label
              htmlFor="sharingPreference"
              className="block text-sm font-medium leading-6 text-gray-900 mb-2"
            >
              Would you like to automatically share meeting notes with all
              attendees?
            </label>
            <select
              id="sharingPreference"
              name="sharingPreference"
              value={settingShareWithAttendees ? "yes" : "no"}
              onChange={(e) =>
                setSettingShareWithAttendees(e.target.value === "yes")
              }
              className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
            >
              <option value="yes">Yes, share automatically</option>
              <option value="no">No, I'll share manually</option>
            </select>
          </div>
        </div>
      </div>
      <div className="w-[27rem] mx-auto mb-8">
        <div className="mt-6 text-right">
          <button
            onClick={saveSharingPreference}
            disabled={isLoading}
            type="button"
            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker2 focus:outline-none"
          >
            {isLoading ? "Saving..." : "Continue"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingSharingPreference;
