export function sendSlackMessage(slackChannel, slackMessage) {
  var xhr = new XMLHttpRequest();
  xhr.open("POST", "https://backend.scribbl.co/slack", true);
  xhr.onload = function () {
    // do something to response
  };
  xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

  // xhr.send(
  //   JSON.stringify({
  //     message_text: slackMessage,
  //     channel_name: slackChannel,
  //   })
  // );
}
