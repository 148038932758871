import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  CheckIcon,
  PlusCircleIcon,
} from "@heroicons/react/solid";
import amplitude from "amplitude-js";

const AddMeetingToCollectionModal = ({ isOpen, onClose, meetingId }) => {
  const [collections, setCollections] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState(new Set());
  const [addSuccess, setAddSuccess] = useState(false);

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await fetch(`https://backend.scribbl.co/collections`, {
          method: "GET",
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort((a, b) => {
            const aContains = a.enhancedRecordings.some(
              (recording) => recording.ID === meetingId
            );
            const bContains = b.enhancedRecordings.some(
              (recording) => recording.ID === meetingId
            );

            // First, sort by whether the collection contains the meeting
            if (aContains && !bContains) return -1;
            if (!aContains && bContains) return 1;

            // If both contain or both don't contain, sort by last modified date
            return (
              new Date(b.UpdatedAt || b.CreatedAt) -
              new Date(a.UpdatedAt || a.CreatedAt)
            );
          });
          setCollections(sortedData);
        } else {
          console.error("Failed to fetch collections");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (isOpen) {
      fetchCollections();
      setSelectedCollections(new Set());
      setAddSuccess(false);
    }
  }, [isOpen, meetingId]);

  const toggleCollectionSelection = (collectionId) => {
    setSelectedCollections((prev) => {
      const updated = new Set(prev);
      if (updated.has(collectionId)) {
        updated.delete(collectionId);
      } else {
        updated.add(collectionId);
      }
      return updated;
    });
  };

  const addMeetingToCollections = async () => {
    try {
      const collectionIds = Array.from(selectedCollections);
      const response = await fetch(
        `https://backend.scribbl.co/collections/addMeeting/${meetingId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(collectionIds),
          credentials: "include",
        }
      );
      if (!response.ok) {
        throw new Error(
          `Failed to add meeting to collections: ${await response.text()}`
        );
      }
      setAddSuccess(true);
      amplitude.getInstance().logEvent("Meeting Added to Collection");
      setTimeout(() => {
        onClose();
        setAddSuccess(false);
      }, 600);
    } catch (error) {
      console.error("Error adding meeting to collections:", error);
    }
  };

  const isMeetingAlreadyAdded = (collection) => {
    return collection.enhancedRecordings.some(
      (recording) => recording.ID === meetingId
    );
  };

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-semibold leading-6 text-gray-900 mb-4"
                >
                  Add Meeting to Collection
                </Dialog.Title>

                {collections.length === 0 ? (
                  <div className="text-center py-8">
                    <PlusCircleIcon className="mx-auto h-12 w-12 text-gray-400" />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No Collections Available
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Create your first collection to organize your meetings and
                      insights.
                    </p>
                    <button
                      onClick={() => window.open("/collections", "_blank")}
                      className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker2 transition-colors"
                    >
                      Create Your First Collection
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="mt-2 mb-4">
                      <p className="text-sm text-gray-500">
                        Collections already containing this meeting are shown at
                        the top. Select from the available collections below to
                        add this meeting.
                      </p>
                    </div>

                    <div className="max-h-60 overflow-y-auto">
                      {collections.map((collection) => {
                        const isAdded = isMeetingAlreadyAdded(collection);
                        return (
                          <div
                            key={collection.ID}
                            onClick={() =>
                              !isAdded &&
                              toggleCollectionSelection(collection.ID)
                            }
                            className={`flex items-center p-3 border-b border-gray-200 ${
                              isAdded
                                ? "bg-gray-100"
                                : "cursor-pointer hover:bg-gray-50"
                            } ${
                              selectedCollections.has(collection.ID)
                                ? "bg-brand-green bg-opacity-10"
                                : ""
                            }`}
                          >
                            {!isAdded && (
                              <input
                                type="checkbox"
                                checked={selectedCollections.has(collection.ID)}
                                className="h-4 w-4 text-brand-green border-gray-300 rounded focus:ring-brand-green"
                              />
                            )}
                            <div
                              className={`ml-3 flex-grow ${
                                isAdded ? "opacity-70" : ""
                              }`}
                            >
                              <p className="text-sm font-medium text-gray-900">
                                {collection.title}
                              </p>
                              <p className="text-xs text-gray-500">
                                {collection.enhancedRecordings.length}{" "}
                                {collection.enhancedRecordings.length === 1
                                  ? "meeting"
                                  : "meetings"}
                              </p>
                            </div>
                            {isAdded ? (
                              <span className="text-xs font-medium text-brand-green">
                                Already added
                              </span>
                            ) : (
                              <CalendarIcon className="h-5 w-5 text-gray-400 ml-2" />
                            )}
                          </div>
                        );
                      })}
                    </div>

                    <div className="mt-6 flex justify-end">
                      <button
                        type="button"
                        className={`inline-flex justify-center rounded-md px-4 py-2 text-sm font-medium text-white focus:outline-none transition-colors ${
                          addSuccess
                            ? "bg-brand-green-darker2"
                            : selectedCollections.size > 0
                            ? "bg-brand-green hover:bg-brand-green-darker2"
                            : "bg-gray-300 cursor-not-allowed"
                        }`}
                        onClick={addMeetingToCollections}
                        disabled={addSuccess || selectedCollections.size === 0}
                      >
                        {addSuccess ? (
                          <>
                            <CheckIcon
                              className="h-5 w-5 mr-2"
                              aria-hidden="true"
                            />
                            Added Successfully
                          </>
                        ) : (
                          `Add to ${selectedCollections.size} ${
                            selectedCollections.size === 1
                              ? "Collection"
                              : "Collections"
                          }`
                        )}
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddMeetingToCollectionModal;
