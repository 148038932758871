import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XIcon,
  PlusIcon,
  CheckCircleIcon,
  CogIcon,
  TrashIcon,
  MinusCircleIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import Avatar from "./Avatar";
import amplitude from "amplitude-js";

const ManageTeamModal = ({ isOpen, setIsOpen, user, setUser }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [newMemberEmail, setNewMemberEmail] = useState("");
  const [error, setError] = useState(null);
  const [orgsToJoin, setOrgsToJoin] = useState([]);
  const [newOrgName, setNewOrgName] = useState("");
  const [showAllTeams, setShowAllTeams] = useState(false);
  const [removalScreen, setRemovalScreen] = useState(null);

  useEffect(() => {
    if (isOpen && user) {
      if (user.orgID) {
        fetchAllMembers();
      } else {
        fetchAvailableOrgs();
      }
    }
  }, [isOpen, user]);

  const fetchAllMembers = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/orgs/members?include_pending=true`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setTeamMembers(data.filter((member) => member.orgID === user.orgID));
        setInvitedMembers(data.filter((member) => member.orgID === null));
      }
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  const fetchAvailableOrgs = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/orgs/available?email=${user.email}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setOrgsToJoin(data.orgs || []);
      }
    } catch (error) {
      console.error("Error fetching available orgs:", error);
    }
  };

  const handleInvite = async (e) => {
    e.preventDefault();
    if (!newMemberEmail) {
      setError("Email is required");
      return;
    }

    try {
      const response = await fetch(`https://backend.scribbl.co/orgs/invite-user`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          orgID: user.orgID,
          userEmail: newMemberEmail,
        }),
      });

      if (response.ok) {
        setNewMemberEmail("");
        fetchAllMembers();
        setError(null);

        amplitude
          .getInstance()
          .logEvent("user invited to team from manage team modal");
      } else {
        const errorData = await response.text();
        setError(errorData);
      }
    } catch (error) {
      console.error("Error inviting user:", error);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  const handleJoinOrg = async (orgID) => {
    try {
      const response = await fetch(`https://backend.scribbl.co/orgs/join`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          email: user.email,
          orgID: orgID,
        }),
      });

      if (response.ok) {
        const updatedUser = await response.json();

        setUser(updatedUser);

        amplitude
          .getInstance()
          .logEvent("user joined team from manage team modal");
      } else {
        const errorData = await response.json();
        setError(errorData.message);
      }
    } catch (error) {
      console.error("Error joining org:", error);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  const handleCreateOrg = async (e) => {
    e.preventDefault();
    if (!newOrgName) {
      setError("Organization name is required");
      return;
    }

    try {
      const response = await fetch(`https://backend.scribbl.co/orgs`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          email: user.email,
          orgName: newOrgName,
          isPrivate: false,
          orgDomain: user.email.split("@")[1],
        }),
      });

      if (response.ok) {
        const newOrg = await response.json();
        setUser({ ...user, org: newOrg, orgID: newOrg.ID });
        setNewOrgName("");
        setError(null);

        amplitude
          .getInstance()
          .logEvent("user created team from manage team modal");
      } else {
        const errorData = await response.json();
        setError(errorData.message);
      }
    } catch (error) {
      console.error("Error creating org:", error);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  const handleRemoveMember = async (member) => {
    try {
      const response = await fetch(`https://backend.scribbl.co/orgs/remove-member`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          userIDToRemove: member.ID,
        }),
      });

      if (response.ok) {
        fetchAllMembers();
        setRemovalScreen(null);
        amplitude.getInstance().logEvent("team member removed", {
          removedUserEmail: member.email,
        });
      } else {
        const errorData = await response.text();
        setError(errorData);
      }
    } catch (error) {
      console.error("Error removing team member:", error);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  const ClaimAdminBanner = () => {
    const handleCalendlyClick = (e) => {
      e.preventDefault();
      const calendlyURL = `https://calendly.com/mike-sallese/meeting-with-mike?name=${
        user.name || ""
      }&email=${user.email || ""}&a1=team%20admin%20demo%20inquiry`;
      window.open(calendlyURL, "_blank");
    };

    return (
      <div className="bg-gradient-to-r from-brand-turq to-brand-green-lighter1 rounded-lg p-4 mb-6 shadow-sm">
        <div className="flex items-center justify-between">
          <div className="flex-1">
            <h4 className="text-sm font-semibold text-white">
              Your Team Needs an Admin! 🚀
            </h4>
            <p className="text-xs text-white opacity-80 mt-1">
              Learn how team admins streamline collaboration
            </p>
          </div>
          <button
            onClick={handleCalendlyClick}
            className="flex-shrink-0 text-xs font-medium py-1.5 px-3 rounded-md transition-colors duration-200 bg-white text-brand-turq hover:bg-gray-100"
          >
            Schedule Demo
          </button>
        </div>
        <div className="mt-3 flex flex-wrap gap-3">
          {[
            "Integrations",
            "Smart sharing rules & admin views",
            "Powerful automation for collections",
            "Manage team roster & permissions",
          ].map((feature, index) => (
            <span
              key={index}
              className="inline-flex items-center text-xs text-white"
            >
              <CheckCircleIcon className="h-3.5 w-3.5 mr-1" />
              {feature}
            </span>
          ))}
        </div>
      </div>
    );
  };

  const hasTeamAdmin = teamMembers.some((member) => member.role === 2);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setIsOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-auto sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green"
                  onClick={() => setIsOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              {removalScreen ? (
                // Removal Screen
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium text-gray-900 mb-6"
                    >
                      Remove Team Member
                    </Dialog.Title>

                    <div className="bg-white rounded-lg">
                      <div className="px-4 py-5 sm:p-6">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-12 sm:w-12">
                            <ExclamationIcon
                              className="h-6 w-6 text-red-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                              Remove {removalScreen.name}
                            </h3>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Are you sure you want to remove this team
                                member? This action cannot be undone.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="mt-6 bg-gray-50 -mx-6 px-6 py-4">
                          <div className="flex items-center p-4 bg-white rounded-lg border border-gray-200">
                            <div className="flex-shrink-0">
                              {removalScreen.picture ? (
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={
                                    removalScreen.picture ||
                                    "/default-avatar.png"
                                  }
                                  alt=""
                                />
                              ) : (
                                <Avatar
                                  email={removalScreen.email}
                                  name={removalScreen.name}
                                />
                              )}
                            </div>
                            <div className="ml-4 flex-1">
                              <div className="text-sm font-medium text-gray-900">
                                {removalScreen.name}
                              </div>
                              <div className="text-sm text-gray-500">
                                {removalScreen.email}
                              </div>
                            </div>
                          </div>

                          <div className="mt-4 space-y-3">
                            <h4 className="text-sm font-medium text-gray-900">
                              What happens when you remove a team member:
                            </h4>
                            <ul className="space-y-2">
                              {[
                                "Immediate loss of access to all team resources",
                                "Removal from all team collections and shared content",
                                "Un-assignment of any assigned subscriptions",
                                "Revocation of all team permissions",
                              ].map((consequence, index) => (
                                <li key={index} className="flex items-start">
                                  <span className="flex-shrink-0 h-5 w-5 text-red-500">
                                    <MinusCircleIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                  <span className="ml-2 text-sm text-gray-500">
                                    {consequence}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>

                        <div className="mt-6 flex flex-col sm:flex-row-reverse sm:space-x-reverse space-y-3 sm:space-y-0 sm:space-x-3">
                          <button
                            type="button"
                            onClick={() => handleRemoveMember(removalScreen)}
                            className="w-full sm:w-auto inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          >
                            Remove Team Member
                          </button>
                          <button
                            type="button"
                            onClick={() => setRemovalScreen(null)}
                            className="w-full sm:w-auto inline-flex justify-center items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {user && user.orgID
                        ? `Manage ${user.org.name}`
                        : "Join or Create a Team"}
                    </Dialog.Title>

                    <div className="mt-4">
                      {user && user.orgID ? (
                        <>
                          <form onSubmit={handleInvite} className="space-y-4">
                            {user &&
                              (!user.subscription ||
                                (user.subscription.accountType !== 3 &&
                                  user.subscription.accountType !== 4)) &&
                              !hasTeamAdmin && <ClaimAdminBanner />}
                            <div>
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Invite team member
                              </label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                  type="email"
                                  value={newMemberEmail}
                                  onChange={(e) =>
                                    setNewMemberEmail(e.target.value)
                                  }
                                  className="focus:ring-brand-green focus:border-brand-green flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                                  placeholder="Email address"
                                />
                                <button
                                  type="submit"
                                  className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-brand-green text-white text-sm"
                                >
                                  <PlusIcon className="h-5 w-5 mr-1" /> Invite
                                </button>
                              </div>
                              {error && (
                                <p className="mt-1 text-sm text-red-600">
                                  {error}
                                </p>
                              )}
                            </div>
                          </form>
                          <div className="mt-4 max-h-64 overflow-y-auto custom-scrollbar">
                            <h4 className="text-sm font-medium text-gray-900 sticky top-0 bg-white py-2">
                              Team Members
                            </h4>
                            <ul className="divide-y divide-gray-200 mr-2">
                              {teamMembers.map((member) => (
                                <li
                                  key={member.ID}
                                  className="py-2 flex items-center"
                                >
                                  <div className="flex items-center flex-1">
                                    {member.picture ? (
                                      <img
                                        className="h-8 w-8 rounded-full"
                                        src={
                                          member.picture ||
                                          "/default-avatar.png"
                                        }
                                        alt=""
                                      />
                                    ) : (
                                      <Avatar
                                        email={member.email}
                                        name={member.name}
                                      />
                                    )}
                                    <div className="ml-3">
                                      <div className="flex items-center gap-2">
                                        <p className="text-sm font-medium text-gray-900">
                                          {member.name}
                                        </p>
                                        {member.role === 2 && (
                                          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-brand-green bg-opacity-10 text-brand-green">
                                            Team Admin
                                          </span>
                                        )}
                                      </div>
                                      <p className="text-sm text-gray-500">
                                        {member.email}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex items-center space-x-3">
                                    {user.role === 2 &&
                                      user.subscription &&
                                      (user.subscription.accountType === 3 ||
                                        user.subscription.accountType === 4) &&
                                      member.ID !== user.ID && (
                                        <button
                                          onClick={() =>
                                            setRemovalScreen(member)
                                          }
                                          className="inline-flex items-center px-2 py-1 text-sm text-red-600 hover:text-red-800 focus:outline-none group"
                                        >
                                          <TrashIcon
                                            className="mr-1.5 h-4 w-4 group-hover:text-red-800"
                                            aria-hidden="true"
                                          />
                                          Remove
                                        </button>
                                      )}
                                    <CheckCircleIcon className="h-5 w-5 text-green-500" />
                                  </div>
                                </li>
                              ))}
                              {invitedMembers.map((member) => (
                                <li
                                  key={member.ID}
                                  className="py-2 flex justify-between items-center"
                                >
                                  <div className="flex items-center">
                                    {member.picture ? (
                                      <img
                                        className="h-8 w-8 rounded-full"
                                        src={
                                          member.picture ||
                                          "/default-avatar.png"
                                        }
                                        alt=""
                                      />
                                    ) : (
                                      <Avatar
                                        email={member.email}
                                        name={member.name}
                                      />
                                    )}
                                    <div className="ml-3">
                                      <p className="text-sm font-medium text-gray-900">
                                        {member.name}
                                      </p>
                                      <p className="text-sm text-gray-500">
                                        {member.email}
                                      </p>
                                    </div>
                                  </div>
                                  <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                    Team Invite Pending
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </>
                      ) : (
                        <>
                          {user && !user.orgID && (
                            <div className="min-h-[400px] text-center max-w-md mx-auto">
                              <h3 className="text-xl font-semibold text-gray-900 mb-3">
                                Scribbl is better with your team!
                              </h3>

                              <div className="space-y-3">
                                {orgsToJoin.length > 0 && (
                                  <div className="bg-gray-50 p-4 rounded-lg">
                                    <h4 className="text-lg font-medium text-gray-900 mb-3">
                                      Available Teams to Join
                                    </h4>
                                    <div className="space-y-2">
                                      {orgsToJoin
                                        .slice(
                                          0,
                                          showAllTeams ? orgsToJoin.length : 3
                                        )
                                        .map((org) => (
                                          <div
                                            key={org.ID}
                                            className="flex justify-between items-center bg-white p-3 rounded-md shadow-sm"
                                          >
                                            <div>
                                              <p className="text-sm font-medium text-gray-900">
                                                {org.name}
                                              </p>
                                              <p className="text-xs text-gray-500">
                                                {org.memberCount} members
                                              </p>
                                            </div>
                                            <button
                                              onClick={() =>
                                                handleJoinOrg(org.ID)
                                              }
                                              className="px-4 py-2 bg-brand-green text-white text-sm rounded-md hover:bg-brand-green-darker2 transition-colors duration-200"
                                            >
                                              Join
                                            </button>
                                          </div>
                                        ))}
                                    </div>
                                    {!showAllTeams && orgsToJoin.length > 3 && (
                                      <button
                                        onClick={() => setShowAllTeams(true)}
                                        className="text-sm text-brand-green hover:text-brand-green-darker2 mt-3 focus:outline-none"
                                      >
                                        Show {orgsToJoin.length - 3} more teams
                                      </button>
                                    )}
                                    {showAllTeams && orgsToJoin.length > 3 && (
                                      <button
                                        onClick={() => setShowAllTeams(false)}
                                        className="text-sm text-brand-green hover:text-brand-green-darker2 mt-3 focus:outline-none"
                                      >
                                        Show less
                                      </button>
                                    )}
                                  </div>
                                )}

                                {orgsToJoin.length === 0 && (
                                  <div className="bg-blue-50 rounded-lg p-3 mb-4 text-sm">
                                    <h4 className="font-medium text-blue-800 mb-1">
                                      Already working with a team?
                                    </h4>
                                    <p className="text-blue-700">
                                      Ask a colleague to invite you to their
                                      Scribbl workspace via team settings.
                                    </p>
                                  </div>
                                )}

                                <div className="bg-gray-50 p-4 rounded-lg">
                                  <h4 className="text-lg font-medium text-gray-900 mb-3">
                                    Create a New Team
                                  </h4>
                                  <form
                                    onSubmit={handleCreateOrg}
                                    className="space-y-3"
                                  >
                                    <div>
                                      <label
                                        htmlFor="orgName"
                                        className="sr-only"
                                      >
                                        Team name
                                      </label>
                                      <input
                                        type="text"
                                        id="orgName"
                                        value={newOrgName}
                                        onChange={(e) =>
                                          setNewOrgName(e.target.value)
                                        }
                                        className="focus:ring-brand-green focus:border-brand-green block w-full rounded-md text-sm border-gray-300"
                                        placeholder="Enter your team name"
                                      />
                                    </div>
                                    <button
                                      type="submit"
                                      className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green transition-colors duration-200"
                                    >
                                      <PlusIcon className="h-5 w-5 mr-2" />
                                      Create Your Team
                                    </button>
                                  </form>
                                </div>
                              </div>

                              {error && (
                                <p className="mt-4 text-sm text-red-600">
                                  {error}
                                </p>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {user && user.orgID && (
                <div className="mt-6 pt-4 border-t border-gray-200">
                  <a
                    href={"/settings/team"}
                    className="block w-full text-left px-3 py-2 rounded-md bg-gray-50 hover:bg-gray-100 transition-colors duration-200 flex items-center justify-between group"
                  >
                    <div className="flex items-center">
                      <CogIcon className="h-5 w-5 text-gray-400 group-hover:text-brand-green mr-3" />
                      <div>
                        <p className="text-sm font-medium text-gray-900">
                          Team Settings
                        </p>
                        <p className="text-xs text-gray-500">
                          Manage subscriptions and billing
                        </p>
                      </div>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-gray-400 group-hover:text-brand-green"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ManageTeamModal;
