import React, { useState, useEffect } from "react";
import amplitude from "amplitude-js";
import SignIn from "./SignIn";
import { useNavigate, useLocation } from "react-router-dom";
import OnboardingJoinTeam from "./OnboardingJoinTeam";
import OnboardingCreateTeam from "./OnboardingCreateTeam";
import OnboardingInviteTeam from "./OnboardingInviteTeam";
import OnboardingDownloadExtension from "./OnboardingDownloadExtension";
import OnboardingTryInMeet from "./OnboardingTryInMeet";
import OnboardingProfile from "./OnboardingProfile";
import OnboardingSharingPreference from "./OnboardingSharingPreference";

import "../index.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const OnboardingOrchestrator = (props) => {
  const ONBOARDING_METHOD_ORGANIC = 0; // user signed up on their own
  const ONBOARDING_METHOD_INVITE = 1; // user signed up via invite link
  const ONBOARDING_METHOD_CHROME_STORE = 2; // user signed up via chrome store

  const navigate = useNavigate();
  const location = useLocation();

  const [onboardingMethod, setOnboardingMethod] = useState(
    ONBOARDING_METHOD_ORGANIC
  );
  const [inviteID, setInviteID] = useState(null);
  const [onboardingStep, setOnboardingStep] = useState("signIn");

  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);
  const [org, setOrg] = useState(null);
  const [hasTeamsToJoin, setHasTeamsToJoin] = useState(true);
  const [usingEmailProvider, setUsingEmailProvider] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let inviteID = params.get("id");
    if (inviteID) {
      setOnboardingMethod(ONBOARDING_METHOD_INVITE);
      setInviteID(inviteID);
    } else if (params.get("source") === "chrome-store") {
      setOnboardingMethod(ONBOARDING_METHOD_CHROME_STORE);
    }
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    const userInfoResp = await fetch(`https://backend.scribbl.co/auth/userinfo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include", // This line ensures cookies are sent with the request
    });
    if (userInfoResp.status === 200) {
      const userInfoJson = await userInfoResp.json();
      if (userInfoJson && userInfoJson.email) {
        setUser(userInfoJson);
        setUserEmail(userInfoJson.email);
        setLoggedIn(true);

        if (userInfoJson.onboardingStatus === 2) {
          setOnboardingStep("complete");
        } else if (userInfoJson.onboardingStatus === 1) {
          setOnboardingStep("sharingPreference");
        } else {
          setOnboardingStep("tryInMeet");
        }
      }
    } else if (userInfoResp.status === 401) {
      console.log("user not logged in");
    } else {
      console.log("user not logged in");
    }
  };

  function routeTo(onboardingStep) {
    console.log("onboardingStep", onboardingStep);
    if (onboardingStep === "signIn") {
      return (
        <SignIn
          nextStep={setOnboardingStep}
          setEmail={setUserEmail}
          setUserObj={setUser}
          setOrg={setOrg}
          orgObj={org}
          inviteID={inviteID}
          onboardingMethod={onboardingMethod}
        />
      );
    } else if (onboardingStep === "sharingPreference") {
      return (
        <OnboardingSharingPreference
          nextStep={setOnboardingStep}
          email={userEmail}
          userObj={user}
        />
      );
    } else if (onboardingStep === "profile") {
      return (
        <OnboardingProfile
          nextStep={setOnboardingStep}
          email={userEmail}
          userObj={user}
          setUser={setUser}
          orgObj={org}
        />
      );
    } else if (onboardingStep === "joinTeam") {
      return (
        <OnboardingJoinTeam
          nextStep={setOnboardingStep}
          email={userEmail}
          userObj={user}
          setUser={setUser}
          setHasTeamsToJoin={setHasTeamsToJoin}
          hasTeamsToJoin={hasTeamsToJoin}
          setUsingEmailProvider={setUsingEmailProvider}
          usingEmailProvider={usingEmailProvider}
          onboardingMethod={onboardingMethod}
        ></OnboardingJoinTeam>
      );
    } else if (onboardingStep === "createTeam") {
      return (
        <OnboardingCreateTeam
          nextStep={setOnboardingStep}
          email={userEmail}
          userObj={user}
          setOrg={setOrg}
          orgObj={org}
          hasTeamsToJoin={hasTeamsToJoin}
          usingEmailProvider={usingEmailProvider}
        ></OnboardingCreateTeam>
      );
    } else if (onboardingStep === "inviteTeam") {
      return (
        <OnboardingInviteTeam
          nextStep={setOnboardingStep}
          email={userEmail}
          userObj={user}
          orgObj={org}
          onboardingMethod={onboardingMethod}
        ></OnboardingInviteTeam>
      );
    } else if (onboardingStep === "downloadExtension") {
      return (
        <OnboardingDownloadExtension
          nextStep={setOnboardingStep}
          email={userEmail}
        ></OnboardingDownloadExtension>
      );
    } else if (onboardingStep === "tryInMeet") {
      return (
        <OnboardingTryInMeet
          nextStep={setOnboardingStep}
          email={userEmail}
        ></OnboardingTryInMeet>
      );
    } else if (onboardingStep === "complete") {
      const params = new URLSearchParams(location.search);
      const returnPath = params.get("returnPath");
      if (returnPath) {
        navigate(decodeURIComponent(returnPath));
      } else {
        navigate("/");
      }
    }
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 w-screen h-screen">
      {/* White section */}
      <div className="px-12">
        <div className="grid pt-12 h-full">
          <div className="absolute">
            <img
              style={{ height: 55 }}
              src="/Logo_OnWhite.png"
              alt="Scribbl dragonfly logo"
            />
          </div>
          {routeTo(onboardingStep)}
        </div>
      </div>
      {/* Red section */}
      <div className="bg-brand-red hidden lg:block">
        <div className="pt-12 w-full ml-auto mr-auto p-4 max-w-[35rem]">
          <div className="flex flex-col justify-center fixed">
            <span className="text-center font-thin text-4xl text-white">
              Optimize your meetings.
            </span>
            <p className="px-6 pt-5 text-lg text-white text-center w-full max-w-[35rem]">
              Life is short and meetings are long. With Scribbl AI, you can{" "}
              <b>quickly get up to speed</b> on past meetings and feel confident
              that you'll
              <b> never lose valuable conversations again</b>.
            </p>
            <img
              className="self-center w-[21rem]"
              //className="xl:w-2/4 xl:h-2/4 w-4/5 h-4/5 self-center"
              src="/signin-image.png"
              alt="Sign in image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingOrchestrator;
