import React, { useState, useEffect } from "react";
import { LoginIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";

import "../index.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const UnauthedNavbar = (props) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white shadow mb-1 h-14">
      <a
        href="https://www.scribbl.co/"
        target="_blank"
        className="bg-brand-red h-full inline-block py-1 px-7"
      >
        <img
          className="h-12 w-auto bg-brand-red inline"
          src="/Logo_OnDark.png"
          alt="Scribbl logo"
        />
      </a>
      <span>
        <button
          type="button"
          onClick={() => navigate(`/signup`)}
          className="mt-2 mr-7 inline-flex float-right items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green"
        >
          <LoginIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Log In or Sign Up
        </button>
      </span>
    </div>
  );
};

export default UnauthedNavbar;
