import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import amplitude from "amplitude-js";

import "../index.css";

const OnboardingDownloadExtension = (props) => {
  const navigate = useNavigate();

  async function markOnboardingComplete() {
    const joinOrgResponse = await fetch(
      `https://backend.scribbl.co/user/onboarding/complete`,
      {
        method: "POST",
        body: JSON.stringify({
          email: props.email,
        }),
        credentials: "include", // This line ensures cookies are sent with the request
      }
    );

    if (joinOrgResponse.ok) {
      // Instead of navigating directly, call the nextStep function
      props.nextStep("complete");
    }
  }

  useEffect(() => {
    amplitude.getInstance().logEvent("er onboarding download extension step");
    localStorage.setItem("fromOnboarding", true);
  }, []);

  return (
    <div className="flex">
      <div className="grow flex justify-center flex-col items-center">
        <div className="w-[27rem]">
          <span className="text-center font-thin text-4xl text-gray-600">
            Install the Scribbl Chrome Extension
          </span>
          <div className="mt-6"></div>
          <a
            href="https://chrome.google.com/webstore/detail/google-meet-transcripts-b/kmjmlilenakedodldceipdnmmnfkahni?hl=en-US"
            target="_blank"
            onClick={() => {
              amplitude
                .getInstance()
                .logEvent("er onboarding download extension success");
            }}
          >
            <img
              src="/chrome_store_onboarding.png"
              alt="Install Scribbl Chrome Extension"
            />
          </a>
          <div className="mt-6 text-right">
            <button
              onClick={() => {
                amplitude
                  .getInstance()
                  .logEvent("er onboarding download extension skip");
                markOnboardingComplete();
              }}
              type="button"
              className="mr-3 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Skip for now
            </button>
            <button
              onClick={() => {
                amplitude
                  .getInstance()
                  .logEvent("er onboarding download extension success");
                window.open(
                  "https://chrome.google.com/webstore/detail/google-meet-transcripts-b/kmjmlilenakedodldceipdnmmnfkahni?hl=en-US",
                  "_blank"
                );
              }}
              type="button"
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker2 focus:outline-none"
            >
              Get extension
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingDownloadExtension;
