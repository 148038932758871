import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import amplitude from "amplitude-js";

import "../index.css";

const OnboardingTryInMeet = (props) => {
  const navigate = useNavigate();

  async function markOnboardingComplete() {
    const joinOrgResponse = await fetch(
      `https://backend.scribbl.co/user/onboarding/complete`,
      {
        method: "POST",
        body: JSON.stringify({
          email: props.email,
        }),
        credentials: "include", // This line ensures cookies are sent with the request
      }
    );
  }

  useEffect(() => {
    amplitude.getInstance().logEvent("er onboarding try in meet step");
    markOnboardingComplete();
  }, []);

  return (
    <div className="flex">
      <div className="grow flex justify-center flex-col items-center">
        <div className="w-[27rem]">
          <span className="text-center font-thin text-4xl text-gray-600">
            Give Scribbl a Test Run
          </span>
          <span className="block font-thin text-md text-gray-500">
            Click “Try in Google Meet” and say a few words. Just end your
            meeting and Scribbl will open in a new browser tab. It’s really that
            simple.
          </span>
          <div className="mt-6"></div>
          <div className="mt-6 text-right">
            <button
              onClick={() => {
                amplitude
                  .getInstance()
                  .logEvent("er onboarding try in meet skip");
                navigate("/");
              }}
              type="button"
              className="mr-3 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Skip to Scribbl Home
            </button>
            <button
              onClick={() => {
                amplitude
                  .getInstance()
                  .logEvent("er onboarding try in meet success");
                window.open("https://www.meet.new/", "_blank");
              }}
              type="button"
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker2 focus:outline-none"
            >
              Try in Google Meet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingTryInMeet;
