import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Navbar from "./Navbar";
import { ChevronRightIcon } from "@heroicons/react/outline";

import { LinkIcon } from "@heroicons/react/solid";
import VideoControls from "./recording/VideoControls";
import SpeakerBars from "./recording/SpeakerBars";
import Transcript from "./recording/Transcript";
import TranscriptSearch from "./recording/TranscriptSearch";
import NotFound from "./NotFound";
import { sendSlackMessage } from "../slack";
import amplitude from "amplitude-js";

import "react-multi-email/style.css";

import { transcriptSearchChanged } from "../transcript";

const Topic = (isPublic) => {
  let siderRef = useRef();
  let videoPlayerRef = useRef();
  let hoverBoxRef = useRef();

  const [currentVideoTime, setCurrentVideoTime] = useState(0);

  const { recordingUUID, topicUUID } = useParams();

  const [topic, setTopic] = useState(null);

  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);

  const [loading, setLoading] = useState(true);
  const [pageNotFound, setPageNotFound] = useState(false);

  const [videoLoaded, setVideoLoaded] = useState(false);

  const copyLinkTextInitialState = "Share Topic";
  const [copyLinkText, setCopyLinkText] = useState(copyLinkTextInitialState);

  const [searchTerm, setSearchTerm] = useState("");

  const [speakerChunksMap, setSpeakerChunksMap] = useState({});

  const [topics, setTopics] = useState([]);

  const [searchResults, setSearchResults] = useState([]);

  async function copyShareLinkToClipboard() {
    if (!navigator.clipboard) {
      console.log("clipboard copy not supported by browser");
      // Clipboard API not available
      return;
    }
    try {
      await navigator.clipboard.writeText(
        `${window.location.protocol}//${window.location.host}/recording/${recordingUUID}/topic/${topicUUID}`
      );
    } catch (err) {
      console.log("failed to copy link:" + err);
    }
  }

  useEffect(() => {
    //Change copy link text to show that a link was successfully copied.
    if (copyLinkText !== copyLinkTextInitialState) {
      setTimeout(() => setCopyLinkText(copyLinkTextInitialState), [1000]);
    }
  }, [copyLinkText]);

  useEffect(() => {
    var eventProperties = {
      recording_uuid: recordingUUID,
      topic_uuid: topicUUID,
    };
    amplitude.getInstance().logEvent("viewed topic", eventProperties);
  }, []);

  // When we get email, get the recording
  useEffect(() => {
    if (userEmail) {
      sendSlackMessage("beta", `${userEmail} is viewing topic ${topicUUID}`);
    }

    async function getSetRecording() {
      if (userEmail) {
        const topic = await fetchTopic(
          recordingUUID,
          topicUUID,
          userEmail,
          setPageNotFound,
          setLoading
        );
        if (topic) {
          // transcript items can be before the recording started, so remove any negative values
          topic.isTopic = true;
          topic.open = true;
          topic.start = topic.start_time;
          topic.end = topic.end_time;
          setTopic(topic);
        }
      } else {
        // the topic is public
        const topic = await fetchPublicTopic(
          recordingUUID,
          topicUUID,
          setPageNotFound,
          setLoading
        );

        if (topic) {
          // transcript items can be before the recording started, so remove any negative values
          topic.isTopic = true;
          topic.open = true;
          topic.start = topic.start_time;
          topic.end = topic.end_time;
          setTopic(topic);
        }
      }
    }

    getSetRecording();
  }, [userEmail]);

  // When the search term changes
  useEffect(() => {
    if (topic) {
      transcriptSearchChanged(searchTerm, setSearchResults, [topic], setTopics);
    }
  }, [searchTerm]);

  return (
    <Navbar
      setUserEmail={setUserEmail}
      setUser={setUser}
      isPublic={isPublic}
      siderRef={siderRef}
      loading={loading}
    >
      <>
        {topic && (
          <div className="h-full w-full flex">
            <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
              <div className="flex-1 relative z-0 flex overflow-hidden">
                <main className="flex-1 relative z-0 focus:outline-none overflow-y-auto">
                  <div
                    className="bg-white border-b border-gray-200 sm:px-6 w-full px-5 grid grid-cols-3"
                    style={{ height: "55px" }}
                  >
                    <div className="col-span-2 my-auto text-ellipsis overflow-hidden whitespace-nowrap">
                      <a href={`/recording/${topic.enhancedRecording.uuid}`}>
                        <span className="text-xl text-gray-500">
                          {topic.enhancedRecording.meetingTitle}
                        </span>
                      </a>
                      <ChevronRightIcon
                        className="h-4 w-4 text-gray-700 inline mb-2 mx-1"
                        aria-hidden="true"
                      />
                      <span className="text-gray-700 font-medium text-xl text-ellipsis overflow-hidden whitespace-nowrap">
                        #{topic.name}
                      </span>
                    </div>
                    <div className="text-right my-auto">
                      <a
                        href="#"
                        className="text-sm text-gray-500 font-medium hover:text-gray-700"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          copyShareLinkToClipboard();
                          setCopyLinkText("Link Copied!");
                        }}
                      >
                        <LinkIcon
                          className="h-4 w-4 inline mb-1 mr-1"
                          aria-hidden="true"
                        />
                        {copyLinkText}
                      </a>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
                      <div className="h-full  rounded-lg">
                        <div className="flex flex-col justify-center m-auto">
                          <video
                            style={{ maxHeight: "55vh" }}
                            className={!videoLoaded ? "invisible" : "rounded"}
                            controls
                            ref={videoPlayerRef}
                            src={topic.clipURL}
                            onTimeUpdate={() => {
                              setCurrentVideoTime(
                                videoPlayerRef.current.currentTime
                              );
                            }}
                            onLoadedMetadata={(e) => {
                              setVideoLoaded(true);
                              setLoading(false);
                            }}
                          />
                          {/* Video controls */}
                          {videoLoaded && (
                            <div>
                              <div className="mt-4">
                                <SpeakerBars
                                  speakerChunksMap={speakerChunksMap}
                                  setSpeakerChunksMap={setSpeakerChunksMap}
                                  videoPlayerRef={videoPlayerRef}
                                  recording={topic}
                                  hoverBoxRef={hoverBoxRef}
                                  topics={topics}
                                  topicsBar={false}
                                />
                              </div>
                              <div className="mt-4">
                                <VideoControls
                                  videoPlayerRef={videoPlayerRef}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
                <aside className="hidden bg-gray-50 relative md:flex md:flex-col flex-shrink-0 w-5/12 border-l border-gray-200 overflow-y-auto">
                  <div className="absolute inset-0 py-2 px-2">
                    <div className="h-full rounded-lg">
                      {videoLoaded && (
                        <div>
                          <div className="px-2 sm:px-4 lg:px-6">
                            <TranscriptSearch
                              searchResults={searchResults}
                              searchTerm={searchTerm}
                              setSearchTerm={setSearchTerm}
                              topics={[topic]}
                              setTopics={setTopics}
                            />
                          </div>
                          <div className="mt-4">
                            <Transcript
                              userEmail={userEmail}
                              recording={{
                                recordingTimeOffset: topic.start,
                                transcriptItems: topic.transcriptItems,
                              }}
                              searchTerm={searchTerm}
                              setSearchTerm={setSearchTerm}
                              topics={[topic]}
                              setTopics={setTopics}
                              videoPlayerRef={videoPlayerRef}
                              topicsPage={true}
                              currentVideoTime={currentVideoTime}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        )}
      </>
      {pageNotFound && <NotFound />}
    </Navbar>
  );
};

export default Topic;

const fetchTopic = async (
  recordingUUID,
  topicUUID,
  userEmail,
  setPageNotFound,
  setLoading
) => {
  try {
    // put fetch here
    const topicResponse = await fetch(
      `https://backend.scribbl.co/topics/${topicUUID}?email=${userEmail}&recordingUUID=${recordingUUID}`,
      {
        method: "GET",
        credentials: "include", // This line ensures cookies are sent with the request
      }
    );
    if (topicResponse.status === 404) {
      setPageNotFound(true);
      return;
    }
    const topic = await topicResponse.json();
    if (topic.isError) {
      setPageNotFound(true);
      setLoading(false);
      alert.error(topic.reason);
      return;
    }
    return topic;
  } catch (exception) {
    console.log(exception);
    return null;
  }
};

const fetchPublicTopic = async (
  recordingUUID,
  topicUUID,
  setPageNotFound,
  setLoading
) => {
  try {
    // put fetch here
    const topicResponse = await fetch(
      `https://backend.scribbl.co/topics/${topicUUID}/public?recordingUUID=${recordingUUID}`,
      {
        method: "GET",
        credentials: "include", // This line ensures cookies are sent with the request
      }
    );
    if (topicResponse.status === 404) {
      setPageNotFound(true);
      return;
    }
    const topic = await topicResponse.json();
    if (topic.isError) {
      setPageNotFound(true);
      setLoading(false);
      alert.error(topic.reason);
      return;
    }
    return topic;
  } catch (exception) {
    console.log(exception);
    return null;
  }
};
