import React, { useState, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleButton from "react-google-button";
import amplitude from "amplitude-js";
import { GoogleDocsIcon } from "../svgs/svgs";
import { ArrowRightIcon } from "@heroicons/react/outline";

import "../index.css";

const SignIn = (props) => {
  const [signInError, setSignInError] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");

  const [loginStep, setLoginStep] = useState(1); // New state for handling step in login process
  const basicScopes =
    "openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar.readonly";
  const driveScopes = "https://www.googleapis.com/auth/drive.file";

  useEffect(() => {
    amplitude.getInstance().logEvent("er onboarding sign in step");
  }, []);

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      console.log("success", codeResponse);

      // After successful login, if we're in the first step, move to the next step
      if (loginStep === 1) {
        // Extract the authorization code from the response
        const authCode = codeResponse.code;

        var reqBody = {};
        if (props.inviteID) {
          reqBody = {
            inviteID: props.inviteID,
          };
        }

        // Make a request to your backend with the authorization code
        const resp = await fetch(
          `https://backend.scribbl.co/auth/code?code=${authCode}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(reqBody),
            credentials: "include", // This line ensures cookies are sent with the request
          }
        );
        if (resp.status === 200) {
          const respJson = await resp.json();
          const email = respJson.email;
          const name = respJson.name;
          setFirstName(name);
          props.setUserEmail && props.setUserEmail(email);
          setEmail(email);

          let hasDriveAccess =
            codeResponse.scope && codeResponse.scope.includes("drive.file")
              ? true
              : false;
          console.log("has drive access?", hasDriveAccess);
          if (hasDriveAccess) {
            getUserInfo();
          } else {
            setLoginStep(2);
          }

          amplitude.getInstance().logEvent("webapp sign up with google 1");
        }
      } else if (loginStep === 2) {
        // Extract the authorization code from the response
        const authCode = codeResponse.code;

        // Make a request to your backend with the authorization code
        const resp = await fetch(
          `https://backend.scribbl.co/auth/code?code=${authCode}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
            credentials: "include", // This line ensures cookies are sent with the request
          }
        );
        if (resp.status === 200) {
          try {
            window.parent.postMessage("user signed in", "*");
            getUserInfo();
          } catch (e) {
            console.log("error signing in", e);
          }

          amplitude.getInstance().logEvent("webapp sign up with google 2");
        }
      }
    },
    onError: (err) => {
      console.log("error", err);
    },
    onNonOAuthError: (err) => {
      console.log("non oauth err", err);
    },
    hint: email,
    flow: "auth-code",
    scope: loginStep === 1 ? basicScopes : driveScopes, // Change the scopes based on the login step
  });

  const getUserInfo = async () => {
    const userInfoResp = await fetch(
      `https://backend.scribbl.co/auth/userinfo`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // This line ensures cookies are sent with the request
      }
    );
    if (userInfoResp.status === 200) {
      const userInfoJson = await userInfoResp.json();
      if (userInfoJson && userInfoJson.email) {
        navigateToOnboaringStep(userInfoJson);
      }
    } else {
      props.setSignInError("An error occurred. Please try again.");
    }
  };

  function addUtmParametersToEventProperties(eventProperties) {
    const params = new URLSearchParams(window.location.search);
    for (const [key, value] of params.entries()) {
      if (key.startsWith("utm_")) {
        eventProperties[key] = value;
      }
    }

    return eventProperties;
  }

  const navigateToOnboaringStep = (user) => {
    if (user) {
      //TODO: Not sure if we need all of these properties or if they're the right thing to pass for this onboarding flow.
      const userProperties = {
        email: user.email,
        account_type: user.subscription ? user.subscription.accountType : 0,
        org: user.orgID,
      };
      amplitude.getInstance().setUserProperties(userProperties);
      amplitude.getInstance().setUserId(user.email);
      props.setEmail(user.email);
      props.setUserObj(user);

      if (user.onboardingStatus === 1 || user.onboardingStatus === 0) {
        let eventProperties = {
          onboardingMethod: props.onboardingMethod,
        };

        const newEventProperties =
          addUtmParametersToEventProperties(eventProperties);
        amplitude
          .getInstance()
          .logEvent("er onboarding sign up success", newEventProperties);
        if (user.orgID) {
          props.setOrg(user.org);
        }
        props.nextStep("sharingPreference");
      } else {
        // existing user logged in
        // uncomment to test login with existing user - props.nextStep("joinTeam");
        amplitude
          .getInstance()
          .logEvent("er onboarding sign in success existing");
        props.nextStep("complete");
      }
    }
  };

  return (
    <div className="flex">
      <div />
      {loginStep === 1 && (
        <div className="grow flex justify-center flex-col pb-16">
          <span className="text-center font-thin text-5xl text-gray-600">
            Welcome to Scribbl.
          </span>
          <span className="text-center font-thin text-lg text-gray-500">
            Get started for free today.
          </span>
          {signInError && (
            <div className="text-center pt-2 text-red-500	">{signInError}</div>
          )}
          <div className="flex justify-center pt-5">
            <div>
              <GoogleButton
                type="light"
                onClick={() => {
                  login();
                }}
              />
            </div>
          </div>
        </div>
      )}

      {loginStep === 2 && (
        <div className="grow flex justify-center items-center flex-col pb-16">
          <span className="text-center font-thin text-5xl text-gray-600 mb-2">
            Welcome {firstName}!
          </span>
          <GoogleDocsIcon></GoogleDocsIcon>
          <span className="mt-3 text-center font-thin text-3xl text-gray-600">
            Give Scribbl access to Google Docs.
          </span>
          <span className="text-center font-thin text-lg text-gray-500">
            Scribbl requires access to Google Docs to save your transcripts.
          </span>
          <div className="flex justify-center pt-5">
            <div>
              <button
                type="button"
                className="bg-brand-green  inline-flex items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-green-lighter2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-red"
                onClick={() => {
                  login();
                }}
              >
                Continue
                <ArrowRightIcon
                  className="-mr-0.5 h-4 w-4"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
      )}
      <div />
    </div>
  );
};

export default SignIn;
