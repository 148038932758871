import { Menu, Transition } from "@headlessui/react";
import {
  PencilIcon,
  ShareIcon,
  DotsVerticalIcon,
  DocumentAddIcon,
  FolderIcon,
} from "@heroicons/react/outline";
import React, { Fragment, useState } from "react";
import { getFormattedTranscript, classNames } from "../../helpers";

const TopBar = ({
  meeting,
  user,
  userEmail,
  onShare,
  onAddToCollection,
  isPublic,
  setMeeting,
  setShowCopiedTxToast,
}) => {
  const [editingName, setEditingName] = useState(false);
  const [editingNameValue, setEditingNameValue] = useState(
    meeting?.meetingTitle
  );

  const handleRename = async () => {
    const renameMeetingResp = await fetch(
      `https://backend.scribbl.co/enhanced-recording/${meeting.uuid}/rename`,
      {
        method: "POST",
        body: JSON.stringify({
          email: userEmail,
          name: editingNameValue,
        }),
        credentials: "include",
      }
    );
    const respJson = await renameMeetingResp.json();
    meeting.meetingTitle = respJson.name;
    setMeeting({ ...meeting });
    setEditingName(false);
  };

  return (
    <div
      className="bg-white border-b border-gray-200 px-6 flex items-center justify-between z-20"
      style={{ height: "55px" }}
    >
      <div className="flex items-center min-w-0 flex-1">
        {editingName ? (
          <div className="flex items-center space-x-2">
            <input
              className="shadow-sm focus:ring-brand-green focus:border-brand-green block sm:text-sm border-gray-300 rounded-md"
              value={editingNameValue}
              onChange={(e) => setEditingNameValue(e.target.value)}
            />
            <button
              onClick={handleRename}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green"
            >
              Save
            </button>
            <button
              onClick={() => setEditingName(false)}
              className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="flex items-center">
            <h1 className="text-lg font-medium text-brand-gray truncate">
              {meeting.meetingTitle}
            </h1>
            {!isPublic && (
              <PencilIcon
                className="h-4 w-4 ml-3 text-gray-400 cursor-pointer hover:text-gray-600"
                onClick={() => {
                  setEditingNameValue(meeting.meetingTitle);
                  setEditingName(true);
                }}
              />
            )}
          </div>
        )}
      </div>

      <div className="flex items-center space-x-2">
        {/* Share button always visible except on very small screens */}
        {!isPublic && (meeting.role === 1 || meeting.role === 2) && (
          <button
            onClick={onShare}
            className="hidden sm:inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
          >
            <ShareIcon className="h-4 w-4 mr-2" />
            Share
          </button>
        )}

        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200">
            <DotsVerticalIcon className="h-4 w-4" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        const transcriptText = getFormattedTranscript(meeting);
                        navigator.clipboard
                          .writeText(transcriptText)
                          .then(() => {
                            setShowCopiedTxToast(true);
                            setTimeout(() => setShowCopiedTxToast(false), 3000);
                          })
                          .catch((err) => {
                            console.error("Failed to copy transcript:", err);
                          });
                      }}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "flex px-4 py-2 text-sm text-gray-700 w-full"
                      )}
                    >
                      <DocumentAddIcon className="h-4 w-4 mr-3" />
                      Export Transcript
                    </button>
                  )}
                </Menu.Item>

                {meeting?.googleDocLink && !isPublic && meeting.role === 1 && (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() =>
                          window.open(meeting.googleDocLink, "_blank").focus()
                        }
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "flex px-4 py-2 text-sm text-gray-700 w-full"
                        )}
                      >
                        <img
                          className="h-4 w-4 mr-3"
                          src="/google-docs-icon.png"
                          alt="Google Docs"
                        />
                        Google Doc
                      </button>
                    )}
                  </Menu.Item>
                )}

                {!isPublic && user?.orgID && (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={onAddToCollection}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "flex px-4 py-2 text-sm text-gray-700 w-full"
                        )}
                      >
                        <FolderIcon className="h-4 w-4 mr-3" />
                        Add to Collection
                      </button>
                    )}
                  </Menu.Item>
                )}

                {/* Share only shows in menu for very small screens */}
                {!isPublic && (meeting.role === 1 || meeting.role === 2) && (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={onShare}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "sm:hidden flex px-4 py-2 text-sm text-gray-700 w-full"
                        )}
                      >
                        <ShareIcon className="h-4 w-4 mr-3" />
                        Share
                      </button>
                    )}
                  </Menu.Item>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default TopBar;
