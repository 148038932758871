import React, { useState, useEffect, useRef } from "react";

import Navbar from "./Navbar";

import { sendSlackMessage } from "../slack";
import "react-multi-email/style.css";
import { useOutsideClick } from "../helpers";

import { SearchIcon, PlayIcon } from "@heroicons/react/solid";
import { LinkIcon } from "@heroicons/react/outline";
import { Combobox } from "@headlessui/react";

import amplitude from "amplitude-js";

var moment = require("moment");

const TopicsPage = () => {
  let siderRef = useRef();
  let comboboxRef = useRef();

  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);

  const [loading, setLoading] = useState(true);
  const [pageError, setPageError] = useState(false);

  const [topics, setTopics] = useState([]);
  const [topicLabels, setTopicLabels] = useState([]);

  const [activeFilters, setActiveFilters] = useState([]);

  const [searchOpen, setSearchOpen] = useState(false);

  const [hoveredTopic, setHoveredTopic] = useState(null);
  const [copyingLinkForTopic, setCopyingLinkForTopic] = useState(null);

  //Pagination
  const pageLength = 12;
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (userEmail) {
      sendSlackMessage("beta", `${userEmail} is viewing topics listview`);
    }

    if (userEmail) {
      // get topics
      async function getTopics() {
        const topics = await fetchTopics(userEmail, setPageError, setLoading);
        if (topics) {
          //sort topics by creation date
          setTopics(
            topics.sort(
              (a, b) =>
                moment(b.CreatedAt, "YYYY-MM-DDTHH:mm:ss") -
                moment(a.CreatedAt, "YYYY-MM-DDTHH:mm:ss")
            )
          );
        }
      }
      getTopics();

      async function getTopicLabels() {
        const topicLabels = await fetchTopicLabels(
          userEmail,
          setPageError,
          setLoading
        );
        if (topicLabels) {
          setTopicLabels(topicLabels);
        }
      }
      getTopicLabels();
    }
  }, [userEmail]);

  useEffect(() => {
    setTimeout(() => setCopyingLinkForTopic(null), [1000]);
  }, [copyingLinkForTopic]);

  useEffect(() => {
    amplitude.getInstance().logEvent("viewed search topics page");
  }, []);

  // When we get email, get the recording
  useEffect(() => {
    if (topics && topicLabels) {
      setLoading(false);
    }
  }, [topics, topicLabels]);

  const [query, setQuery] = useState("");
  const filteredTopicSearch =
    query === ""
      ? topicLabels
      : topicLabels.filter((topicLabel) => {
          return topicLabel.name.toLowerCase().includes(query.toLowerCase());
        });

  let filteredTopics = filterTopics(topics, activeFilters);

  useOutsideClick(comboboxRef, () => {
    setSearchOpen(false);
  });

  function removeKeywordsFromTopics(removedKeyword) {
    return filteredTopics.map((t) => {
      if (t.keywords) {
        for (let i = 0; i <= t.keywords.length; i++) {
          if (t.keywords[i] === removedKeyword.name) {
            return t.keywords.splice(i, 1);
          }
        }
      }

      return t.keywords;
    });
  }

  async function copyShareLinkToClipboard(topic) {
    let recording = topic.enhancedRecording;
    if (!navigator.clipboard) {
      console.log("clipboard copy not supported by browser");
      // Clipboard API not available
      return;
    }
    try {
      await navigator.clipboard.writeText(
        `${window.location.protocol}//${window.location.host}/recording/${recording.uuid}/topic/${topic.identifier}`
      );
    } catch (err) {
      console.log("failed to copy link:" + err);
    }
  }

  function topicDuration(topic) {
    let durationSeconds = topic.end_time - topic.start_time;
    if (durationSeconds < 60) {
      return Math.floor(durationSeconds) + "s";
    } else if (durationSeconds >= 60 && durationSeconds < 3600) {
      let durationMinutes = durationSeconds / 60;
      return Math.floor(durationMinutes) + "m";
    } else {
      let durationHours = durationSeconds / 3600;
      let durationMinutes = durationSeconds % 3600;
      return (
        Math.floor(durationHours) + "h" + Math.floor(durationMinutes) + "m"
      );
    }
  }

  // Recordings to show
  let startAt = 1;
  let endAt = 5;
  if (filteredTopics) {
    startAt = currentPage * pageLength + 1;
    endAt =
      currentPage * pageLength + pageLength <= filteredTopics.length
        ? currentPage * pageLength + pageLength
        : filteredTopics.length;
  }

  return (
    <div className="bg-gray-50">
      <Navbar
        setUserEmail={setUserEmail}
        setUser={setUser}
        isPublic={false}
        siderRef={siderRef}
        loading={loading}
      >
        <div className="w-full">
          <div className="fixed top-16 w-72">
            {/* Simple dropdown + keyword search */}
          </div>
          <div className="flex flex-col w-full justify-content-center h-28 border-b border-gray-200 bg-white ">
            {/* Header */}
            <div className="grid grid-cols-3">
              <h2 className="text-xl font-light text-gray-700 mt-6 ml-12">
                Topics
              </h2>
              {/* Search */}
              <div className="text-center items-center w-full">
                <div className="pb-3">
                  <div className="relative mt-5 justify-center flex items-center">
                    {/* Combo box */}
                    <Combobox
                      as="div"
                      ref={comboboxRef}
                      className="w-full"
                      open={searchOpen}
                      value={filteredTopicSearch}
                      onChange={(selection) => {
                        let pushFilter = true;
                        activeFilters.forEach((filter) => {
                          if (
                            selection.name.toLowerCase() ===
                            filter.name.toLowerCase()
                          ) {
                            pushFilter = false;
                          }
                        });
                        if (pushFilter) {
                          activeFilters.push(selection);
                          setActiveFilters(activeFilters);
                        }
                        setSearchOpen(false);
                        amplitude
                          .getInstance()
                          .logEvent("searched topics page");
                      }}
                    >
                      {({ open }) => {
                        return (
                          <div className="relative w-full mt-1">
                            <SearchIcon
                              className="pointer-events-none absolute top-2 left-4 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <Combobox.Input
                              placeholder="Search by topic name or keyword"
                              className="w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-5 shadow-sm focus:border-brand-green-lighter3 focus:outline-none focus:ring-1 focus:ring-brand-green-lighter3 sm:text-sm"
                              onChange={(event) => setQuery(event.target.value)}
                              onKeyDown={(event) => {
                                if (event.key === "Escape") {
                                  event.preventDefault();
                                  event.target.blur();
                                }
                              }}
                              onFocus={(event) => {
                                if (event.target.value === "") {
                                  setSearchOpen(true);
                                }
                              }}
                            />

                            {(open || searchOpen) && (
                              <Combobox.Options
                                static
                                id="testid"
                                className="absolute z-10 mt-1 max-h-[20rem] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                              >
                                <h2
                                  style={{ textAlign: "start" }}
                                  className="bg-gray-100 text-start py-2.5 px-4 text-xs font-semibold text-gray-900"
                                >
                                  Search by Keyword
                                </h2>

                                {query ? (
                                  <Combobox.Option
                                    key="keyword-option"
                                    value={{ name: query, type: "keyword" }}
                                    className={({ active }) =>
                                      classNames(
                                        "relative cursor-default select-none py-2 pl-3 pr-9",
                                        active
                                          ? "bg-brand-green-lighter3 text-white font-semibold"
                                          : "text-gray-900"
                                      )
                                    }
                                  >
                                    {({ active, selected }) => (
                                      <>
                                        <div className="flex">
                                          <div>
                                            <span
                                              className={classNames(
                                                "truncate",
                                                selected && "font-semibold"
                                              )}
                                            >
                                              {`"${query}"`}
                                            </span>
                                            <span
                                              className={classNames(
                                                "ml-2 truncate font-normal",
                                                active
                                                  ? "text-white"
                                                  : "text-gray-500"
                                              )}
                                            >
                                              {`Keyword: ${query}`}
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </Combobox.Option>
                                ) : (
                                  <span
                                    style={{ textAlign: "start" }}
                                    className={classNames(
                                      "my-2 flex ml-2 truncate text-gray-500",
                                      "text-gray-500"
                                    )}
                                  >
                                    Start typing to search by keyword
                                  </span>
                                )}
                                <h2
                                  style={{ textAlign: "start" }}
                                  className="bg-gray-100 text-start py-2.5 px-4 text-xs font-semibold text-gray-900"
                                >
                                  Search by Topic Name
                                </h2>
                                {filteredTopicSearch.map((topic) => (
                                  <Combobox.Option
                                    key={topic.ID}
                                    value={{
                                      name: topic.name,
                                      type: "topic",
                                      labelID: topic.ID,
                                    }}
                                    className={({ active }) =>
                                      classNames(
                                        "relative cursor-default select-none py-2 pl-3 pr-9",
                                        active
                                          ? "bg-brand-green-lighter3 text-white font-semibold"
                                          : "text-gray-900"
                                      )
                                    }
                                  >
                                    {({ active, selected }) => (
                                      <>
                                        <div className="flex">
                                          <span
                                            className={classNames(
                                              "truncate",
                                              selected && "font-semibold"
                                            )}
                                          >
                                            #{topic.name}
                                          </span>
                                          <span
                                            className={classNames(
                                              "ml-2 truncate font-normal",
                                              active
                                                ? "text-white"
                                                : "text-gray-500"
                                            )}
                                          >
                                            Topic
                                          </span>
                                        </div>
                                      </>
                                    )}
                                  </Combobox.Option>
                                ))}
                                {filteredTopicSearch.length === 0 && (
                                  <span
                                    style={{ textAlign: "start" }}
                                    className={classNames(
                                      "flex ml-2 truncate text-gray-500",
                                      "text-gray-500"
                                    )}
                                  >
                                    {`No topics match "${query}"`}
                                  </span>
                                )}
                              </Combobox.Options>
                            )}
                          </div>
                        );
                      }}
                    </Combobox>
                  </div>
                </div>

                {/* Filter tags */}
                <div className="flex">
                  {activeFilters.map((filter, filterIdx) => {
                    return (
                      <span
                        key={filter.labelID}
                        className="ml-2 inline-flex items-center rounded-full bg-brand-green-lighter3 py-0.5 pl-2.5 pr-1 text-sm font-medium text-white"
                      >
                        {filter.type === "topic" && "#" + filter.name}
                        {filter.type === "keyword" && '"' + filter.name + '"'}
                        <button
                          onClick={() => {
                            filteredTopics = removeKeywordsFromTopics(
                              activeFilters[filterIdx]
                            );
                            activeFilters.splice(filterIdx, 1);
                            const activeFiltersUpdate =
                              structuredClone(activeFilters);

                            setActiveFilters(activeFiltersUpdate);
                          }}
                          type="button"
                          className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-white hover:bg-brand-green-darker1 focus:bg-brand-green focus:text-white focus:outline-none"
                        >
                          <span className="sr-only">{filter.name}</span>
                          <svg
                            className="h-2 w-2"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 8 8"
                          >
                            <path
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M1 1l6 6m0-6L1 7"
                            />
                          </svg>
                        </button>
                      </span>
                    );
                  })}
                </div>
              </div>
              <div></div>
            </div>
          </div>
          {/* Topics image grid */}
          <div className="mt-9 lg:px-20" style={{ overflow: "auto" }}>
            <ul
              role="list"
              //className="grid grid-cols-2 gap-x-4 gap-y-8 sm:gap-x-6 md:grid-cols-3 lg:grid-cols-4 xl:gap-x-8"
            >
              {filteredTopics.slice(startAt - 1, endAt).map(
                (topic) =>
                  topic.coverThumbnail &&
                  topic.labels &&
                  topic.labels.length > 0 && (
                    <li
                      key={topic.ID}
                      className="align-top relative mx-3 my-4 inline-block bg-white border border-gray-200 drop-shadow rounded-md w-[250px] "
                      onMouseEnter={() => {
                        setHoveredTopic(topic);
                      }}
                      onMouseLeave={() => {
                        setHoveredTopic(null);
                      }}
                      onClick={() => {
                        let recording = topic.enhancedRecording;
                        window.open(
                          `/recording/${recording.uuid}/topic/${topic.identifier}`,
                          "_blank"
                        );
                        var eventProperties = {
                          topic_uuid: topic.identifier,
                        };
                        amplitude
                          .getInstance()
                          .logEvent("clicked topic result", eventProperties);
                      }}
                    >
                      <div className="group block w-full overflow-hidden cursor-pointer">
                        <img
                          src={topic.coverThumbnail}
                          alt=""
                          className="pointer-events-none object-cover group-hover:opacity-75 rounded-t-md w-full"
                        />
                        <div className="absolute top-[100px] left-0 w-full">
                          <span className="py-1.5 px-2 bg-gray-900 rounded-full text-white opacity-70 text-xs ml-1 font-medium">
                            {moment(new Date(topic.CreatedAt)).format(
                              "MM/DD/YYYY"
                            )}
                          </span>
                          <span className="float-right py-1.5 px-2 bg-gray-900 rounded-full text-white opacity-70 text-xs mr-1 font-medium">
                            {topicDuration(topic)}
                          </span>
                        </div>
                        {topic === hoveredTopic && (
                          <div className="absolute top-10 w-full">
                            <PlayIcon className="h-12 w-12 text-white opacity-70 mx-auto" />
                          </div>
                        )}
                        <div className="absolute top-0 left-0 w-full">
                          {topic === hoveredTopic && (
                            <button
                              className="float-right py-1 px-1.5 bg-gray-900 rounded-full text-white opacity-70 text-xs mt-1 mr-1 font-medium"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                copyShareLinkToClipboard(topic);
                                setCopyingLinkForTopic(topic);
                              }}
                            >
                              {copyingLinkForTopic != topic ? (
                                <LinkIcon className="h-4 w-4" />
                              ) : (
                                <span>Link Copied!</span>
                              )}
                            </button>
                          )}
                        </div>

                        <div className="p-2">
                          <p className="flex pointer-events-none block truncate text-xs font-medium text-gray-600">
                            {topic.enhancedRecording.meetingTitle}
                          </p>
                          <p className="pointer-events-none mt-0.5 block truncate text-sm font-bold text-brand-green">
                            #
                            {topic.labels &&
                              topic.labels.length &&
                              topic.labels[0].name}
                          </p>
                          {topic.keywords && topic.keywords.length != 0 && (
                            <p className="pointer-events-none mt-1 block truncate text-sm font-medium text-gray-600">
                              Contains:
                              {topic.keywords.map((keyword) => {
                                return <span>"{keyword}" </span>;
                              })}
                            </p>
                          )}
                        </div>
                      </div>
                    </li>
                  )
              )}
            </ul>
            {/* Pagination */}
            {pageLength <= filteredTopics.length && (
              <nav
                className=" px-4 py-3 flex items-center justify-between  border-gray-200 round-md sm:px-6"
                aria-label="Pagination"
              >
                <div className="flex-1 flex justify-between sm:justify-end">
                  <div className="hidden sm:block mr-4">
                    <p className="text-sm text-gray-700 mt-2.5">
                      Showing <span className="font-medium">{startAt}</span> to{" "}
                      <span className="font-medium">{endAt}</span> of{" "}
                      <span className="font-medium">
                        {filteredTopics.length}
                      </span>{" "}
                      results
                    </p>
                  </div>
                  <button
                    onClick={() =>
                      currentPage > 0 && setCurrentPage(currentPage - 1)
                    }
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Previous
                  </button>
                  <button
                    onClick={() =>
                      (currentPage + 1) * pageLength <= filteredTopics.length &&
                      setCurrentPage(currentPage + 1)
                    }
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Next
                  </button>
                </div>
              </nav>
            )}
            {/* Empty state */}
            {filteredTopics &&
              filteredTopics.length === 0 &&
              activeFilters.length === 0 && (
                <div className="text-center py-16">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      vectorEffect="non-scaling-stroke"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    />
                  </svg>
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No Topics
                  </h3>
                  <p className="mt-1 text-sm text-gray-500 max-w-[300px] mx-auto">
                    Install the Scribbl Chrome extension to start recording
                    meetings and creating Topics.
                  </p>
                  <div className="mt-6">
                    <button
                      type="button"
                      onClick={() =>
                        window.open(
                          "https://chrome.google.com/webstore/detail/google-meet-transcripts-b/kmjmlilenakedodldceipdnmmnfkahni?hl=en-US",
                          "_blank"
                        )
                      }
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-green hover:bg-brand-green-darker2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green"
                    >
                      Go to Chrome Store
                    </button>
                  </div>
                </div>
              )}
            {/* Search empty state */}
            {filteredTopics &&
              filteredTopics.length === 0 &&
              activeFilters.length != 0 && (
                <div className="text-center py-16">
                  <SearchIcon
                    className="mx-auto h-12 w-12 text-gray-400"
                    aria-hidden="true"
                  />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No Topics Found
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    No Topics found matching your search criteria
                  </p>
                </div>
              )}
          </div>
        </div>
      </Navbar>
    </div>
  );
};

export default TopicsPage;

const fetchTopics = async (userEmail, setPageError, setLoading) => {
  try {
    // put fetch here
    const topicsReq = await fetch(
      `https://backend.scribbl.co/topics?email=${userEmail}`,
      {
        method: "GET",
        credentials: "include", // This line ensures cookies are sent with the request
      }
    );

    const topics = await topicsReq.json();
    if (topics.isError) {
      setPageError(true);
      setLoading(false);
      alert.error(topics.reason);
      return;
    }
    return topics;
  } catch (exception) {
    console.log(exception);
    return null;
  }
};
const fetchTopicLabels = async (userEmail, setPageError, setLoading) => {
  try {
    const topicsReq = await fetch(
      `https://backend.scribbl.co/user/topics/labels?email=${userEmail}`,
      {
        method: "GET",
        credentials: "include", // This line ensures cookies are sent with the request
      }
    );

    const topics = await topicsReq.json();
    if (topics.isError) {
      setPageError(true);
      setLoading(false);
      alert.error(topics.reason);
      return;
    }
    return topics;
  } catch (exception) {
    console.log(exception);
    return null;
  }
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function filterTopics(topics, activeFilters) {
  let filteredTopics = [];
  topics.forEach((topic) => {
    if (activeFilters.length === 0) {
      if (topic.coverThumbnail && topic.labels && topic.labels.length > 0) {
        filteredTopics.push(topic);
      }
    } else {
      for (let i = 0; i < activeFilters.length; i++) {
        const filter = activeFilters[i];
        //1. filter by topic id
        if (filter.type === "topic" && topic.labels && topic.labels.length) {
          for (let i = 0; i < topic.labels.length; i++) {
            const label = topic.labels[i];
            if (label.ID === filter.labelID) {
              let shouldPush = true;
              filteredTopics.forEach((t) => {
                if (t.ID === topic.ID) {
                  shouldPush = false;
                }
              });
              if (shouldPush) {
                if (
                  topic.coverThumbnail &&
                  topic.labels &&
                  topic.labels.length > 0
                ) {
                  filteredTopics.push(topic);
                }
              }
              break;
            }
          }
        }

        //2. filter by keyword in tx
        if (
          filter.type === "keyword" &&
          topic.transcriptItems &&
          topic.transcriptItems.length
        ) {
          for (let t = 0; t < topic.transcriptItems.length; t++) {
            const ti = topic.transcriptItems[t];
            if (
              ti.transcript.toLowerCase().includes(filter.name.toLowerCase())
            ) {
              if (topic.keywords) {
                if (topic.keywords.includes(filter.name) === false) {
                  topic.keywords.push(filter.name);
                }
              } else {
                topic.keywords = [filter.name];
              }

              let shouldPush = true;
              filteredTopics.forEach((t) => {
                if (t.ID === topic.ID) {
                  shouldPush = false;
                }
              });
              if (shouldPush) {
                if (
                  topic.coverThumbnail &&
                  topic.labels &&
                  topic.labels.length > 0
                ) {
                  filteredTopics.push(topic);
                }
              }
              break;
            }
          }
        }
      }
    }
  });
  return filteredTopics;
}
