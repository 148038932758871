// GoogleDriveIntegration.jsx
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import { LinkIcon } from "@heroicons/react/solid";
import amplitude from "amplitude-js";

const GoogleDriveIntegration = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [driveConnected, setDriveConnected] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState("disconnected");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    checkGoogleDriveConnection();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");

    if (code) {
      handleGoogleDriveCallback(code);
    } else {
      checkGoogleDriveConnection();
    }
  }, [location]);

  useEffect(() => {
    if (user) {
      setLoading(false);
      amplitude
        .getInstance()
        .logEvent("Integrations drive integration visited");
    }
  }, [user, driveConnected, connectionStatus]);

  const checkGoogleDriveConnection = async () => {
    try {
      const response = await fetch(
        "https://backend.scribbl.co/google-drive/status",
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setDriveConnected(data.connected);
        setConnectionStatus(data.connected ? "connected" : "disconnected");
      }
    } catch (error) {
      console.error("Error checking Google Drive connection:", error);
      setConnectionStatus("error");
    }
  };

  const handleConnectGoogleDrive = async () => {
    try {
      setConnectionStatus("connecting");
      const response = await fetch(
        "https://backend.scribbl.co/google-drive/connect",
        {
          method: "GET",
          credentials: "include",
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        setDriveConnected(true);
        setConnectionStatus("connected");

        amplitude.getInstance().logEvent("Integrations drive connected");
      }
    } catch (error) {
      console.error("Error connecting to Google Drive:", error);
      setConnectionStatus("error");
    }
  };

  const handleGoogleDriveCallback = async (code) => {
    try {
      setConnectionStatus("connecting");
      const response = await fetch(
        `https://backend.scribbl.co/google-drive/callback?code=${code}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        await checkGoogleDriveConnection();
        navigate("/integrations/google-drive", { replace: true });
      } else {
        throw new Error("Failed to exchange code for token");
      }
    } catch (error) {
      console.error("Error handling Google Drive callback:", error);
      setConnectionStatus("error");
    }
  };

  const handleDisconnectGoogleDrive = async () => {
    try {
      setConnectionStatus("disconnecting");
      const response = await fetch(
        "https://backend.scribbl.co/google-drive/disconnect",
        {
          method: "POST",
          credentials: "include",
        }
      );
      if (response.ok) {
        setDriveConnected(false);
        setConnectionStatus("disconnected");
      }
    } catch (error) {
      console.error("Error disconnecting from Google Drive:", error);
      setConnectionStatus("error");
    }
  };

  const renderConnectionStatus = () => {
    switch (connectionStatus) {
      case "connected":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
            <span className="w-2 h-2 mr-2 bg-green-400 rounded-full"></span>
            Connected
          </span>
        );
      case "disconnected":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
            <span className="w-2 h-2 mr-2 bg-gray-400 rounded-full"></span>
            Disconnected
          </span>
        );
      case "connecting":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
            <svg
              className="animate-spin -ml-1 mr-2 h-4 w-4 text-blue-600"
              xmlns="http://w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Connecting
          </span>
        );
      case "disconnecting":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
            <svg
              className="animate-spin -ml-1 mr-2 h-4 w-4 text-yellow-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Disconnecting
          </span>
        );
      case "error":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-red-100 text-red-800">
            <span className="w-2 h-2 mr-2 bg-red-400 rounded-full"></span>
            Error
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <Navbar setUser={setUser} loading={loading}>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-bold text-gray-900">
            Google Drive Integration
          </h1>
          <svg
            className="w-32 h-16"
            xmlns="http://www.w3.org/2000/svg"
            width="2500"
            height="2166"
            viewBox="0 0 1443.061 1249.993"
          >
            <path
              fill="#3777e3"
              d="M240.525 1249.993l240.492-416.664h962.044l-240.514 416.664z"
            />
            <path
              fill="#ffcf63"
              d="M962.055 833.329h481.006L962.055 0H481.017z"
            />
            <path
              fill="#11a861"
              d="M0 833.329l240.525 416.664 481.006-833.328L481.017 0z"
            />
          </svg>
        </div>

        <div className="bg-white shadow sm:rounded-lg mb-8 overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Connection Status
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Connect Scribbl with your Google Drive account to sync meeting
                  notes and documents.
                </p>
              </div>
              {renderConnectionStatus()}
            </div>
            <div className="mt-5">
              {!driveConnected && user && user.orgID ? (
                <button
                  type="button"
                  onClick={handleConnectGoogleDrive}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green-darker2"
                >
                  <LinkIcon className="mr-2 h-5 w-5" />
                  Connect to Google Drive
                </button>
              ) : (
                user &&
                user.orgID && (
                  <button
                    type="button"
                    onClick={handleDisconnectGoogleDrive}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green-darker2"
                  >
                    Disconnect from Google Drive
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default GoogleDriveIntegration;
