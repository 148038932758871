import React, { useState, useEffect, useMemo } from "react";
import {
  UserGroupIcon,
  LockClosedIcon,
  SearchIcon,
  XIcon,
  UserIcon,
} from "@heroicons/react/outline";

const SharingStep = ({
  collectionType,
  contributorOption,
  setContributorOption,
  selectedContributors,
  setSelectedContributors,
  user,
  automationRules,
  onPrivacyAcknowledged,
}) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredTeamMembers = useMemo(() => {
    return teamMembers.filter(
      (member) =>
        !selectedContributors.some(
          (selected) => selected.email === member.email
        ) &&
        (member.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          member.email.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [teamMembers, selectedContributors, searchTerm]);

  useEffect(() => {
    if (collectionType === "1 on 1") {
      setContributorOption("just-me");
    }
  }, [collectionType, setContributorOption]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await fetch(`https://backend.scribbl.co/orgs/members`, {
          credentials: "include",
        });
        const data = await response.json();

        // Filter out the current user
        const filteredTeamMembers = data.filter(
          (member) => member.email !== user.email
        );

        setTeamMembers(filteredTeamMembers);
      } catch (error) {
        console.error("Error fetching team members:", error);
      }
    };

    if (user?.orgID) {
      fetchTeamMembers();
    }
  }, [user]);

  const handleContributorOptionChange = (option) => {
    setContributorOption(option);
    if (option !== "manual") {
      setSelectedContributors([]);
    }
  };

  const handleContributorSelect = (member) => {
    setSelectedContributors([...selectedContributors, member]);
  };

  const handleContributorRemove = (memberToRemove) => {
    setSelectedContributors(
      selectedContributors.filter(
        (member) => member.email !== memberToRemove.email
      )
    );
  };

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold text-gray-900">Contributor Settings</h2>
      <p className="mt-2 text-sm text-gray-500">
        Define who can contribute meetings to this collection. Contributor
        meetings will be included based on the automation rules. You can always
        adjust these settings later.
      </p>

      <div className="space-y-2">
        {["organization", "just-me", "manual"].map((option) => (
          <label key={option} className="flex items-center space-x-2">
            <input
              type="radio"
              className="form-radio text-brand-turq"
              name="contributorOption"
              value={option}
              checked={contributorOption === option}
              onChange={() => handleContributorOptionChange(option)}
              disabled={collectionType === "1on1" && option !== "just-me"}
            />
            <span>
              {option === "organization" && "All team members can contribute"}
              {option === "just-me" && "Only I can contribute"}
              {option === "manual" && "Select specific contributors"}
            </span>
            {option === "organization" &&
              contributorOption === "organization" && (
                <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                  Recommended
                </span>
              )}
          </label>
        ))}
      </div>
      <PrivacyWarning
        automationRules={automationRules}
        contributorOption={contributorOption}
        user={user}
        onAcknowledgement={onPrivacyAcknowledged}
      />

      <div className="mt-4 bg-gray-50 p-4 rounded-md h-80 overflow-y-auto">
        {contributorOption === "organization" && (
          <div className="bg-blue-50 border-l-4 border-blue-400 p-3 flex items-start">
            <UserGroupIcon className="h-5 w-5 text-blue-400 mr-2 flex-shrink-0" />
            <p className="text-sm text-blue-700">
              All current and future members of your organization will be able
              to contribute to this collection.
            </p>
          </div>
        )}

        {contributorOption === "just-me" && (
          <div className="bg-green-50 border-l-4 border-green-400 p-3 flex items-start">
            <LockClosedIcon className="h-5 w-5 text-green-400 mr-2 flex-shrink-0" />
            <p className="text-sm text-green-700">
              Only you will be able to contribute to this collection. Others can
              view but not modify it.
            </p>
          </div>
        )}

        {contributorOption === "manual" && (
          <div className="space-y-4 h-full flex flex-col">
            <div className="relative">
              <input
                type="text"
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-brand-turq focus:border-brand-turq sm:text-sm"
                placeholder="Search team members"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            </div>

            <div className="flex-grow overflow-hidden flex flex-col">
              <div className="border border-gray-200 rounded-md overflow-hidden flex-grow">
                <ul className="divide-y divide-gray-200 overflow-y-auto h-full">
                  {filteredTeamMembers.map((member) => (
                    <li
                      key={member.id || member.email}
                      className="p-2 flex items-center justify-between hover:bg-gray-50 cursor-pointer"
                      onClick={() => handleContributorSelect(member)}
                    >
                      <div className="flex items-center min-w-0">
                        <UserIcon className="h-5 w-5 text-gray-400 mr-2 flex-shrink-0" />
                        <div className="min-w-0 flex-1">
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {member.name || member.email}
                          </p>
                          <p className="text-xs text-gray-500 truncate">
                            {member.email}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              {selectedContributors.length > 0 && (
                <div className="mt-4 max-h-24">
                  <h4 className="text-sm font-medium text-gray-900 mb-2">
                    Selected Contributors
                  </h4>
                  <div className="flex flex-wrap gap-2 max-h-24 overflow-y-auto">
                    {selectedContributors.map((member) => (
                      <div
                        key={member.email}
                        className="inline-flex items-center bg-gray-100 rounded-full px-3 py-1 text-sm"
                      >
                        <span className="truncate max-w-xs">
                          {member.name || member.email}
                        </span>
                        <button
                          onClick={() => handleContributorRemove(member)}
                          className="ml-2 text-gray-500 hover:text-gray-700"
                        >
                          <XIcon className="h-4 w-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const PrivacyWarning = ({
  automationRules,
  contributorOption,
  user,
  onAcknowledgement,
}) => {
  const [acknowledged, setAcknowledged] = useState(false);
  const userDomain = user.email.split("@")[1];

  const hasInternalEmailRules = automationRules.some(
    (rule) =>
      rule.ruleType === "email" &&
      rule.value.toLowerCase().endsWith(`@${userDomain.toLowerCase()}`)
  );
  const hasInternalDomainRule = automationRules.some(
    (rule) =>
      rule.ruleType === "domain" &&
      rule.value.toLowerCase().includes(userDomain.toLowerCase())
  );
  const isSharedWithOrg = contributorOption === "organization";

  if (!isSharedWithOrg || (!hasInternalEmailRules && !hasInternalDomainRule)) {
    return null;
  }

  const handleAcknowledgement = (e) => {
    setAcknowledged(e.target.checked);
    onAcknowledgement(e.target.checked);
  };

  return (
    <div className="mt-4 rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-red-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            Important Privacy Alert
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <p>
              {hasInternalDomainRule ? (
                <>
                  You've set up a rule to capture all meetings within your
                  company domain ({userDomain}). This means ALL internal
                  meetings, including meetings like private 1:1s between any
                  team members, could be shared with everyone in the
                  organization.
                </>
              ) : (
                hasInternalEmailRules && (
                  <>
                    You've set up rules to capture meetings involving specific
                    internal team members. This means their 1:1 meetings will be
                    shared with everyone in the organization.
                  </>
                )
              )}
            </p>
          </div>
          <div className="mt-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={acknowledged}
                className="form-checkbox h-4 w-4 text-red-600"
                onChange={handleAcknowledgement}
              />
              <span className="ml-2 text-sm text-red-700">
                I understand this will share private internal meetings with
                everyone in the organization
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharingStep;
