import React, { useEffect, useState } from "react";
import { Dialog, Switch, Transition } from "@headlessui/react";
import {
  UserIcon,
  UserGroupIcon,
  LockClosedIcon,
  SearchIcon,
  UserAddIcon,
  XIcon,
} from "@heroicons/react/outline";
import amplitude from "amplitude-js";

const ShareModal = ({ isOpen, onClose, user, collectionUUID }) => {
  const [sharedMembers, setSharedMembers] = useState([]);
  const [availableMembers, setAvailableMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOwner, setIsOwner] = useState(false);
  const [pendingShare, setPendingShare] = useState({});
  const [addAllRole, setAddAllRole] = useState("");
  const [sharedWithOrg, setSharedWithOrg] = useState(false);

  const sortMembers = (members, currentUserID) => {
    return members.sort((a, b) =>
      a.user.ID === currentUserID ? -1 : b.user.ID === currentUserID ? 1 : 0
    );
  };

  const getAccessTypeLabel = (accessType) => {
    switch (accessType) {
      case 1:
        return "Owner";
      case 2:
        return "Contributor";
      case 3:
        return "Viewer";
      default:
        return "Unknown";
    }
  };

  const toggleMemberAccess = async (member, newAccessType) => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collectionUUID}/users`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userAccesses: [
              {
                user: member,
                shouldHaveAccess: true,
                accessType: newAccessType,
              },
            ],
          }),
          credentials: "include",
        }
      );

      if (!response.ok) throw new Error("Failed to update access");

      const updatedMembers = await response.json();
      updateMemberLists(updatedMembers);

      const accessTypeLabel = getAccessTypeLabel(newAccessType).toLowerCase();
      amplitude
        .getInstance()
        .logEvent(
          `Collection dashboard: share setting changed - added ${accessTypeLabel}`
        );
    } catch (error) {
      console.error("Error updating member access:", error);
    }
  };

  const toggleSharedWithOrg = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collectionUUID}/toggle-shared-with-org`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) throw new Error("Failed to toggle shared with org");

      const updatedCollection = await response.json();
      setSharedWithOrg(updatedCollection.sharedWithOrg);

      // Refresh the member list
      const membersResponse = await fetch(
        `https://backend.scribbl.co/collections/${collectionUUID}/users`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (membersResponse.ok) {
        const data = await membersResponse.json();
        updateMemberLists(data.members);
      }

      if (updatedCollection.sharedWithOrg) {
        amplitude
          .getInstance()
          .logEvent(
            "Collection dashboard: share setting changed - enabled org-wide sharing"
          );
      }
    } catch (error) {
      console.error("Error toggling shared with org:", error);
    }
  };

  const removeMemberAccess = async (member) => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collectionUUID}/users`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userAccesses: [
              {
                user: member,
                shouldHaveAccess: false,
              },
            ],
          }),
          credentials: "include",
        }
      );

      if (!response.ok) throw new Error("Failed to remove access");

      const updatedMembers = await response.json();
      updateMemberLists(updatedMembers);
      amplitude.getInstance().logEvent("Collection Member Access Removed");
    } catch (error) {
      console.error("Error removing member access:", error);
    }
  };

  const addAllMembers = async (role) => {
    const membersToAdd = filteredAvailableMembers.map((member) => ({
      user: member.user,
      shouldHaveAccess: true,
      accessType: parseInt(role),
    }));

    try {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collectionUUID}/users`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userAccesses: membersToAdd,
          }),
          credentials: "include",
        }
      );

      if (!response.ok) throw new Error("Failed to add all members");

      const updatedMembers = await response.json();
      updateMemberLists(updatedMembers);

      const accessTypeLabel = getAccessTypeLabel(parseInt(role)).toLowerCase();
      amplitude
        .getInstance()
        .logEvent(
          `Collection dashboard: share setting changed - added all as ${accessTypeLabel}s`
        );
    } catch (error) {
      console.error("Error adding all members:", error);
    }
  };

  const updateMemberLists = (members) => {
    const shared = [];
    const available = [];
    members.forEach((member) => {
      if (member.shouldHaveAccess) {
        shared.push(member);
      } else {
        available.push(member);
      }
    });
    setSharedMembers(sortMembers(shared, user.ID));
    setAvailableMembers(sortMembers(available, user.ID));
  };

  useEffect(() => {
    const getOrgMembers = async () => {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collectionUUID}/users`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        updateMemberLists(data.members);
        setSharedWithOrg(data.sharedWithOrg);
        const currentUserAccess = data.members.find(
          (member) => member.user.ID === user.ID
        );
        setIsOwner(currentUserAccess && currentUserAccess.accessType === 1);
      } else {
        console.error("Failed to fetch org members");
      }
    };
    getOrgMembers();
  }, [user.ID, collectionUUID]);

  const filteredAvailableMembers = availableMembers.filter(
    (member) =>
      member.user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      member.user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderTeamSharedContent = () => (
    <div className="h-56 space-y-6">
      <div className="flex items-center justify-between bg-brand-turq bg-opacity-10 p-4 rounded-lg">
        <div className="flex items-center space-x-3">
          <UserGroupIcon className="h-6 w-6 text-brand-turq" />
          <div>
            <h4 className="text-lg font-semibold text-gray-900">
              Shared with entire team
            </h4>
            <p className="text-sm text-gray-600">
              All team members can access this collection
            </p>
          </div>
        </div>
        <Switch
          checked={sharedWithOrg}
          onChange={toggleSharedWithOrg}
          className={`${
            sharedWithOrg ? "bg-brand-turq" : "bg-gray-200"
          } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq`}
        >
          <span className="sr-only">Toggle team sharing</span>
          <span
            className={`${
              sharedWithOrg ? "translate-x-6" : "translate-x-1"
            } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
          />
        </Switch>
      </div>
    </div>
  );

  const renderIndividualSharingContent = () => (
    <div className="space-y-4">
      <div className="flex items-center justify-between bg-gray-100 p-3 rounded-lg">
        <div className="flex items-center space-x-2">
          <UserGroupIcon className="h-5 w-5 text-gray-400" />
          <div>
            <h4 className="text-sm font-semibold text-gray-900">
              Share with entire team
            </h4>
          </div>
        </div>
        <Switch
          checked={sharedWithOrg}
          onChange={toggleSharedWithOrg}
          className={`${
            sharedWithOrg ? "bg-brand-turq" : "bg-gray-200"
          } relative inline-flex items-center h-5 rounded-full w-10 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq`}
        >
          <span className="sr-only">Toggle team sharing</span>
          <span
            className={`${
              sharedWithOrg ? "translate-x-5" : "translate-x-1"
            } inline-block w-3 h-3 transform bg-white rounded-full transition-transform`}
          />
        </Switch>
      </div>

      {/* Currently Shared With Section */}
      <div>
        <h4 className="text-sm font-medium text-gray-900 mb-2">
          Currently Shared With
        </h4>
        <div
          className="bg-gray-50 border border-gray-200 rounded-md overflow-hidden"
          style={{ maxHeight: "200px" }}
        >
          <ul
            className="divide-y divide-gray-200 overflow-y-auto"
            style={{ maxHeight: "200px" }}
          >
            {sharedMembers.map((member) => (
              <li
                key={member.user.ID}
                className="p-3 flex justify-between items-center"
              >
                <div className="flex items-center min-w-0 flex-1 mr-2">
                  <UserIcon className="h-5 w-5 text-gray-400 mr-2 flex-shrink-0" />
                  <div className="min-w-0 flex-1">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {member.user.name}
                    </p>
                    <p className="text-xs text-gray-500 truncate">
                      {member.user.email}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-2 flex-shrink-0">
                  <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-brand-turq bg-opacity-10 text-brand-turq">
                    {getAccessTypeLabel(member.accessType)}
                  </span>
                  {member.user.ID !== user.ID && (
                    <>
                      <select
                        value={member.accessType}
                        onChange={(e) =>
                          toggleMemberAccess(
                            member.user,
                            parseInt(e.target.value)
                          )
                        }
                        className="text-xs rounded-md border-gray-300 text-brand-turq focus:ring-brand-turq focus:border-brand-turq"
                      >
                        <option value={2}>Contributor</option>
                        <option value={3}>Viewer</option>
                      </select>
                      <button
                        onClick={() => removeMemberAccess(member.user)}
                        className="text-xs text-red-600 hover:text-red-800 whitespace-nowrap"
                      >
                        Remove
                      </button>
                    </>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Share with Team Members Section */}
      <div>
        <h4 className="text-sm font-medium text-gray-900 mb-2">
          Share with Team Members
        </h4>
        <div className="flex items-center justify-between mb-2">
          <div className="relative flex-grow mr-2">
            <input
              type="text"
              className="block w-full pl-8 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-brand-turq focus:border-brand-turq sm:text-sm"
              placeholder="Search team members"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <SearchIcon
                className="h-4 w-4 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
          <div className="flex items-center">
            <select
              value={addAllRole}
              onChange={(e) => setAddAllRole(e.target.value)}
              className="text-xs rounded-md border-gray-300 text-brand-turq focus:ring-brand-turq focus:border-brand-turq mr-2"
            >
              <option value="">Add all as...</option>
              <option value="2">Contributor</option>
              <option value="3">Viewer</option>
            </select>
            <button
              onClick={() => {
                if (addAllRole) {
                  addAllMembers(addAllRole);
                  setAddAllRole("");
                }
              }}
              disabled={!addAllRole}
              className="inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-brand-turq hover:bg-brand-green-lighter1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq disabled:opacity-50"
            >
              <UserGroupIcon className="h-4 w-4 mr-1" aria-hidden="true" />
              Add All
            </button>
          </div>
        </div>
        <div className="border border-gray-200 rounded-md overflow-hidden">
          <ul className="divide-y divide-gray-200 max-h-48 overflow-y-auto">
            {filteredAvailableMembers.map((member) => (
              <li
                key={member.user.ID}
                className="p-3 flex items-center justify-between hover:bg-gray-50"
              >
                <div className="flex items-center min-w-0 flex-1 mr-2">
                  <UserIcon className="h-5 w-5 text-gray-400 mr-2 flex-shrink-0" />
                  <div className="min-w-0 flex-1">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {member.user.name}
                    </p>
                    <p className="text-xs text-gray-500 truncate">
                      {member.user.email}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-2 flex-shrink-0">
                  <select
                    value={pendingShare[member.user.ID] || ""}
                    onChange={(e) =>
                      setPendingShare({
                        ...pendingShare,
                        [member.user.ID]: e.target.value,
                      })
                    }
                    className="text-xs rounded-md border-gray-300 text-brand-turq focus:ring-brand-turq focus:border-brand-turq"
                  >
                    <option value="">Select role</option>
                    <option value="2">Contributor</option>
                    <option value="3">Viewer</option>
                  </select>
                  <button
                    onClick={() => {
                      if (pendingShare[member.user.ID]) {
                        toggleMemberAccess(
                          member.user,
                          parseInt(pendingShare[member.user.ID])
                        );
                        setPendingShare({
                          ...pendingShare,
                          [member.user.ID]: "",
                        });
                      }
                    }}
                    disabled={!pendingShare[member.user.ID]}
                    className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-brand-turq hover:bg-brand-green-lighter1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq disabled:opacity-50"
                  >
                    <UserAddIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Share Collection
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Manage access to this collection for team members.
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-5 sm:mt-4">
                {isOwner ? (
                  sharedWithOrg ? (
                    renderTeamSharedContent()
                  ) : (
                    renderIndividualSharingContent()
                  )
                ) : (
                  <div className="text-center">
                    <LockClosedIcon className="mx-auto h-12 w-12 text-gray-400" />
                    <h3 className="mt-2 text-lg font-medium text-gray-900">
                      Access Restricted
                    </h3>
                    <p className="mt-2 text-sm text-gray-500">
                      Only the owner of this collection can modify share
                      settings.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ShareModal;
