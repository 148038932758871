import React, { useState, useEffect, useMemo, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XIcon,
  UserGroupIcon,
  SearchIcon,
  UserAddIcon,
  LinkIcon,
  MailIcon,
  ExclamationCircleIcon,
  CheckIcon,
} from "@heroicons/react/outline";
import Avatar from "./Avatar";
import amplitude from "amplitude-js";

const isValidEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

const ShareMeetingModal = ({
  isOpen,
  onClose,
  uuid,
  userEmail,
  user,
  setTriggerTeamModal,
  currentVideoTime,
}) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [sharedMembers, setSharedMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [copyButtonText, setCopyButtonText] = useState("Copy");
  const [publicLink, setPublicLink] = useState("");

  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [showExternalShare, setShowExternalShare] = useState(false);
  const [externalEmail, setExternalEmail] = useState("");

  const [activeTab, setActiveTab] = useState("team");

  const [inviteEmail, setInviteEmail] = useState("");
  const [showInviteForm, setShowInviteForm] = useState(false);

  const [inviteError, setInviteError] = useState("");

  // should be passed in
  const [shareAtCurrentTime, setShareAtCurrentTime] = useState(false);

  useEffect(() => {
    if (user?.orgID) {
      fetchTeamMembers();
    }
    fetchSharedMembers();
  }, [user, uuid, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setShareAtCurrentTime(false);
    }
  }, [isOpen]);

  const fetchTeamMembers = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/orgs/members?include_pending=true`,
        {
          credentials: "include",
        }
      );
      const data = await response.json();

      const filteredTeamMembers = data.filter(
        (member) => member.email !== userEmail
      );

      setTeamMembers(filteredTeamMembers);
    } catch (error) {
      console.error("Error fetching team members:", error);
    }
  };

  const fetchSharedMembers = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/enhanced-recording/${uuid}/shared`,
        {
          credentials: "include",
        }
      );
      const data = await response.json();

      // Filter out the current user from the shared members list
      const filteredSharedMembers = data.filter(
        (member) => member.email !== userEmail
      );

      setSharedMembers(filteredSharedMembers);
    } catch (error) {
      console.error("Error fetching shared members:", error);
    }
  };

  const filteredTeamMembers = useMemo(() => {
    return teamMembers.filter(
      (member) =>
        !sharedMembers.some((shared) => shared.email === member.email) &&
        (member.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          member.email.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [teamMembers, sharedMembers, searchTerm]);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedTeamMembers([]);
    } else {
      setSelectedTeamMembers(filteredTeamMembers.map((member) => member.email));
    }
    setSelectAll(!selectAll);
  };

  const handleTeamMemberSelect = (email) => {
    if (selectedTeamMembers.includes(email)) {
      setSelectedTeamMembers(
        selectedTeamMembers.filter((selectedEmail) => selectedEmail !== email)
      );
    } else {
      setSelectedTeamMembers([...selectedTeamMembers, email]);
    }
  };

  const handleShareWithSelectedMembers = async () => {
    const emailsToShare = [
      ...sharedMembers.map((member) => member.email),
      ...selectedTeamMembers,
    ];
    await shareRecording(uuid, userEmail, false, emailsToShare);
    const newlySharedMembers = teamMembers.filter((member) =>
      selectedTeamMembers.includes(member.email)
    );
    setSharedMembers([...sharedMembers, ...newlySharedMembers]);
    setSelectedTeamMembers([]);
    setSelectAll(false);
    setSearchTerm("");

    amplitude.getInstance().logEvent("user shared meeting via team member");
  };

  const handleUnshare = async (member) => {
    const updatedEmails = sharedMembers
      .filter((m) => m.email !== member.email)
      .map((m) => m.email);
    await shareRecording(uuid, userEmail, false, updatedEmails);
    setSharedMembers(sharedMembers.filter((m) => m.email !== member.email));
  };

  const generatePublicLink = async () => {
    const link = await getShareLink(
      uuid,
      userEmail,
      shareAtCurrentTime ? currentVideoTime : null
    );
    setPublicLink(link);
  };

  const handleShareExternalMember = async (email) => {
    await shareRecording(uuid, userEmail, false, [
      ...sharedMembers.map((m) => m.email),
      email,
    ]);
    setSharedMembers([...sharedMembers, { email }]);
    setExternalEmail("");
    setShowExternalShare(false);
  };

  const inviteUserToOrg = async (orgID, userEmail) => {
    try {
      const response = await fetch(`https://backend.scribbl.co/orgs/invite-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ orgID, userEmail }),
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.text();
        throw new Error(errorData || "Failed to invite user");
      }
      const json = await response.json();

      amplitude
        .getInstance()
        .logEvent("user invited user to team from share meeting modal");

      return json;
    } catch (error) {
      console.error("Error inviting user:", error);
      throw error;
    }
  };

  const handleInviteTeamMember = async () => {
    if (!isValidEmail(inviteEmail)) {
      setInviteError("Please enter a valid email address.");
      return;
    }

    try {
      await inviteUserToOrg(user.orgID, inviteEmail);

      // Re-fetch team members
      await fetchTeamMembers();

      // Hide the invite form
      setShowInviteForm(false);

      // Fill the team search with the invited user's email
      setSearchTerm(inviteEmail);

      // Clear the invite email input
      setInviteEmail("");

      // Show success message
      setInviteError(""); // Clear any previous error
    } catch (error) {
      setInviteError(error.message);
    }
  };

  const tabContent = {
    team: (
      <div className="space-y-6">
        {sharedMembers.length > 0 && (
          <div>
            <h4 className="text-sm font-medium text-gray-900 mb-2">
              Currently Shared With
            </h4>
            <div className="bg-gray-50 border border-gray-200 rounded-md p-3">
              <ul className="max-h-32 overflow-y-auto divide-y divide-gray-200">
                {sharedMembers.map((member) => (
                  <li
                    key={member.email}
                    className="py-2 flex justify-between items-center"
                  >
                    <div className="flex items-center">
                      <Avatar name={member.name} email={member.email} />
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">
                          {member.name || member.email}
                        </p>
                        <p className="text-xs text-gray-500">{member.email}</p>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="ml-2 text-xs font-medium text-red-600 hover:text-red-500"
                      onClick={() => handleUnshare(member)}
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {user && user.orgID && (
          <div>
            <div>
              <h4 className="text-sm font-medium text-gray-900 mb-2">
                Share with Team Members
              </h4>
              <div className="relative">
                <input
                  type="text"
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-brand-green focus:border-brand-green sm:text-sm"
                  placeholder="Search team members"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
            {filteredTeamMembers.length > 0 ? (
              <div>
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center">
                    <input
                      id="select-all"
                      type="checkbox"
                      className="h-4 w-4 text-brand-green focus:ring-brand-green border-gray-300 rounded"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                    <label
                      htmlFor="select-all"
                      className="ml-2 block text-sm text-gray-900"
                    >
                      Select All
                    </label>
                  </div>
                  <span className="text-xs text-gray-500">
                    {selectedTeamMembers.length} selected
                  </span>
                </div>
                <ul className="max-h-48 overflow-y-auto divide-y divide-gray-200 border border-gray-200 rounded-md">
                  {filteredTeamMembers.map((member) => (
                    <li
                      key={member.id || member.email}
                      className={`p-3 flex items-center transition duration-150 ease-in-out cursor-pointer ${
                        selectedTeamMembers.includes(member.email)
                          ? "bg-brand-green bg-opacity-10"
                          : "hover:bg-gray-50"
                      }`}
                      onClick={() => handleTeamMemberSelect(member.email)}
                    >
                      <div className="flex items-center flex-grow">
                        <input
                          type="checkbox"
                          className="h-4 w-4 text-brand-green focus:ring-brand-green border-gray-300 rounded"
                          checked={selectedTeamMembers.includes(member.email)}
                          onChange={() => {}} // Handle change in the onClick of the li element
                        />
                        <div className="ml-3 flex items-center flex-grow">
                          <Avatar name={member.name} email={member.email} />
                          <div className="ml-3 flex-grow">
                            <p className="text-sm font-medium text-gray-900">
                              {member.name || member.email}
                              {member.orgID === null && (
                                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                  Team Invite Pending
                                </span>
                              )}
                            </p>
                            <p className="text-xs text-gray-500">
                              {member.email}
                            </p>
                          </div>
                        </div>
                      </div>
                      {selectedTeamMembers.includes(member.email) && (
                        <CheckIcon
                          className="h-5 w-5 text-brand-green"
                          aria-hidden="true"
                        />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              searchTerm && (
                <div className="bg-blue-50 border-l-4 border-blue-400 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-blue-700">
                        Team member not found. Add the user to your team to
                        share this meeting, or share the meeting externally.
                      </p>
                      <div className="mt-2">
                        <button
                          type="button"
                          className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-brand-green hover:bg-brand-green-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green"
                          onClick={() => setShowInviteForm(true)}
                        >
                          <UserAddIcon
                            className="-ml-0.5 mr-2 h-4 w-4"
                            aria-hidden="true"
                          />
                          Add a new user to my team
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
            {showInviteForm && (
              <div className="mt-4">
                <h4 className="text-sm font-medium text-gray-900 mb-2">
                  Invite New Team Member
                </h4>
                {/* Display error or success messages */}
                {inviteError && (
                  <div className="rounded-md bg-red-50 p-4 mb-2">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationCircleIcon
                          className="h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-red-700">{inviteError}</p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex items-center">
                  <input
                    type="email"
                    className="shadow-sm focus:ring-brand-green focus:border-brand-green block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Enter email address"
                    value={inviteEmail}
                    onChange={(e) => setInviteEmail(e.target.value)}
                  />
                  <button
                    type="button"
                    className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green"
                    onClick={handleInviteTeamMember}
                  >
                    Invite
                  </button>
                </div>
              </div>
            )}

            <button
              type="button"
              className="mt-2 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-green text-base font-medium text-white hover:bg-brand-green-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={handleShareWithSelectedMembers}
              disabled={selectedTeamMembers.length === 0}
            >
              Share with
              {` ${
                selectedTeamMembers.length !== 0
                  ? selectedTeamMembers.length
                  : ""
              } `}
              Selected Members
            </button>
          </div>
        )}

        {user && !user.orgID && (
          <div className="text-center py-6 bg-gray-50 rounded-lg border border-gray-200 px-4">
            <UserGroupIcon className="mx-auto h-12 w-12 text-brand-green" />
            <h3 className="mt-2 text-base font-medium text-gray-900">
              Create a Team for Secure Sharing
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Teams enable secure meeting sharing and easier collaboration.
            </p>
            <div className="mt-4">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-brand-green hover:bg-brand-green-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green"
                onClick={() => {
                  setTriggerTeamModal(true);
                  onClose();
                }}
              >
                <UserAddIcon
                  className="-ml-1 mr-2 h-5 w-5"
                  aria-hidden="true"
                />
                Create Team
              </button>
            </div>
            <p className="mt-3 text-xs text-gray-400">
              Or use the "External" tab to share without a team.
            </p>
          </div>
        )}
      </div>
    ),
    external: (
      <div className="space-y-6">
        <div>
          <h4 className="text-sm font-medium text-gray-900 flex items-center">
            <LinkIcon className="h-5 w-5 text-gray-400 mr-2" />
            Share via Public Link
          </h4>
          <div className="mt-2 bg-gray-50 border border-gray-200 p-4 rounded-md">
            <p className="text-sm text-gray-600">
              Sharing via public link is less secure. We recommend sharing with
              team members or via email for better security.
            </p>
          </div>
          <div className="mt-3">
            {publicLink ? (
              <div className="flex flex-col space-y-3">
                <div className="flex items-center">
                  <input
                    type="text"
                    readOnly
                    value={publicLink}
                    className="flex-grow min-w-0 block w-full px-3 py-2 rounded-md border border-gray-300 text-gray-900 sm:text-sm focus:outline-none focus:ring-brand-green focus:border-brand-green"
                  />
                  <button
                    type="button"
                    className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green"
                    onClick={async () => {
                      try {
                        let linkToCopy = publicLink;
                        if (shareAtCurrentTime) {
                          linkToCopy += `?t=${currentVideoTime}`;
                        }
                        await navigator.clipboard.writeText(linkToCopy);
                        setCopyButtonText("Copied!");
                        setTimeout(() => setCopyButtonText("Copy"), 2000);

                        amplitude
                          .getInstance()
                          .logEvent("user shared meeting via external link", {
                            includeCurrentTime: shareAtCurrentTime,
                          });
                      } catch (err) {
                        console.error("Failed to copy link:", err);
                      }
                    }}
                  >
                    {copyButtonText}
                  </button>
                </div>
                {currentVideoTime !== undefined && (
                  <div className="flex items-center">
                    <input
                      id="share-current-time"
                      name="share-current-time"
                      type="checkbox"
                      className="cursor-pointer h-4 w-4 rounded border-gray-300 text-brand-green focus:ring-brand-green"
                      checked={shareAtCurrentTime}
                      onChange={() =>
                        setShareAtCurrentTime(!shareAtCurrentTime)
                      }
                    />
                    <label
                      htmlFor="share-current-time"
                      className="ml-2 text-sm text-gray-700 cursor-pointer"
                    >
                      Share at current video time
                    </label>
                  </div>
                )}
              </div>
            ) : (
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green"
                onClick={generatePublicLink}
              >
                <LinkIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Generate Public Link
              </button>
            )}
          </div>
          <p className="mt-2 text-sm text-gray-500">
            For a more secure option to share externally,{" "}
            <button
              type="button"
              className="text-brand-green hover:text-brand-green-darker font-medium"
              onClick={() => setShowExternalShare(true)}
            >
              share via email address
            </button>
            .
          </p>
        </div>

        {showExternalShare && (
          <div>
            <h4 className="text-sm font-medium text-gray-900 flex items-center">
              <MailIcon className="h-5 w-5 text-gray-400 mr-2" />
              Share via Email Address
            </h4>
            <div className="mt-2">
              <input
                type="email"
                className="shadow-sm focus:ring-brand-green focus:border-brand-green block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Enter email address"
                value={externalEmail}
                onChange={(e) => setExternalEmail(e.target.value)}
              />
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-green text-base font-medium text-white hover:bg-brand-green-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green sm:text-sm"
                onClick={async () => {
                  if (isValidEmail(externalEmail)) {
                    await handleShareExternalMember(externalEmail);
                    amplitude
                      .getInstance()
                      .logEvent(
                        "user shared meeting via external email address"
                      );
                  }
                }}
                disabled={!isValidEmail(externalEmail)}
              >
                Share
              </button>
            </div>
          </div>
        )}
      </div>
    ),
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-brand-green bg-opacity-10 sm:mx-0 sm:h-10 sm:w-10">
                  <UserGroupIcon
                    className="h-6 w-6 text-brand-green"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Share Meeting
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Share this meeting securely with your team members or
                      external collaborators.
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-5 sm:mt-4">
                <div className="border-b border-gray-200">
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {["team", "external"].map((tab) => (
                      <button
                        key={tab}
                        onClick={() => setActiveTab(tab)}
                        className={`${
                          activeTab === tab
                            ? "border-brand-green text-brand-green"
                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                        } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm capitalize`}
                      >
                        {tab}
                      </button>
                    ))}
                  </nav>
                </div>
                <div className="mt-6 h-[400px] overflow-y-auto">
                  {tabContent[activeTab]}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const getShareLink = async (uuid, userEmail, currentVideoTime = null) => {
  const response = await fetch(
    `https://backend.scribbl.co/enhanced-recording/${uuid}/share`,
    {
      method: "POST",
      body: JSON.stringify({ email: userEmail }),
      credentials: "include",
    }
  );
  const data = await response.json();
  let shareLink = `${window.location.origin}/share/meeting/${data.uuid}`;
  if (currentVideoTime) {
    shareLink += `?t=${currentVideoTime}`;
  }
  return shareLink;
};

const shareRecording = async (uuid, userEmail, shareWithTeam, shareEmails) => {
  await fetch(
    `https://backend.scribbl.co/enhanced-recording/${uuid}/share/update?email=${userEmail}&uuid=${uuid}`,
    {
      method: "POST",
      body: JSON.stringify({
        sharedWithEmails: shareEmails,
        sharedWithTeam: shareWithTeam,
      }),
      credentials: "include",
    }
  );
  amplitude.getInstance().logEvent("er recording shared", {
    recording_uuid: uuid,
    users_shared_with: shareEmails,
    shared_with_team: shareWithTeam,
    number_users_shared_with: shareEmails.length,
  });
};

export default ShareMeetingModal;
