import React from "react";

export const Title = ({ text }) => {
  return <h2 className="text-lg font-semibold">{text}</h2>;
};

// Text Block Component
export const TextBlock = ({ text }) => {
  return <p className="text-gray-700">{text}</p>;
};

// Bullet List Component
export const BulletList = ({ items }) => {
  if (!Array.isArray(items)) {
    console.error("BulletList: items is not an array", items);
    return null;
  }
  return (
    <ul className="list-disc pl-5">
      {items.map((item, index) => (
        <li key={index}>
          {typeof item === "string" ? item : item.text}
          {item.subBullets &&
            Array.isArray(item.subBullets) &&
            item.subBullets.length > 0 && (
              <ul className="list-circle pl-5">
                {item.subBullets.map((subItem, subIndex) => (
                  <li key={subIndex}>
                    {typeof subItem === "string" ? subItem : subItem.text}
                  </li>
                ))}
              </ul>
            )}
        </li>
      ))}
    </ul>
  );
};

// Number List Component
export const NumberList = ({ items }) => {
  return (
    <ol className="list-decimal pl-5">
      {items.map((item, index) => (
        <li key={index}>{item.text}</li>
      ))}
    </ol>
  );
};

// Progress Bar Component
export const ProgressBar = ({ title, percent }) => {
  return (
    <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
      <div
        className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
        style={{ width: `${percent}%` }}
      >
        {title}: {percent}%
      </div>
    </div>
  );
};

// Collapsible List Component
// In GenericComponents.js

export const CollapsibleList = ({ items }) => {
  if (!Array.isArray(items)) {
    console.error("CollapsibleList: items is not an array", items);
    return null;
  }
  return (
    <div>
      {items.map((item, index) => (
        <details key={index} className="mb-2">
          <summary className="font-semibold cursor-pointer">
            {item.title}
          </summary>
          <div className="pl-4">
            {Array.isArray(item.content) ? (
              item.content.map((contentItem, contentIndex) => (
                <div key={contentIndex}>
                  {contentItem.title && (
                    <h3 className="font-semibold">{contentItem.title}</h3>
                  )}
                  {contentItem.content &&
                    Array.isArray(contentItem.content) && (
                      <ul className="list-disc pl-5">
                        {contentItem.content.map((subItem, subIndex) => (
                          <li key={subIndex}>{subItem.text}</li>
                        ))}
                      </ul>
                    )}
                  {typeof contentItem.content === "string" && (
                    <p>{contentItem.content}</p>
                  )}
                </div>
              ))
            ) : (
              <p>{item.content}</p>
            )}
          </div>
        </details>
      ))}
    </div>
  );
};

// Link Component
export const Link = ({ text, url }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-600 hover:underline"
    >
      {text}
    </a>
  );
};

// Code Snippet Component
export const CodeSnippet = ({ language, code }) => {
  return (
    <pre>
      <code className={`language-${language}`}>{code}</code>
    </pre>
  );
};
