import React, { useState, useRef, useEffect } from "react";
import Navbar from "./Navbar";
import Meeting from "./Meeting";
import Recording from "./Recording";
import NotFound from "./NotFound";
import amplitude from "amplitude-js";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import DeletedMeeting from "./DeletedMeeting";

const MeetingRouter = ({ isPublic }) => {
  let siderRef = useRef();
  const navigate = useNavigate();
  const { uuid } = useParams();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const openedFromMeet = query.get("openedFromMeet");

  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [meeting, setMeeting] = useState(null);
  const [pageNotFound, setPageNotFound] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const [triggerTeamModal, setTriggerTeamModal] = useState(false);

  const fetchMeeting = async (uuid, userEmail, setPageNotFound, setLoading) => {
    try {
      const enhancedRecResoponse = await fetch(
        `https://backend.scribbl.co/enhanced-recording/${uuid}?email=${userEmail}&uuid=${uuid}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (enhancedRecResoponse.status === 404) {
        try {
          const data = await enhancedRecResoponse.json();
          if (data?.reason === "deleted") {
            setIsDeleted(true);
          } else {
            setPageNotFound(true);
          }
        } catch (error) {
          // If we can't parse the JSON, it means it's a regular 404 without a body
          setPageNotFound(true);
        }
        setLoading(false);
        return;
      }

      const enhancedRecording = await enhancedRecResoponse.json();
      if (enhancedRecording.isError) {
        setPageNotFound(true);
        setLoading(false);
        alert.error(enhancedRecording.reason);
        return;
      }
      return enhancedRecording;
    } catch (exception) {
      console.log(exception);
      setPageNotFound(true);
      setLoading(false);
      return null;
    }
  };

  useEffect(() => {
    async function getPublicMeeting() {
      // Get the public recording
      let getPublicURL = `https://backend.scribbl.co/enhanced-recording/${uuid}/share`;
      if (userEmail) {
        getPublicURL = `https://backend.scribbl.co/enhanced-recording/${uuid}/share?email=${userEmail}`;
      }

      const getPublicMeetingResp = await fetch(getPublicURL, {
        method: "GET",
        credentials: "include", // This line ensures cookies are sent with the request
      });

      if (getPublicMeetingResp.status === 404) {
        setPageNotFound(true);
        return;
      }

      // we should handle the error case here or it will fail
      try {
        const meeting = await getPublicMeetingResp.json();
        if (userEmail) {
          navigate(`/recording/${meeting.uuid}`);
        } else {
          setMeeting(meeting);
        }

        if (meeting.containsVideo === false) {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    async function getSetMeeting() {
      if (userEmail) {
        const rawMeeting = await fetchMeeting(
          uuid,
          userEmail,
          setPageNotFound,
          setLoading
        );
        if (rawMeeting) {
          // This amplitude event should only be triggered the first time this is hit
          //, because this func can be called elsewhere.
          if (!meeting) {
            var eventProperties = {
              recording_uuid: rawMeeting.uuid,
              role: rawMeeting.role,
              type: rawMeeting.containsVideo ? "recording" : "transcript",
            };
            amplitude
              .getInstance()
              .logEvent("er recording viewed", eventProperties);
          }
          setMeeting(rawMeeting);
        }
        // For meetings with video, there is a setLoading call in the <video>'s onLoadedMetadata handler
        // aka video takes care of setting loading to false
        if (rawMeeting.containsVideo === false) {
          setLoading(false);
        } else if (
          rawMeeting.containsVideo &&
          openedFromMeet &&
          rawMeeting.recordingURL === "" &&
          !isPublic
        ) {
          setTimeout(async () => {
            await getSetMeeting();
          }, 1000);
        } else if (
          rawMeeting.recordingURL === "" &&
          rawMeeting.isProcessing === false
        ) {
          const updatedMeeting = structuredClone(rawMeeting);
          updatedMeeting.containsVideo = false;
          setMeeting(updatedMeeting);
          setLoading(false);
        }
      }
    }

    if (isPublic) {
      getPublicMeeting();
    } else {
      getSetMeeting();
    }
  }, [userEmail]);

  return (
    <Navbar
      setUserEmail={setUserEmail}
      setUser={setUser}
      isPublic={isPublic}
      siderRef={siderRef}
      setLoading={setLoading}
      loading={loading}
      triggerTeamModal={triggerTeamModal}
    >
      {meeting && meeting.containsVideo && (
        <Recording
          isPublic={isPublic}
          user={user}
          userEmail={userEmail}
          setLoading={setLoading}
          recording={meeting}
          setTriggerTeamModal={setTriggerTeamModal}
        ></Recording>
      )}

      {meeting && !meeting.containsVideo && (
        <Meeting
          isPublic={isPublic}
          user={user}
          userEmail={userEmail}
          setLoading={setLoading}
          meeting={meeting}
          setTriggerTeamModal={setTriggerTeamModal}
        ></Meeting>
      )}

      {isDeleted && <DeletedMeeting />}
      {pageNotFound && <NotFound />}
    </Navbar>
  );
};

export default MeetingRouter;
