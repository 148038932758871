export function transcriptSearchChanged(
  searchTerm,
  setSearchResults,
  topics,
  setTopics
) {
  if (searchTerm !== "") {
    let results = [];
    let scrollToFirstItemSet = false;

    topics.forEach((topic) => {
      topic.transcriptItems.forEach((txItem) => {
        txItem.scrollTo = false;
        txItem.currentItem = false;
        txItem.matchesSearchTerm = false;

        if (
          searchTerm &&
          txItem.transcript.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          results.push(txItem);
          txItem.matchesSearchTerm = true;
          if (scrollToFirstItemSet === false) {
            txItem.scrollTo = true;
            scrollToFirstItemSet = true;
          }
        } else {
          txItem.matchesSearchTerm = false;
        }
      });
      topic.open = true;
      if (results.length) {
        results[0].currentItem = true;
        setSearchResults(results);
      } else {
        setSearchResults([]);
      }
    });
  } else {
    let scrollToFirstItemSet = false;
    topics.forEach((topic) => {
      topic.transcriptItems.forEach((txItem) => {
        txItem.scrollTo = false;
        txItem.matchesSearchTerm = false;
        txItem.currentItem = false;

        if (scrollToFirstItemSet === false) {
          txItem.scrollTo = true;
          scrollToFirstItemSet = true;
        }
      });
      topic.open = true;
    });
    setSearchResults([]);
  }
  const newTopics = structuredClone(topics);
  setTopics(newTopics);
}
