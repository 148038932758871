import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import amplitude from "amplitude-js";

import "../index.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const OnboardingJoinTeam = (props) => {
  const navigate = useNavigate();

  const [orgsToJoin, setOrgsToJoin] = useState([]);
  const ONBOARDING_METHOD_CHROME_STORE = 2; // user signed up via chrome store

  useEffect(() => {
    amplitude.getInstance().logEvent("er onboarding join team step");
  }, []);

  useEffect(() => {
    async function fetchOrgList() {
      const orgListResponse = await fetch(
        `https://backend.scribbl.co/orgs/available?email=${props.email}`,
        {
          method: "GET",
          credentials: "include", // This line ensures cookies are sent with the request
        }
      );

      var resp = await orgListResponse.json();
      if (resp.usingEmailProvider) {
        props.setUsingEmailProvider(true);
      } else {
        if (!resp.orgs) {
          props.setHasTeamsToJoin(false);
        } else {
          setOrgsToJoin(resp.orgs);
        }
      }
    }

    fetchOrgList();
  }, []);

  useEffect(() => {
    if (props.usingEmailProvider || !props.hasTeamsToJoin) {
      props.nextStep("createTeam");
    }
  }, [props.usingEmailProvider, props.hasTeamsToJoin]);

  async function joinOrg(orgID) {
    const joinOrgResponse = await fetch(`https://backend.scribbl.co/orgs/join`, {
      method: "POST",
      body: JSON.stringify({
        email: props.email,
        orgID: orgID,
      }),
      credentials: "include", // This line ensures cookies are sent with the request
    });

    var user = await joinOrgResponse.json();
    props.setUser(user);
    amplitude.getInstance().logEvent("er onboarding join team success");
    props.fromCollections
      ? props.nextStep("success")
      : props.nextStep("inviteTeam");
  }

  return (
    <div className="flex py-20">
      <div />
      <div className="grow flex justify-center flex-col items-center">
        <div className="w-[27rem]">
          <span className="text-center font-thin text-4xl text-gray-600">
            We found other teams that you can join!
          </span>

          <div className="pt-8">
            {orgsToJoin.map((org) => (
              <div className="flex justify-center" key={org.ID}>
                <div className="block bg-gray-50 p-2.5 mb-2 w-full rounded relative">
                  <span className="font-semibold text-gray-600">
                    {org.name}
                  </span>
                  <br></br>
                  <span className="text-sm text-gray-500">
                    {org.memberCount} members
                  </span>
                  <button
                    type="button"
                    onClick={() => joinOrg(org.ID)}
                    className="absolute top-0 right-0 mt-5 mr-2 inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-brand-green-lighter1 hover:bg-brand-green-darker2 focus:outline-none"
                  >
                    Join
                  </button>
                </div>
              </div>
            ))}
            <div className="mt-8">
              <button
                type="button"
                onClick={() => props.nextStep("createTeam")}
                className="w-full  text-center items-center py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Create a new team
              </button>
              {props.disableSkip ? (
                <></>
              ) : (
                <div className="text-right text-sm mt-2 text-gray-600 cursor-pointer hover:text-gray-800">
                  <a
                    href="#"
                    onClick={() => {
                      amplitude
                        .getInstance()
                        .logEvent("er onboarding join team skip");
                      if (
                        props.onboardingMethod &&
                        props.onboardingMethod ===
                          ONBOARDING_METHOD_CHROME_STORE
                      ) {
                        props.nextStep("tryInMeet");
                      } else {
                        props.nextStep("downloadExtension");
                      }
                    }}
                  >
                    Continue with no team for now
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div />
    </div>
  );
};

export default OnboardingJoinTeam;
