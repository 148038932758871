import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import amplitude from "amplitude-js";

import "../index.css";

const OnboardingProfile = (props) => {
  const [companyType, setCompanyType] = useState("");
  const [companyTypeOther, setCompanyTypeOther] = useState("");
  const [role, setRole] = useState("");
  const [seniority, setSeniority] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    amplitude.getInstance().logEvent("er onboarding download extension step");
  }, []);

  function saveProperties() {
    if (
      companyType === "" ||
      role === "" ||
      seniority === "" ||
      companySize === ""
    ) {
      setError("Please answer all questions before proceeding.");
      return;
    }

    if (companyType === "6" && companyTypeOther === "") {
      setError("Please answer all questions before proceeding.");
      return;
    }

    let propCompanyType = parseInt(companyType);
    let propSeniority = parseInt(seniority);
    let propCompanySize = parseInt(companySize);
    savePropertiesRequest(
      propCompanyType,
      companyTypeOther,
      role,
      propSeniority,
      propCompanySize
    );
  }

  async function savePropertiesRequest(
    propCompanyType,
    propCompanyTypeOther,
    propRole,
    propSeniority,
    propCompanySize
  ) {
    let attributes = {
      email: props.email,
      companyType: propCompanyType,
      companyTypeOther: propCompanyTypeOther,
      role: propRole,
      seniority: propSeniority,
      companySize: propCompanySize,
    };

    const createProfileResponse = await fetch(
      `https://backend.scribbl.co/user-profile`,
      {
        method: "POST",
        body: JSON.stringify(attributes),
        credentials: "include", // This line ensures cookies are sent with the request
      }
    );

    await createProfileResponse.json();
    amplitude
      .getInstance()
      .logEvent("er onboarding profile success", attributes);
    navigateToOnboardingStep();
  }

  function navigateToOnboardingStep() {
    console.log("navigation props", props);
    if (props.orgObj) {
      props.nextStep("inviteTeam");
    } else {
      props.nextStep("joinTeam");
    }
  }

  return (
    <div className="flex">
      <div className="grow flex justify-center flex-col items-center">
        <div className="w-[27rem]">
          <span className="text-center font-thin text-4xl text-gray-600">
            A few questions to help customize your experience.
          </span>
          <div className="mt-2">
            {error && (
              <div className="mt-4 block text-sm font-medium leading-6 text-red-500">
                {error}
              </div>
            )}
            <div className="mt-4">
              <label
                htmlFor="companyType"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                What type of company do you work for?
              </label>
              <select
                id="companyType"
                name="companyType"
                value={companyType}
                onChange={(e) => setCompanyType(e.target.value)}
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  sm:text-sm sm:leading-6"
                defaultValue=""
              >
                <option></option>
                <option value="0">
                  Agency (i.e. Dev shop, SF consultancy, PR firm, etc.)
                </option>
                <option value="1">Startup</option>
                <option value="2">Enterprise</option>
                <option value="3">Nonprofit</option>
                <option value="4">Education</option>
                <option value="5">Government</option>
                <option value="6">Other</option>
              </select>
            </div>
            {companyType === "6" && (
              <div className="mt-4">
                <label
                  htmlFor="companyTypeOther"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  You listed other for company type. Please specify.
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="companyTypeOther"
                    id="companyTypeOther"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={companyTypeOther}
                    onChange={(e) => setCompanyTypeOther(e.target.value)}
                  />
                </div>
              </div>
            )}
            <div className="mt-4">
              <label
                htmlFor="role"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                What's your role?
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="role"
                  id="role"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Project Manager"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-4">
              <label
                htmlFor="seniority"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                How would you describe your seniority?
              </label>
              <select
                id="seniority"
                name="seniority"
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  sm:text-sm sm:leading-6"
                defaultValue=""
                value={seniority}
                onChange={(e) => setSeniority(e.target.value)}
              >
                <option></option>
                <option value="0">Individual Contributor</option>
                <option value="1">Manager</option>
                <option value="2">Executive or Owner</option>
              </select>
            </div>
            <div className="mt-4">
              <label
                htmlFor="companySize"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                How many people work at your company or organization?
              </label>
              <select
                id="companySize"
                name="companySize"
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  sm:text-sm sm:leading-6"
                defaultValue=""
                value={companySize}
                onChange={(e) => setCompanySize(e.target.value)}
              >
                <option></option>
                <option value="0">1 - 5 employees</option>
                <option value="1">5 - 10 employees</option>
                <option value="2">10 - 20 employees</option>
                <option value="3">20 - 50 employees</option>
                <option value="4">50 - 100 employees</option>
                <option value="5">100 - 500 employees</option>
                <option value="6">500 - 1000 employees</option>
                <option value="7">1000+ employees</option>
              </select>
            </div>
          </div>
          <div className="mt-6 text-right">
            <button
              onClick={() => {
                saveProperties();
              }}
              type="button"
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker2 focus:outline-none"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingProfile;
