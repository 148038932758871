import React, { useEffect } from "react";
import amplitude from "amplitude-js";

const OnboardingIframe = () => {
  // The sole purpose of this component is to be able to pass local storage back to the
  // scribbl-extension-sign-in repo because it doesn't exist on the same domain as the
  // app so it can't read from local storage.

  useEffect(() => {
    var fromOnboardingVal = localStorage.getItem("fromOnboarding");
    if (!fromOnboardingVal) {
      fromOnboardingVal = false;
    } else {
      amplitude.getInstance().logEvent("er onboarding iframe step");
    }
    var jsonObj = JSON.stringify({ fromOnboarding: fromOnboardingVal });
    var parent = window.parent;
    parent.postMessage(jsonObj, "*");
  }, []);

  return <div>Onboarding Iframe</div>;
};

export default OnboardingIframe;
