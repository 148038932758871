import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@material-tailwind/react";
import { GoogleOAuthProvider } from "@react-oauth/google";

createRoot(document.getElementById("root")).render(
  <ThemeProvider>
    <GoogleOAuthProvider clientId="296427734486-15b04okhajnqqrdvau8plml5ru5qvnns.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </ThemeProvider>
);
