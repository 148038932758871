import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_live_RbyYFBGfLGhcINXV8lX91QJB001ulsAc9i");
// const stripePromise = loadStripe("pk_test_XSkknx7URDZSHH1oMJUpLkVg00DlIUgbCb"); // DEV

const billingCycleOptions = [
  {
    key: 0,
    name: "Annually",
    price: 156,
    label: "Annually $13 /user /month",
    priceID: "price_1NvrCsFFY57ee5ETyeIZPTiQ",
    // priceID: "price_1NvrRRFFY57ee5ETLnJiBQhA", // DEV
  },
  {
    key: 1,
    name: "Monthly",
    price: 20,
    label: "Monthly $20 /user /month",
    priceID: "price_1Nw9BBFFY57ee5ETn7NZ6aCL",
    // priceID: "price_1Ibau6FFY57ee5ETPpKDnCVS", // DEV,
  },
];

const TeamPricing = (props) => {
  const [quantity, setQuantity] = useState(1);
  const [billingCycle, setBillingCycle] = useState(billingCycleOptions[0]);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    calculatePrice();
  }, [quantity, billingCycle]);

  function calculatePrice() {
    let unitPrice = billingCycle.price;
    setPrice(unitPrice * quantity);
  }

  async function initiateStripeCheckout() {
    if (props.userEmail) {
      let priceID = billingCycle.priceID;
      const stripe = await stripePromise;

      let checkoutParams = {
        priceId: priceID,
        quantity: parseInt(quantity),
        clientReferenceId: "",
      };

      if (window.Rewardful && window.Rewardful.referral) {
        checkoutParams.clientReferenceId = window.Rewardful.referral;
      }

      const response = await fetch("https://backend.scribbl.co/checkout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(checkoutParams),
        credentials: "include", // This line ensures cookies are sent with the request
      });

      const session = await response.json();
      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });

      if (result.error) {
        // Handle any errors that occurred during redirection
        console.error(result.error.message);
      }
    } else {
      console.error("userEmail is null");
    }
  }

  function calculateTotalPrice() {
    return price;
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex w-full h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden bg-gray-50 px-4 pb-4 pt-5 text-left transition-all sm:my-8 h-full w-full sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md text-gray-700 hover:text-gray-500"
                    onClick={() => props.setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <div className="grid grid-cols-5 gap-4 ">
                  <div className="col-start-2 col-span-2 bg-white rounded-md border border-gray-200 p-3 text-gray-700">
                    <div className="text-xl">Upgrade My Team</div>
                    <div className="mt-1 text-sm text-gray-700">
                      Purchase pro licenses and assign them to any member of
                      your team.
                    </div>
                    <div className="grid grid-cols-2 gap-4 mt-8">
                      <div>
                        <label
                          htmlFor="location"
                          className="block text-sm font-medium leading-6 text-gray-700"
                        >
                          Billing Cycle
                        </label>
                        <select
                          value={billingCycle.key}
                          onChange={(event) => {
                            let bc = billingCycleOptions.find(
                              (option) =>
                                option.key === parseInt(event.target.value)
                            );
                            setBillingCycle(bc);
                          }}
                          id="location"
                          name="location"
                          className="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                        >
                          {billingCycleOptions.map((option) => {
                            return (
                              <option value={option.key}>{option.label}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div>
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium leading-6 text-gray-700"
                          >
                            Quantity
                          </label>
                          <div className="mt-1">
                            <input
                              value={quantity}
                              onChange={(event) =>
                                setQuantity(event.target.value)
                              }
                              type="number"
                              name="quantity"
                              min="1"
                              id="quantity"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                              placeholder="1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      <div className="bg-gray-100 p-6 text-sm rounded-md mt-8">
                        If you’re exploring upgrading more than just a few users
                        on your team{" "}
                        <a
                          href="https://www.scribbl.co/pages/contact"
                          target="_blank"
                          className="text-brand-green font-semibold"
                        >
                          contact us
                        </a>{" "}
                        for team plans and potential volume discounts.
                      </div>
                    }
                    <div className="p-5">
                      <div className="font-semibold mt-8">
                        Pro users will get:
                      </div>
                      <ul className="mt-4 space-y-3 text-sm leading-6 text-gray-600">
                        <li className="flex gap-x-3">
                          <CheckIcon
                            className="h-6 w-5 flex-none text-brand-green"
                            aria-hidden="true"
                          />
                          Unlimited meetings per month
                        </li>
                        <li className="flex gap-x-3">
                          <CheckIcon
                            className="h-6 w-5 flex-none text-brand-green"
                            aria-hidden="true"
                          />
                          Unlimited meeting length
                        </li>
                        <li className="flex gap-x-3">
                          <CheckIcon
                            className="h-6 w-5 flex-none text-brand-green"
                            aria-hidden="true"
                          />
                          AI generated notes and action items
                        </li>
                        <li className="flex gap-x-3">
                          <CheckIcon
                            className="h-6 w-5 flex-none text-brand-green"
                            aria-hidden="true"
                          />
                          Video recorings expire after 1 year
                        </li>
                        <li className="flex gap-x-3">
                          <CheckIcon
                            className="h-6 w-5 flex-none text-brand-green"
                            aria-hidden="true"
                          />
                          Up to 500 GBs of storage per team
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <div className="bg-white rounded-md border border-gray-200 p-3 text-gray-700 text-sm">
                      <div className="font-bold">Scribbl Pro Plan:</div>
                      <div className="mt-4">{quantity} Seats</div>
                      <div className="mt-4">Billed {billingCycle.name}</div>
                      <div className="border-t border-gray-100 mt-4"></div>
                      <div className="mt-4">
                        {billingCycle.key === 0 ? (
                          <span>Every 1 Year</span>
                        ) : (
                          <span>Every 1 Month</span>
                        )}
                        <span className="float-right">${price}</span>
                      </div>
                      <div className="mt-4 font-bold">
                        <span>Amount Due Today</span>
                        <span className="float-right">
                          ${calculateTotalPrice()}
                        </span>
                      </div>
                      <button
                        onClick={() => {
                          initiateStripeCheckout();
                        }}
                        type="button"
                        className="rounded-md mt-4 w-full bg-brand-green-lighter1 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-green"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default TeamPricing;
