import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ChatAlt2Icon, ArrowRightIcon } from "@heroicons/react/outline";
import amplitude from "amplitude-js";

const AIChat = ({
  uuid,
  userEmail,
  initialAIChat,
  maxHeight,
  isProUser,
  isCollection,
  updateAIChat,
}) => {
  const [userMessage, setUserMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [responseCount, setResponseCount] = useState(0);
  const endOfMessagesRef = useRef(null);

  useEffect(() => {
    if (initialAIChat) {
      try {
        const initialChat = JSON.parse(initialAIChat);
        setChatHistory(
          initialChat.filter(
            (chat) => chat.role === "assistant" || chat.role === "user"
          )
        );
        setResponseCount(
          initialChat.filter((chat) => chat.role === "assistant").length
        );

        console.log(initialAIChat);
      } catch (error) {
        console.error("Error parsing initial AI chat data:", error);
      }
    }
  }, [initialAIChat]);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatHistory]);

  const sendMessage = async () => {
    if (!userMessage.trim() || (!isProUser && responseCount >= 1)) return;

    setIsLoading(true);
    const newMessage = { role: "user", content: userMessage };
    setChatHistory((prevChat) => [...prevChat, newMessage]);

    try {
      const endpoint = isCollection
        ? `https://backend.scribbl.co/collections/${uuid}/aichat`
        : `https://backend.scribbl.co/enhanced-recording/${uuid}/aichat`;

      const response = await fetch(endpoint, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userMessage }),
      });

      if (response.ok) {
        const { aiChat } = await response.json();
        const aiChatParsed = JSON.parse(aiChat);

        setChatHistory(
          aiChatParsed.filter(
            (chat) => chat.role === "assistant" || chat.role === "user"
          )
        );
        setResponseCount(
          aiChatParsed.filter((chat) => chat.role === "assistant").length
        );

        updateAIChat && updateAIChat(aiChatParsed);
        amplitude
          .getInstance()
          .logEvent("AI chat response", { isCollection: isCollection });
      } else {
        console.error("Failed to send message");
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setIsLoading(false);
    setUserMessage("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !isLoading) {
      sendMessage();
    }
  };

  return (
    <div
      style={{ maxHeight: maxHeight, minHeight: maxHeight }}
      className="text-sm flex flex-col h-full bg-gray-50 shadow-xl rounded-lg"
    >
      <div className="flex flex-col flex-1 overflow-y-auto p-4 space-y-4">
        {chatHistory.length === 0 && !isLoading ? (
          <div className="flex-1 flex flex-col items-center justify-center">
            <ChatAlt2Icon className="text-brand-turq mb-4 h-12 w-12" />
            <h3 className="text-lg font-semibold text-gray-700">
              Ask AI About Your {isCollection ? "Collection" : "Meeting"}
            </h3>
            <p className="text-gray-500 text-center">
              Ask our AI Copilot any questions{" "}
              {isCollection
                ? "across the meetings in your collection "
                : "about your meeting "}
              and get insights on specific topics. Just type your question below
              and get instant answers.
            </p>
          </div>
        ) : (
          chatHistory.map((chat, index) => (
            <div
              key={index}
              className={classNames("rounded-lg p-4 shadow", {
                "bg-[#d6ebeb] border-l-4 border-brand-green":
                  chat.role === "user",
                "bg-gray-100 border-l-4 border-gray-500":
                  chat.role === "assistant",
              })}
            >
              <div className="text-sm font-semibold mb-1">
                {chat.role === "user" ? userEmail : "AI"}:
              </div>
              <div
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "break-spaces",
                }}
                className="text-gray-700 pl-2  whitespace-break-spaces"
              >
                <Markdown
                  style={{
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    whiteSpace: "break-spaces",
                  }}
                  className="markdown-style"
                  remarkPlugins={[remarkGfm]}
                >
                  {chat.content}
                </Markdown>
              </div>
            </div>
          ))
        )}
        {isLoading && (
          <div className="flex justify-center items-center my-2">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-green"></div>
          </div>
        )}
        <div ref={endOfMessagesRef} />
      </div>

      <div className="p-4 bg-white border-t border-brand-gray-lighter3">
        {!isProUser && responseCount >= 1 && (
          <div className="mt-4 px-4 py-3 rounded-lg bg-brand-red bg-opacity-10 border border-brand-red border-opacity-60">
            <div className="flex flex-col justify-between items-center">
              <div>
                <h4 className="text-brand-red font-semibold">
                  Free Limit Reached - 1 AI Response per{" "}
                  {isCollection ? "Collection" : "Meeting"}
                </h4>
                <p className="mt-2 text-sm text-gray-600">
                  You've reached your free plan limit of one AI response per
                  {isCollection ? " collection" : " meeting"}. For unlimited
                  responses, please upgrade to our Pro Plan.
                </p>
              </div>
              <button
                className="flex w-full mt-[16px] justify-center items-center bg-brand-turq text-white rounded-md shadow px-4 py-2 hover:bg-brand-green-lighter1 transition-colors duration-200 ease-in-out"
                onClick={() => {
                  amplitude
                    .getInstance()
                    .logEvent("pricing button clicked from AI chat limit");
                  window.open("/pricing", "_blank").focus();
                }}
              >
                <span className="text-sm font-medium mr-2">Upgrade to Pro</span>
                <ArrowRightIcon className="h-4 w-4" />
              </button>
            </div>
          </div>
        )}
        {(isProUser === true || responseCount < 1) && (
          <div className="flex space-x-2">
            <input
              type="text"
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder={`Ask something about the ${
                isCollection ? "meetings in this collection" : "meeting"
              }...`}
              className="flex-1 placeholder:text-sm p-2 border border-brand-gray-lighter2 rounded-lg focus:ring-brand-turq focus:border-brand-turq"
              disabled={isLoading || (!isProUser && responseCount >= 1)}
              style={{ backgroundColor: isLoading ? "#f0f0f0" : "white" }}
            />
            <button
              onClick={sendMessage}
              className="px-6 py-2 bg-brand-turq text-white rounded-lg hover:bg-brand-green-lighter1 focus:outline-none focus:ring-2 focus:ring-brand-turq focus:ring-opacity-50"
              disabled={isLoading || (!isProUser && responseCount >= 1)}
              style={{
                opacity:
                  isLoading || (!isProUser && responseCount >= 1) ? 0.6 : 1,
                cursor:
                  isLoading || (!isProUser && responseCount >= 1)
                    ? "not-allowed"
                    : "pointer",
              }}
            >
              Send
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AIChat;
