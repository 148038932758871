import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import amplitude from "amplitude-js";
import {
  LockClosedIcon,
  ChartBarIcon,
  ChevronRightIcon,
  CogIcon,
  CollectionIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";

const Integrations = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const [integrations, setIntegrations] = useState([
    {
      name: "Hubspot",
      description: "Connect your CRM and automate your sales processes.",
      status: "not_setup",
    },
    {
      name: "Pipedrive",
      description: "Sync meetings and notes with your Pipedrive CRM.",
      status: "not_setup",
    },
    {
      name: "Slack",
      description: "Streamline your team communication and notifications.",
      status: "not_setup",
    },
    {
      name: "Google Drive",
      description: "Sync your meeting notes and documents with Google Drive.",
      status: "not_setup",
    },
  ]);

  useEffect(() => {
    fetchIntegrationStatuses();
  }, []);

  const fetchIntegrationStatuses = async () => {
    try {
      const response = await fetch(
        "https://backend.scribbl.co/integrations/status",
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setIntegrations([
          {
            name: "Hubspot",
            description: "Connect your CRM and automate your sales processes.",
            status: data.hubspot,
          },
          {
            name: "Pipedrive",
            description: "Sync meetings and notes with your Pipedrive CRM.",
            status: data.pipedrive,
          },
          {
            name: "Slack",
            description:
              "Streamline your team communication and notifications.",
            status: data.slack,
          },
          {
            name: "Google Drive",
            description:
              "Sync your meeting notes and documents with Google Drive.",
            status: data.drive,
          },
        ]);
      }
    } catch (error) {
      console.error("Error fetching integration statuses:", error);
    } finally {
      console.log("setting loading to false");
    }
  };

  useEffect(() => {
    if (user && integrations) {
      setLoading(false);

      amplitude.getInstance().logEvent("Integrations tab visited");
    }
  }, [user, integrations]);

  const getStatusColor = (status) => {
    switch (status) {
      case "connected":
        return "bg-green-100 text-green-800 border-green-200";
      case "not_setup":
        return "bg-gray-100 text-gray-800 border-gray-200";
      default:
        return "bg-yellow-100 text-yellow-800 border-yellow-200";
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case "connected":
        return "Connected";
      case "not_setup":
        return "Set up";
      default:
        return "Configure";
    }
  };

  return (
    <Navbar setUser={setUser} loading={loading}>
      {!user?.subscription ||
      (user.subscription.accountType !== 3 &&
        user.subscription.accountType !== 4) ? (
        <GatedIntegrations user={user} />
      ) : (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">
            Integrations
          </h1>
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-8">
              <h2 className="text-2xl font-semibold text-gray-900 mb-6">
                Available Integrations
              </h2>
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                {integrations.map((integration) => (
                  <Link
                    key={integration.name}
                    to={`/integrations/${integration.name
                      .split(" ")
                      .join("-")
                      .toLowerCase()}`}
                    className="block group"
                  >
                    <div className="border border-gray-200 rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 h-full flex flex-col justify-between">
                      <div>
                        <div className="flex items-center justify-between mb-4">
                          <div className="w-32 h-16 flex items-center justify-center">
                            {integration.name === "Hubspot" && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24.576 12.28"
                                className="w-full h-full"
                              >
                                <path
                                  d="M.744 4.04h.805v1.79h1.698V4.04h.806v4.4h-.806V6.605H1.55V8.43H.744zM6.604 7a.67.67 0 0 1-.667.667.67.67 0 0 1-.667-.667v-1.9h-.763V7a1.43 1.43 0 0 0 2.86 0v-1.9h-.763V7m2.895-1.868a1.17 1.17 0 0 0-.898.36V4.04h-.766v2.692c0 1.008.73 1.698 1.547 1.698.9 0 1.707-.703 1.707-1.65 0-.935-.735-1.65-1.6-1.65zm.02 2.6c-.52 0-.916-.433-.916-.94s.397-.94.916-.94c.44 0 .837.434.837.94s-.397.94-.837.94zm2.858-2.397c0-.385.257-.507.537-.507.226 0 .525.17.72.38l.5-.586c-.25-.336-.757-.568-1.172-.568-.83 0-1.43.483-1.43 1.283 0 1.484 1.826 1.014 1.826 1.845 0 .256-.25.482-.537.482-.452 0-.6-.22-.806-.452l-.556.574c.354.434.794.654 1.32.654.788 0 1.423-.49 1.423-1.252 0-1.65-1.826-1.136-1.826-1.85m3.843-.236c-.82 0-1.547.7-1.547 1.698v2.7h.766v-1.46c.255.25.52.36.898.36.855 0 1.6-.715 1.6-1.65 0-.947-.798-1.65-1.708-1.65zm.136 2.6c-.52 0-.916-.434-.916-.94s.397-.94.916-.94c.44 0 .837.434.837.94s-.397.94-.837.94zm7.21.043c-.452 0-.58-.195-.58-.495V5.903h.702V5.23h-.702v-.886l-.775.348v2.7c0 .7.476 1.038 1.13 1.038a1.57 1.57 0 0 0 .306-.024l.19-.696-.27.012"
                                  fill="#45535e"
                                />
                                <path
                                  d="M21.543 5.942c-.147-.253-.353-.455-.612-.608a1.64 1.64 0 0 0-.619-.214v-.803a.59.59 0 0 0 .365-.555c0-.337-.27-.6-.607-.6a.61.61 0 0 0-.612.61c0 .247.132.46.357.555v.803c-.18.026-.367.082-.538.17l-2.162-1.642c.016-.057.028-.116.028-.178 0-.373-.302-.676-.676-.676s-.675.303-.675.676.302.676.676.676a.67.67 0 0 0 .347-.098l.14.107 1.94 1.398c-.103.094-.198.2-.275.322a1.5 1.5 0 0 0-.25.809v.06c0 .206.04.4.106.585.06.16.145.305.25.437l-.644.646a.52.52 0 0 0-.701.49.52.52 0 0 0 .522.522.52.52 0 0 0 .522-.522c0-.054-.008-.107-.024-.157l.666-.666a1.71 1.71 0 0 0 .296.162 1.73 1.73 0 0 0 .692.145h.046a1.63 1.63 0 0 0 .79-.2c.26-.142.465-.336.62-.583a1.53 1.53 0 0 0 .24-.824v-.015c0-.297-.07-.57-.213-.82zM20.73 7.34c-.18.2-.388.325-.623.325h-.04c-.134 0-.265-.037-.394-.104a.91.91 0 0 1-.345-.314c-.093-.132-.144-.275-.144-.428V6.77c0-.15.03-.292.1-.426a.92.92 0 0 1 .321-.351c.137-.1.282-.134.445-.134h.015a.9.9 0 0 1 .418.096c.134.072.244.17.33.3a.95.95 0 0 1 .147.42l.004.095a.83.83 0 0 1-.236.569z"
                                  fill="#f8761f"
                                />
                              </svg>
                            )}

                            {integration.name === "Slack" && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 498 127"
                                className="w-full h-full"
                              >
                                <g fill="none">
                                  <path
                                    d="M159.5 99.5l6.2-14.4c6.7 5 15.6 7.6 24.4 7.6 6.5 0 10.6-2.5 10.6-6.3-.1-10.6-38.9-2.3-39.2-28.9-.1-13.5 11.9-23.9 28.9-23.9 10.1 0 20.2 2.5 27.4 8.2L212 56.5c-6.6-4.2-14.8-7.2-22.6-7.2-5.3 0-8.8 2.5-8.8 5.7.1 10.4 39.2 4.7 39.6 30.1 0 13.8-11.7 23.5-28.5 23.5-12.3 0-23.6-2.9-32.2-9.1m237.9-19.6c-3.1 5.4-8.9 9.1-15.6 9.1-9.9 0-17.9-8-17.9-17.9 0-9.9 8-17.9 17.9-17.9 6.7 0 12.5 3.7 15.6 9.1l17.1-9.5c-6.4-11.4-18.7-19.2-32.7-19.2-20.7 0-37.5 16.8-37.5 37.5s16.8 37.5 37.5 37.5c14.1 0 26.3-7.7 32.7-19.2l-17.1-9.5zM228.8 2.5h21.4v104.7h-21.4zm194.1 0v104.7h21.4V75.8l25.4 31.4h27.4l-32.3-37.3 29.9-34.8h-26.2L444.3 64V2.5zM313.8 80.1c-3.1 5.1-9.5 8.9-16.7 8.9-9.9 0-17.9-8-17.9-17.9 0-9.9 8-17.9 17.9-17.9 7.2 0 13.6 4 16.7 9.2v17.7zm0-45v8.5c-3.5-5.9-12.2-10-21.3-10-18.8 0-33.6 16.6-33.6 37.4 0 20.8 14.8 37.6 33.6 37.6 9.1 0 17.8-4.1 21.3-10v8.5h21.4v-72h-21.4z"
                                    fill="#000"
                                  />
                                  <path
                                    d="M27.2 80c0 7.3-5.9 13.2-13.2 13.2C6.7 93.2.8 87.3.8 80c0-7.3 5.9-13.2 13.2-13.2h13.2V80zm6.6 0c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2v33c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V80z"
                                    fill="#E01E5A"
                                  />
                                  <path
                                    d="M47 27c-7.3 0-13.2-5.9-13.2-13.2C33.8 6.5 39.7.6 47 .6c7.3 0 13.2 5.9 13.2 13.2V27H47zm0 6.7c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H13.9C6.6 60.1.7 54.2.7 46.9c0-7.3 5.9-13.2 13.2-13.2H47z"
                                    fill="#36C5F0"
                                  />
                                  <path
                                    d="M99.9 46.9c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H99.9V46.9zm-6.6 0c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V13.8C66.9 6.5 72.8.6 80.1.6c7.3 0 13.2 5.9 13.2 13.2v33.1z"
                                    fill="#2EB67D"
                                  />
                                  <path
                                    d="M80.1 99.8c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V99.8h13.2zm0-6.6c-7.3 0-13.2-5.9-13.2-13.2 0-7.3 5.9-13.2 13.2-13.2h33.1c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H80.1z"
                                    fill="#ECB22E"
                                  />
                                </g>
                              </svg>
                            )}

                            {integration.name === "Google Drive" && (
                              <svg
                                className="w-full h-full"
                                xmlns="http://www.w3.org/2000/svg"
                                width="2500"
                                height="2166"
                                viewBox="0 0 1443.061 1249.993"
                              >
                                <path
                                  fill="#3777e3"
                                  d="M240.525 1249.993l240.492-416.664h962.044l-240.514 416.664z"
                                />
                                <path
                                  fill="#ffcf63"
                                  d="M962.055 833.329h481.006L962.055 0H481.017z"
                                />
                                <path
                                  fill="#11a861"
                                  d="M0 833.329l240.525 416.664 481.006-833.328L481.017 0z"
                                />
                              </svg>
                            )}

                            {integration.name === "Pipedrive" && (
                              <svg
                                className="w-full h-full"
                                width="1000"
                                height="274"
                                viewBox="0 0 1000 274"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_4_435)">
                                  <path
                                    d="M191.44 72.37C201.398 72.37 209.47 64.2977 209.47 54.34C209.47 44.3823 201.398 36.31 191.44 36.31C181.482 36.31 173.41 44.3823 173.41 54.34C173.41 64.2977 181.482 72.37 191.44 72.37Z"
                                    fill="#017737"
                                  />
                                  <path
                                    d="M207.63 81.4H175.26V192.46H207.63V81.4Z"
                                    fill="#017737"
                                  />
                                  <path
                                    d="M712.37 72.37C722.328 72.37 730.4 64.2977 730.4 54.34C730.4 44.3823 722.328 36.31 712.37 36.31C702.412 36.31 694.34 44.3823 694.34 54.34C694.34 64.2977 702.412 72.37 712.37 72.37Z"
                                    fill="#017737"
                                  />
                                  <path
                                    d="M728.56 81.4H696.19V192.46H728.56V81.4Z"
                                    fill="#017737"
                                  />
                                  <path
                                    d="M963.96 133.27C963.96 101.57 938.69 78.96 908.32 78.96C874.18 78.96 849.35 102.68 849.35 136.82C849.35 170.52 872.63 194.68 908.32 194.68C938.69 194.68 958.86 175.84 962.63 155H931.37C927.82 164.09 917.4 169.19 907.65 169.19C894.57 168.97 884.82 161.21 881.94 144.36H963.74L963.96 133.27ZM882.6 124.18C886.15 107.78 898.34 103.79 908.31 103.79C918.51 103.79 930.48 108.89 932.03 124.18H882.6Z"
                                    fill="#017737"
                                  />
                                  <path
                                    d="M825.41 81.4H858.66L813.43 192.46H779.3L734.07 81.4H768.88L797.03 154.99L825.41 81.4Z"
                                    fill="#017737"
                                  />
                                  <path
                                    d="M647.4 99.13C654.94 87.38 666.91 79.84 680.65 79.84C683.09 79.84 686.19 80.06 688.19 80.73V111.1C685.53 110.66 682.65 110.44 679.99 110.44C659.15 110.44 648.29 125.74 648.29 147.68V192.46H616.15V110.66C616.15 108.89 615.48 108.44 613.49 108.44H602.4V81.4H630.33C641.64 81.4 647.4 86.72 647.4 96.7V99.13Z"
                                    fill="#017737"
                                  />
                                  <path
                                    d="M596.63 165.41C594.86 165.41 594.41 164.97 594.41 163.19V52.36C594.41 41.28 588.65 36.18 576.9 36.18H548.75V63H559.17C561.16 63 561.83 63.66 561.83 65.44V91.6C557.62 86.06 545.87 78.96 530.13 78.96C498.87 78.96 476.04 102.68 476.04 136.82C476.04 171.62 497.99 194.68 529.24 194.68C546.75 194.68 559.17 185.81 563.82 178.5C564.26 183.38 567.59 192.47 579.56 192.47H608.16V165.43H596.63V165.41ZM535.89 168.07C520.37 168.07 509.07 156.1 509.07 136.81C509.07 118.41 520.6 106 536.11 106C553.62 106 562.49 121.52 562.49 136.59C562.49 160.09 548.31 168.07 535.89 168.07Z"
                                    fill="#017737"
                                  />
                                  <path
                                    d="M468.94 133.27C468.94 101.57 443.67 78.96 413.3 78.96C379.16 78.96 354.33 102.68 354.33 136.82C354.33 170.52 377.61 194.68 413.3 194.68C443.67 194.68 463.84 175.84 467.61 155H436.35C432.8 164.09 422.38 169.19 412.63 169.19C399.55 168.97 389.8 161.21 386.92 144.36H468.72L468.94 133.27ZM387.59 124.18C391.14 107.78 403.33 103.79 413.3 103.79C423.5 103.79 435.47 108.89 437.02 124.18H387.59Z"
                                    fill="#017737"
                                  />
                                  <path
                                    d="M294.93 78.96C276.75 78.96 263.89 88.49 259.02 96.69C258.13 92.03 255.03 81.39 242.62 81.39H215.58V108.44H226.66C228.65 108.44 229.1 108.88 229.1 110.66V237.9H261.47V188.69L261.25 180.71C265.9 188.47 278.32 194.68 293.39 194.68C325.09 194.68 347.26 171.18 347.26 136.82C347.24 102.23 326.18 78.96 294.93 78.96ZM286.72 168.07C269.21 168.07 260.78 152.33 260.78 137.26C260.78 114.21 274.75 106 287.16 106C302.23 106 314.2 118.64 314.2 137.03C314.21 158.76 299.58 168.07 286.72 168.07Z"
                                    fill="#017737"
                                  />
                                  <path
                                    d="M167.68 136.82C167.68 171.18 145.51 194.68 113.81 194.68C98.74 194.68 86.32 188.47 81.67 180.71L81.89 188.69V237.9H49.53V110.66C49.53 108.89 49.09 108.44 47.09 108.44H36V81.4H63.05C75.46 81.4 78.57 92.04 79.45 96.7C84.33 88.5 97.18 78.97 115.36 78.97C146.62 78.96 167.68 102.23 167.68 136.82ZM134.65 137.04C134.65 118.64 122.68 106.01 107.6 106.01C95.19 106.01 81.22 114.21 81.22 137.27C81.22 152.34 89.64 168.08 107.16 168.08C120.02 168.07 134.65 158.76 134.65 137.04Z"
                                    fill="#017737"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_4_435">
                                    <rect
                                      width="1000"
                                      height="274"
                                      fill="white"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            )}
                          </div>
                        </div>
                        <h3 className="text-xl font-medium text-gray-900 group-hover:text-indigo-600 transition-colors duration-200 mb-2">
                          {integration.name}
                        </h3>
                        <p className="text-sm text-gray-600 mb-4">
                          {integration.description}
                        </p>
                      </div>
                      <div>
                        <span
                          className={`inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-full border ${getStatusColor(
                            integration.status
                          )}`}
                        >
                          <span className="w-2 h-2 rounded-full bg-current mr-2"></span>
                          {getStatusText(integration.status)}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </Navbar>
  );
};

const GatedIntegrations = ({ user }) => {
  const handleCalendlyClick = (e) => {
    e.preventDefault();
    // Updated with correct a1 field name for additional details
    const calendlyURL = `https://calendly.com/mike-sallese/meeting-with-mike?name=${
      user.name || ""
    }&email=${user.email || ""}&a1=integrations%20tab%20demo%20inquiry`;
    window.open(calendlyURL, "_blank");
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-4">
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
        <div className="bg-gradient-to-r from-brand-turq to-brand-green px-6 py-5">
          <div className="flex items-center gap-2 mb-2">
            <LockClosedIcon className="h-5 w-5 text-white/90" />
            <p className="text-sm text-white/90 font-medium">
              Team Plan Required
            </p>
          </div>
          <h2 className="text-xl font-semibold text-white">
            Unlock Advanced Integrations with Team Plan
          </h2>
          <p className="mt-1 text-sm text-white/90">
            Upgrade to our team plan to enable these powerful integrations and
            streamline your workflow
          </p>
        </div>

        <div className="p-5 space-y-6">
          {/* Enhanced Supported Integrations */}
          <div className="border-t border-b border-gray-200 py-4">
            <h3 className="text-sm font-medium text-gray-900 mb-3">
              Integrations Included with Team Plan
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
              {/* Integration Items */}
              {[
                {
                  name: "HubSpot",
                  description: "Sync contacts & deals",
                  icon: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24.576 12.28"
                      className="w-full h-full"
                    >
                      <path
                        d="M.744 4.04h.805v1.79h1.698V4.04h.806v4.4h-.806V6.605H1.55V8.43H.744zM6.604 7a.67.67 0 0 1-.667.667.67.67 0 0 1-.667-.667v-1.9h-.763V7a1.43 1.43 0 0 0 2.86 0v-1.9h-.763V7m2.895-1.868a1.17 1.17 0 0 0-.898.36V4.04h-.766v2.692c0 1.008.73 1.698 1.547 1.698.9 0 1.707-.703 1.707-1.65 0-.935-.735-1.65-1.6-1.65zm.02 2.6c-.52 0-.916-.433-.916-.94s.397-.94.916-.94c.44 0 .837.434.837.94s-.397.94-.837.94zm2.858-2.397c0-.385.257-.507.537-.507.226 0 .525.17.72.38l.5-.586c-.25-.336-.757-.568-1.172-.568-.83 0-1.43.483-1.43 1.283 0 1.484 1.826 1.014 1.826 1.845 0 .256-.25.482-.537.482-.452 0-.6-.22-.806-.452l-.556.574c.354.434.794.654 1.32.654.788 0 1.423-.49 1.423-1.252 0-1.65-1.826-1.136-1.826-1.85m3.843-.236c-.82 0-1.547.7-1.547 1.698v2.7h.766v-1.46c.255.25.52.36.898.36.855 0 1.6-.715 1.6-1.65 0-.947-.798-1.65-1.708-1.65zm.136 2.6c-.52 0-.916-.434-.916-.94s.397-.94.916-.94c.44 0 .837.434.837.94s-.397.94-.837.94zm7.21.043c-.452 0-.58-.195-.58-.495V5.903h.702V5.23h-.702v-.886l-.775.348v2.7c0 .7.476 1.038 1.13 1.038a1.57 1.57 0 0 0 .306-.024l.19-.696-.27.012"
                        fill="#45535e"
                      />
                      <path
                        d="M21.543 5.942c-.147-.253-.353-.455-.612-.608a1.64 1.64 0 0 0-.619-.214v-.803a.59.59 0 0 0 .365-.555c0-.337-.27-.6-.607-.6a.61.61 0 0 0-.612.61c0 .247.132.46.357.555v.803c-.18.026-.367.082-.538.17l-2.162-1.642c.016-.057.028-.116.028-.178 0-.373-.302-.676-.676-.676s-.675.303-.675.676.302.676.676.676a.67.67 0 0 0 .347-.098l.14.107 1.94 1.398c-.103.094-.198.2-.275.322a1.5 1.5 0 0 0-.25.809v.06c0 .206.04.4.106.585.06.16.145.305.25.437l-.644.646a.52.52 0 0 0-.701.49.52.52 0 0 0 .522.522.52.52 0 0 0 .522-.522c0-.054-.008-.107-.024-.157l.666-.666a1.71 1.71 0 0 0 .296.162 1.73 1.73 0 0 0 .692.145h.046a1.63 1.63 0 0 0 .79-.2c.26-.142.465-.336.62-.583a1.53 1.53 0 0 0 .24-.824v-.015c0-.297-.07-.57-.213-.82zM20.73 7.34c-.18.2-.388.325-.623.325h-.04c-.134 0-.265-.037-.394-.104a.91.91 0 0 1-.345-.314c-.093-.132-.144-.275-.144-.428V6.77c0-.15.03-.292.1-.426a.92.92 0 0 1 .321-.351c.137-.1.282-.134.445-.134h.015a.9.9 0 0 1 .418.096c.134.072.244.17.33.3a.95.95 0 0 1 .147.42l.004.095a.83.83 0 0 1-.236.569z"
                        fill="#f8761f"
                      />
                    </svg>
                  ),
                },
                {
                  name: "Slack",
                  description: "Team notifications",
                  icon: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 498 127"
                      className="w-full h-full"
                    >
                      <g fill="none">
                        <path
                          d="M159.5 99.5l6.2-14.4c6.7 5 15.6 7.6 24.4 7.6 6.5 0 10.6-2.5 10.6-6.3-.1-10.6-38.9-2.3-39.2-28.9-.1-13.5 11.9-23.9 28.9-23.9 10.1 0 20.2 2.5 27.4 8.2L212 56.5c-6.6-4.2-14.8-7.2-22.6-7.2-5.3 0-8.8 2.5-8.8 5.7.1 10.4 39.2 4.7 39.6 30.1 0 13.8-11.7 23.5-28.5 23.5-12.3 0-23.6-2.9-32.2-9.1m237.9-19.6c-3.1 5.4-8.9 9.1-15.6 9.1-9.9 0-17.9-8-17.9-17.9 0-9.9 8-17.9 17.9-17.9 6.7 0 12.5 3.7 15.6 9.1l17.1-9.5c-6.4-11.4-18.7-19.2-32.7-19.2-20.7 0-37.5 16.8-37.5 37.5s16.8 37.5 37.5 37.5c14.1 0 26.3-7.7 32.7-19.2l-17.1-9.5zM228.8 2.5h21.4v104.7h-21.4zm194.1 0v104.7h21.4V75.8l25.4 31.4h27.4l-32.3-37.3 29.9-34.8h-26.2L444.3 64V2.5zM313.8 80.1c-3.1 5.1-9.5 8.9-16.7 8.9-9.9 0-17.9-8-17.9-17.9 0-9.9 8-17.9 17.9-17.9 7.2 0 13.6 4 16.7 9.2v17.7zm0-45v8.5c-3.5-5.9-12.2-10-21.3-10-18.8 0-33.6 16.6-33.6 37.4 0 20.8 14.8 37.6 33.6 37.6 9.1 0 17.8-4.1 21.3-10v8.5h21.4v-72h-21.4z"
                          fill="#000"
                        />
                        <path
                          d="M27.2 80c0 7.3-5.9 13.2-13.2 13.2C6.7 93.2.8 87.3.8 80c0-7.3 5.9-13.2 13.2-13.2h13.2V80zm6.6 0c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2v33c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V80z"
                          fill="#E01E5A"
                        />
                        <path
                          d="M47 27c-7.3 0-13.2-5.9-13.2-13.2C33.8 6.5 39.7.6 47 .6c7.3 0 13.2 5.9 13.2 13.2V27H47zm0 6.7c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H13.9C6.6 60.1.7 54.2.7 46.9c0-7.3 5.9-13.2 13.2-13.2H47z"
                          fill="#36C5F0"
                        />
                        <path
                          d="M99.9 46.9c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H99.9V46.9zm-6.6 0c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V13.8C66.9 6.5 72.8.6 80.1.6c7.3 0 13.2 5.9 13.2 13.2v33.1z"
                          fill="#2EB67D"
                        />
                        <path
                          d="M80.1 99.8c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V99.8h13.2zm0-6.6c-7.3 0-13.2-5.9-13.2-13.2 0-7.3 5.9-13.2 13.2-13.2h33.1c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H80.1z"
                          fill="#ECB22E"
                        />
                      </g>
                    </svg>
                  ),
                },
                {
                  name: "Google Drive",
                  description: "Document sync",
                  icon: (
                    <svg
                      className="w-full h-full"
                      xmlns="http://www.w3.org/2000/svg"
                      width="2500"
                      height="2166"
                      viewBox="0 0 1443.061 1249.993"
                    >
                      <path
                        fill="#3777e3"
                        d="M240.525 1249.993l240.492-416.664h962.044l-240.514 416.664z"
                      />
                      <path
                        fill="#ffcf63"
                        d="M962.055 833.329h481.006L962.055 0H481.017z"
                      />
                      <path
                        fill="#11a861"
                        d="M0 833.329l240.525 416.664 481.006-833.328L481.017 0z"
                      />
                    </svg>
                  ),
                },
                {
                  name: "Pipedrive",
                  description: "CRM automation",
                  icon: (
                    <svg
                      className="w-full h-full"
                      width="1000"
                      height="274"
                      viewBox="0 0 1000 274"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_4_435)">
                        <path
                          d="M191.44 72.37C201.398 72.37 209.47 64.2977 209.47 54.34C209.47 44.3823 201.398 36.31 191.44 36.31C181.482 36.31 173.41 44.3823 173.41 54.34C173.41 64.2977 181.482 72.37 191.44 72.37Z"
                          fill="#017737"
                        />
                        <path
                          d="M207.63 81.4H175.26V192.46H207.63V81.4Z"
                          fill="#017737"
                        />
                        <path
                          d="M712.37 72.37C722.328 72.37 730.4 64.2977 730.4 54.34C730.4 44.3823 722.328 36.31 712.37 36.31C702.412 36.31 694.34 44.3823 694.34 54.34C694.34 64.2977 702.412 72.37 712.37 72.37Z"
                          fill="#017737"
                        />
                        <path
                          d="M728.56 81.4H696.19V192.46H728.56V81.4Z"
                          fill="#017737"
                        />
                        <path
                          d="M963.96 133.27C963.96 101.57 938.69 78.96 908.32 78.96C874.18 78.96 849.35 102.68 849.35 136.82C849.35 170.52 872.63 194.68 908.32 194.68C938.69 194.68 958.86 175.84 962.63 155H931.37C927.82 164.09 917.4 169.19 907.65 169.19C894.57 168.97 884.82 161.21 881.94 144.36H963.74L963.96 133.27ZM882.6 124.18C886.15 107.78 898.34 103.79 908.31 103.79C918.51 103.79 930.48 108.89 932.03 124.18H882.6Z"
                          fill="#017737"
                        />
                        <path
                          d="M825.41 81.4H858.66L813.43 192.46H779.3L734.07 81.4H768.88L797.03 154.99L825.41 81.4Z"
                          fill="#017737"
                        />
                        <path
                          d="M647.4 99.13C654.94 87.38 666.91 79.84 680.65 79.84C683.09 79.84 686.19 80.06 688.19 80.73V111.1C685.53 110.66 682.65 110.44 679.99 110.44C659.15 110.44 648.29 125.74 648.29 147.68V192.46H616.15V110.66C616.15 108.89 615.48 108.44 613.49 108.44H602.4V81.4H630.33C641.64 81.4 647.4 86.72 647.4 96.7V99.13Z"
                          fill="#017737"
                        />
                        <path
                          d="M596.63 165.41C594.86 165.41 594.41 164.97 594.41 163.19V52.36C594.41 41.28 588.65 36.18 576.9 36.18H548.75V63H559.17C561.16 63 561.83 63.66 561.83 65.44V91.6C557.62 86.06 545.87 78.96 530.13 78.96C498.87 78.96 476.04 102.68 476.04 136.82C476.04 171.62 497.99 194.68 529.24 194.68C546.75 194.68 559.17 185.81 563.82 178.5C564.26 183.38 567.59 192.47 579.56 192.47H608.16V165.43H596.63V165.41ZM535.89 168.07C520.37 168.07 509.07 156.1 509.07 136.81C509.07 118.41 520.6 106 536.11 106C553.62 106 562.49 121.52 562.49 136.59C562.49 160.09 548.31 168.07 535.89 168.07Z"
                          fill="#017737"
                        />
                        <path
                          d="M468.94 133.27C468.94 101.57 443.67 78.96 413.3 78.96C379.16 78.96 354.33 102.68 354.33 136.82C354.33 170.52 377.61 194.68 413.3 194.68C443.67 194.68 463.84 175.84 467.61 155H436.35C432.8 164.09 422.38 169.19 412.63 169.19C399.55 168.97 389.8 161.21 386.92 144.36H468.72L468.94 133.27ZM387.59 124.18C391.14 107.78 403.33 103.79 413.3 103.79C423.5 103.79 435.47 108.89 437.02 124.18H387.59Z"
                          fill="#017737"
                        />
                        <path
                          d="M294.93 78.96C276.75 78.96 263.89 88.49 259.02 96.69C258.13 92.03 255.03 81.39 242.62 81.39H215.58V108.44H226.66C228.65 108.44 229.1 108.88 229.1 110.66V237.9H261.47V188.69L261.25 180.71C265.9 188.47 278.32 194.68 293.39 194.68C325.09 194.68 347.26 171.18 347.26 136.82C347.24 102.23 326.18 78.96 294.93 78.96ZM286.72 168.07C269.21 168.07 260.78 152.33 260.78 137.26C260.78 114.21 274.75 106 287.16 106C302.23 106 314.2 118.64 314.2 137.03C314.21 158.76 299.58 168.07 286.72 168.07Z"
                          fill="#017737"
                        />
                        <path
                          d="M167.68 136.82C167.68 171.18 145.51 194.68 113.81 194.68C98.74 194.68 86.32 188.47 81.67 180.71L81.89 188.69V237.9H49.53V110.66C49.53 108.89 49.09 108.44 47.09 108.44H36V81.4H63.05C75.46 81.4 78.57 92.04 79.45 96.7C84.33 88.5 97.18 78.97 115.36 78.97C146.62 78.96 167.68 102.23 167.68 136.82ZM134.65 137.04C134.65 118.64 122.68 106.01 107.6 106.01C95.19 106.01 81.22 114.21 81.22 137.27C81.22 152.34 89.64 168.08 107.16 168.08C120.02 168.07 134.65 158.76 134.65 137.04Z"
                          fill="#017737"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4_435">
                          <rect width="1000" height="274" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  ),
                },
              ].map((integration, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center p-3 border border-gray-200 rounded-lg bg-white"
                >
                  <div className="w-20 h-10 flex items-center justify-center mb-2">
                    {integration.icon}
                  </div>
                  <p className="text-sm text-gray-900 font-medium text-center">
                    {integration.name}
                  </p>
                  <p className="text-xs text-gray-500 mt-1 text-center">
                    {integration.description}
                  </p>
                </div>
              ))}
            </div>
            <p className="text-xs text-gray-500 mt-3 text-center">
              More integrations coming soon. Have a specific tool in mind? Let
              us know during the demo.
            </p>
          </div>

          {/* CTA */}
          <div className="text-center pt-1">
            <button
              onClick={handleCalendlyClick}
              className="w-full px-6 py-3 text-base font-semibold rounded-lg shadow-md text-white transition-all duration-300 bg-brand-turq hover:bg-brand-green hover:shadow-lg transform hover:-translate-y-0.5"
            >
              <div className="flex items-center justify-center space-x-2">
                <span>Schedule Your Team Plan Demo</span>
                <ChevronRightIcon className="h-5 w-5" />
              </div>
            </button>
          </div>

          {/* More Features */}
          <div>
            <h3 className="text-sm font-medium text-gray-900 mb-2">
              Additional Team Plan Benefits
            </h3>
            <p className="text-sm text-gray-600 mb-3">
              Integrations are just the start. The team plan unlocks a complete
              suite of collaboration and management features designed for
              growing teams.
            </p>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
              {[
                {
                  icon: UserGroupIcon,
                  title: "Sharing Rules",
                  desc: "Auto-share meetings based on domains & attendees",
                },
                {
                  icon: ChartBarIcon,
                  title: "Admin Dashboard",
                  desc: "Monitor team's external meetings",
                },
                {
                  icon: CollectionIcon,
                  title: "Collections",
                  desc: "Auto-sort meetings by calendar & domain",
                },
                {
                  icon: CogIcon,
                  title: "Team Controls",
                  desc: "Full team admin controls",
                },
              ].map((feature, idx) => (
                <div key={idx} className="bg-gray-50 rounded-lg p-3">
                  <feature.icon className="h-4 w-4 text-brand-turq mb-1" />
                  <h4 className="text-xs font-medium text-gray-900">
                    {feature.title}
                  </h4>
                  <p className="text-xs text-gray-500 mt-0.5">{feature.desc}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integrations;
