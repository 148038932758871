import moment from "moment";
import { useEffect } from "react";

export const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  });
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const getFormattedTranscript = (meeting) => {
  const meetingDate = moment(meeting?.CreatedAt || new Date());

  return meeting.transcriptItems
    .map((item) => {
      const time = moment(meetingDate).add(
        item.itemStart - (meeting.recordingTimeOffset || 0),
        "seconds"
      );

      const timeStamp = time.format("HH:mm:ss");
      const formattedDate = time.format("MM/DD/YYYY");
      return `[${formattedDate} ${timeStamp}] ${item.speaker}: ${item.transcript}`;
    })
    .join("\n");
};
