import React from "react";
import { CalendarIcon, ClockIcon, UsersIcon } from "@heroicons/react/outline";
var moment = require("moment");

const Attributes = (props) => {
  const humanizeDuration = require("humanize-duration");
  return (
    <div className="bg-white mt-4 mx-3 py-3 px-5 rounded-md border border-gray-200">
      {/* Card Header */}
      <div className="flex items-center">
        <span className="leading-6 font-medium text-gray-900">Attributes</span>
      </div>
      {/* Card body */}
      <div className="grid grid-cols-2 gap-2 pt-3">
        <div className="flex flex-col">
          {/* Date */}
          <div className="flex">
            <CalendarIcon
              className="h-4 w-4 text-gray-700"
              aria-hidden="true"
            />
            <span className="pl-2 text-sm font-semibold text-gray-700">
              Date
            </span>
          </div>
          <span className="pl-2 pt-2 text-sm text-gray-700">
            {moment(new Date(props.meetingCreatedAt)).format("MM/DD/YY")}
          </span>
          {/* Duration */}
          {props.meetingDuration && isFinite(props.meetingDuration) && (
            <div>
              <div className="flex pt-3">
                <ClockIcon
                  className="h-4 w-4 text-gray-700"
                  aria-hidden="true"
                />
                <span className="pl-2 text-sm font-semibold text-gray-700">
                  Duration
                </span>
              </div>
              <span className="pl-2 pt-2 text-sm text-gray-700">
                {humanizeDuration(props.meetingDuration, {
                  maxDecimalPoints: 0,
                  units: ["h", "m"],
                })}
              </span>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <div className="flex">
            <UsersIcon className="h-4 w-4 text-gray-700" aria-hidden="true" />
            <span className="pl-2 text-sm font-semibold text-gray-700">
              Attendees
            </span>
          </div>
          <div className="flex flex-col pl-2 pt-2 text-sm text-gray-700">
            {props.attendees.map((speaker, index) => {
              return <span key={index}>{speaker}</span>;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attributes;
