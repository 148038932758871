import React, { useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Switch } from "@headlessui/react";
import CancelModal from "./CancelModal";
import TeamSettings from "./TeamSettings";
import { Dialog, Transition } from "@headlessui/react";

import Navbar from "../Navbar";

import "../../index.css";
import { sendSlackMessage } from "../../slack";

import moment from "moment";
import Rules from "../Rules";

const Settings = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [userEmail, setUserEmail] = useState(null);
  const [logout, setLogout] = useState(false);

  const [user, setUser] = useState(null);
  const [tabs, setTabs] = useState([
    { name: "Account", current: true, path: "/settings" },
    { name: "Meeting Settings", current: false, path: "/settings/meeting" },
    { name: "Team", current: false, path: "/settings/team" },
    { name: "Sharing", current: false, path: "/settings/sharing-rules" },
  ]);
  const [autoRecordEnabled, setAutoRecordEnabled] = useState(true);
  const [extensionOn, setExtensionOn] = useState(false);
  const [generateMeetingNotes, setGenerateMeetingNotes] = useState(false);
  const [notifyParticipants, setNotifyParticipants] = useState(false);
  const [openTabAfterMeeting, setOpenTabAfterMeeting] = useState(false);
  const [settingShareWithAttendees, setSettingShareWithAttendees] =
    useState(false);

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [upgradeSuccessModalOpen, setUpgradeSuccessModalOpen] = useState(false);

  useEffect(() => {
    if (props.tab) {
      setCurrentTab(tabs, setTabs, props.tab);
    }

    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get("upgradeSuccess");
    if (paramValue === "true") {
      setUpgradeSuccessModalOpen(true);
    }
  }, []);

  const updateSetting = async (switchState, settingName) => {
    const updateSettingsResponse = await fetch(
      `https://backend.scribbl.co/user/setting`,
      {
        method: "POST",
        body: JSON.stringify({
          email: userEmail,
          settingName: settingName,
          settingValue: switchState,
        }),
        credentials: "include",
      }
    );
    const respJson = await updateSettingsResponse.json();
    console.log("resp", respJson);
    setUser(respJson);
  };

  const setCurrentTab = (tabs, setTabs, tabName) => {
    let newTabs = [...tabs];
    newTabs.forEach((t) => {
      if (tabName === t.name) {
        t.current = true;
      } else {
        t.current = false;
      }
    });
    setTabs(newTabs);

    const currentTab = newTabs.find((tab) => tab.current);
    if (currentTab) {
      navigate(currentTab.path);
    }
  };

  const updatePaymentMethod = async () => {
    const updatePaymentMethodResponse = await fetch(
      `https://backend.scribbl.co/update-payment`,
      {
        method: "POST",
        credentials: "include",
      }
    );
    const respJson = await updatePaymentMethodResponse.json();
    if (respJson.return_url) {
      window.location.href = respJson.url;
    }
  };

  useEffect(() => {
    const getUserInfo = async () => {
      const userInfoResp = await fetch(`https://backend.scribbl.co/auth/userinfo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (userInfoResp.status === 200) {
        const userInfoJson = await userInfoResp.json();
        if (userInfoJson && userInfoJson.email) {
          setUser(userInfoJson);
        }
      }
    };

    if (userEmail) {
      getUserInfo();
    }
  }, [userEmail]);

  useEffect(() => {
    if (user && user.autoRecordEnabled !== autoRecordEnabled) {
      console.log("updating are to ", autoRecordEnabled);
      updateSetting(autoRecordEnabled, "autoStartRecording");
    }
  }, [autoRecordEnabled]);

  useEffect(() => {
    if (user && user.settingOnByDefault !== extensionOn) {
      console.log("updating setting to", extensionOn);
      updateSetting(extensionOn, "settingOnByDefault");
    }
  }, [extensionOn]);

  useEffect(() => {
    if (user && user.settingPostToChat !== notifyParticipants) {
      updateSetting(notifyParticipants, "settingPostToChat");
    }
  }, [notifyParticipants]);

  useEffect(() => {
    if (user && user.settingOpenTab !== openTabAfterMeeting) {
      updateSetting(openTabAfterMeeting, "settingOpenTab");
    }
  }, [openTabAfterMeeting]);

  useEffect(() => {
    if (user && user.settingAIMeetingNotes !== generateMeetingNotes) {
      updateSetting(generateMeetingNotes, "settingAIMeetingNotes");
    }
  }, [generateMeetingNotes]);

  useEffect(() => {
    if (user && user.settingShareWithAttendees !== settingShareWithAttendees) {
      updateSetting(settingShareWithAttendees, "settingShareWithAttendees");
    }
  }, [settingShareWithAttendees]);

  useEffect(() => {
    if (user) {
      console.log(user);
      setAutoRecordEnabled(user.autoStartRecording);
      setExtensionOn(user.settingOnByDefault);
      setNotifyParticipants(user.settingPostToChat);
      setGenerateMeetingNotes(user.settingAIMeetingNotes);
      setOpenTabAfterMeeting(user.settingOpenTab);
      setSettingShareWithAttendees(user.settingShareWithAttendees);
      setLoading(false);
    }
  }, [user]);

  let renewalDate = null;
  if (user?.subscription && user.subscription.accountType > 0 && user.subDate) {
    let d = moment(user.subscription.subDate);
    renewalDate = d.format("MMMM Do, YYYY");
  }

  const renderAccountSettings = () => {
    return (
      <div className="bg-white border border-gray-200 rounded-md p-5">
        <div className="text-gray-700 text-2xl font-medium mb-6">
          Account Settings
        </div>
        {user && (
          <div>
            <div className="mt-7 text-gray-700 text-sm">
              <div className="font-bold">Name: </div>
              <div className="mt-2">{user.name}</div>
            </div>
            <div className="mt-7 text-gray-700 text-sm">
              <div className="font-bold">Email Address: </div>
              <div className="mt-2">{user.email}</div>
            </div>

            {user.org.ID !== 0 && (
              <div className="mt-7 text-gray-700 text-sm">
                <div className="font-bold">Team: </div>
                <div className="mt-2">{user.org.name}</div>
              </div>
            )}

            <button
              onClick={async () => {
                const logoutReq = await fetch(
                  `https://backend.scribbl.co/auth/logout`,
                  {
                    method: "POST",
                    credentials: "include",
                  }
                );
                if (logoutReq.status === 200) {
                  navigate("/");
                }
              }}
              className="mt-7 rounded-md bg-white px-9 py-1.5 text-sm font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Log Out
            </button>
            <div className="border-t border-gray-200 mt-7"></div>

            <div className="text-gray-700 text-2xl font-medium mt-7">
              Subscription
            </div>
            <div className="mt-7 text-gray-700 text-sm">
              <div className="font-bold">Monthly Meetings Remaining:</div>
              <div className="mt-2">
                {!user.subscription ? user.tokensRemaining : "Unlimited"}
              </div>
            </div>

            <div className="mt-7 text-gray-700 text-sm">
              <div className="font-bold">Account Type:</div>
              <div className="mt-2 bg-gray-100 inline-block px-4 py-2 rounded items-center">
                <div className="inline-flex items-center">
                  {user.subscription ? (
                    <span>
                      {user.subscription.accountType === 1 && (
                        <span>Scribbl Pro Plan (monthly)</span>
                      )}
                      {user.subscription.accountType === 2 && (
                        <span>Scribbl Pro Plan (annual)</span>
                      )}
                      {user.subscription.accountType === 3 && (
                        <span>Scribbl Team Plan</span>
                      )}
                      {user.subscription.accountType === 4 && (
                        <span>Scribbl Team Plan</span>
                      )}
                    </span>
                  ) : (
                    "Lite"
                  )}
                </div>
                {!user.subscription ? (
                  <div className="ml-12 inline-flex items-center px-6 py-1.5 shadow-sm text-sm font-medium rounded-md text-white bg-brand-green-lighter1 hover:bg-brand-green-darker2 focus:bg-brand-green-darker2">
                    <a
                      id="account-button"
                      href={`/pricing`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn w-100"
                      onClick={() => {
                        sendSlackMessage(
                          "useractivity",
                          `${user.email} clicked on upgrade button from account settings page.`
                        );
                      }}
                    >
                      Upgrade to PRO
                    </a>
                  </div>
                ) : (
                  <div className="inline-flex">
                    {user.subscription.ownerID === user.ID && (
                      <div>
                        {user.subscription.accountType !== 0 ? (
                          <div>
                            <button
                              type="button"
                              className="ml-12 inline-flex items-center rounded-md bg-white px-6 py-1.5 text-sm font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() => {
                                updatePaymentMethod();
                              }}
                            >
                              Update Payment Methods
                            </button>
                            {user.subscription.accountType !== 3 &&
                              user.subscription.accountType !== 4 && (
                                <button
                                  type="button"
                                  className="ml-3 inline-flex items-center px-6 py-1.5 shadow-sm text-sm font-medium rounded-md text-white bg-red-400 hover:bg-red-800 focus:bg-brand-green-darker2"
                                  onClick={() => {
                                    setCancelModalOpen(true);
                                    sendSlackMessage(
                                      "useractivity",
                                      `${user.email} clicked on cancel subscription from account settings page.`
                                    );
                                  }}
                                >
                                  Cancel Subscription
                                </button>
                              )}
                          </div>
                        ) : (
                          <div className="ml-6 text-xs inline-flex ml-2">
                            <div className="flex flex-col text-center text-gray-700">
                              <div>Cancelled: </div>
                              <div>
                                Expires on{" "}
                                {moment(
                                  new Date(user.subscription.subDate)
                                ).format("MM/DD/YYYY")}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              {renewalDate && (
                <div className="mt-7 text-gray-700 text-sm">
                  <div className="font-bold">Subscription Valid Until:</div>
                  <div className="mt-2">{renewalDate}</div>
                  <div className="mt-1 text-xs text-gray-500">
                    {user.subscription.accountType === 3 ||
                    user.subscription.accountType === 4
                      ? "Your team subscription will automatically renew on this date. Please contact us to cancel, or change the quantity of the team plan"
                      : "Your subscription will automatically renew on this date unless cancelled"}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <CancelModal
          open={cancelModalOpen}
          setOpen={setCancelModalOpen}
          onConfirm={async () => {
            console.log("Going forward with cancel");
            setLoading(true);
            const cancelSubscriptionURL = "https://backend.scribbl.co/user/cancel";
            const cancel_fetch_options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                subscriptionID: user.subscription.ID,
              }),
              credentials: "include",
            };

            const result = await fetch(
              cancelSubscriptionURL,
              cancel_fetch_options
            );

            if (result.status === 200) {
              const newUser = structuredClone(user);
              newUser.subscription.accountType = 0;
              setUser(newUser);
              setLoading(false);
              window
                .open("https://form.typeform.com/to/kW0Ac4f1", "_blank")
                .focus();
            } else {
              window.alert(
                "There was a unexpected problem cancelling your subscription. We're sorry for the inconvenience. Please reach out to mike@scribbl.co."
              );
              setLoading(false);
            }
          }}
          onReject={() => {
            console.log("Not cancelling");
          }}
        />
      </div>
    );
  };

  const renderMeetingSettings = () => {
    return (
      <div className="bg-white border border-gray-200 rounded-md p-5">
        <div>
          <div className="text-gray-700 text-2xl font-medium mb-6">
            General In Meeting Settings
          </div>
          <div>
            <div className="mt-7 space-y-4">
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="share-with-attendees"
                    aria-describedby="share-with-attendees-description"
                    name="share-with-attendees"
                    type="checkbox"
                    className="cursor-pointer h-4 w-4 rounded border-gray-300 text-brand-green focus:ring-brand-green"
                    checked={settingShareWithAttendees}
                    onChange={() =>
                      setSettingShareWithAttendees(!settingShareWithAttendees)
                    }
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="share-with-attendees"
                    className="font-medium text-gray-700"
                  >
                    Automatically share meeting notes with all attendees
                  </label>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="extension-on"
                    aria-describedby="extension-on-description"
                    name="extension-on"
                    type="checkbox"
                    className="cursor-pointer h-4 w-4 rounded border-gray-300 text-brand-green focus:ring-brand-green"
                    checked={extensionOn}
                    onChange={() => setExtensionOn(!extensionOn)}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="extension-on"
                    className="font-medium text-gray-700"
                  >
                    Set extension to ON by default
                  </label>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="generate-meeting-notes"
                    aria-describedby="generate-meeting-notes-description"
                    name="generate-meeting-notes"
                    type="checkbox"
                    className="cursor-pointer h-4 w-4 rounded border-gray-300 text-brand-green focus:ring-brand-green"
                    checked={generateMeetingNotes}
                    onChange={() =>
                      setGenerateMeetingNotes(!generateMeetingNotes)
                    }
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="generate-meeting-notes"
                    className="font-medium text-gray-700"
                  >
                    Generate Meeting Notes & Action Items with GPT
                  </label>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="notify"
                    aria-describedby="notify-description"
                    name="notify"
                    type="checkbox"
                    className="cursor-pointer h-4 w-4 rounded border-gray-300 text-brand-green focus:ring-brand-green"
                    checked={notifyParticipants}
                    onChange={() => setNotifyParticipants(!notifyParticipants)}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label htmlFor="notify" className="font-medium text-gray-700">
                    Notify participants that Scribbl is recording the call
                  </label>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="open-tab"
                    aria-describedby="open-tab-description"
                    name="open-tab"
                    type="checkbox"
                    className="cursor-pointer h-4 w-4 rounded border-gray-300 text-brand-green focus:ring-brand-green"
                    checked={openTabAfterMeeting}
                    onChange={() =>
                      setOpenTabAfterMeeting(!openTabAfterMeeting)
                    }
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="open-tab"
                    className="font-medium text-gray-700"
                  >
                    Open Scribbl in a new tab after meeting (non-video meetings
                    only)
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200 mt-7"></div>
        <div className="my-7">
          <div className="text-gray-700 text-2xl font-medium">
            Video Recording Settings
          </div>
          <div>
            <div className="mt-7 flex items-center justify-between">
              <Switch.Group
                as="div"
                className="flex items-center justify-between"
              >
                <Switch.Label
                  as="span"
                  className="mr-3 text-sm font-medium text-gray-700"
                  passive
                >
                  Automatically prompt me to record video in the meeting:
                </Switch.Label>
                <Switch
                  checked={autoRecordEnabled}
                  onChange={() => setAutoRecordEnabled(!autoRecordEnabled)}
                  className={`${
                    autoRecordEnabled ? "bg-brand-green" : "bg-gray-200"
                  } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-green focus:ring-offset-2`}
                >
                  <span
                    aria-hidden="true"
                    className={`${
                      autoRecordEnabled ? "translate-x-5" : "translate-x-0"
                    } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
              </Switch.Group>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderTeamSettings = () => {
    return <TeamSettings userEmail={userEmail} user={user} />;
  };
  const renderSharingRules = () => {
    return <Rules user={user} />;
  };
  const renderContent = () => {
    switch (props.tab) {
      case "Account":
        return renderAccountSettings();
      case "Meeting Settings":
        return renderMeetingSettings();
      case "Team":
        return renderTeamSettings();
      case "Sharing":
        return renderSharingRules();
      default:
        return renderAccountSettings();
    }
  };
  return (
    <Navbar
      loading={loading}
      setUserEmail={setUserEmail}
      setUser={setUser}
      logout={logout}
      setLogout={setLogout}
      setLoading={setLoading}
    >
      <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex">
        <div className="flex-1 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              {renderContent()}
            </div>
          </div>
        </div>
      </main>

      <Transition.Root show={upgradeSuccessModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={setUpgradeSuccessModalOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center">
                      <img
                        className="h-30 w-auto"
                        src="/great-gatsby.gif"
                        alt="Dragonfly logo"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-700"
                      >
                        <div className="text-xl mb-2">Thank you!</div>We hope
                        you enjoy the premium version of Scribbl.
                      </Dialog.Title>
                      <div className="mt-3">
                        <p className="text-sm text-gray-700">
                          For any help, please email mike@scribbl.co or
                          john@scribbl.co.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-brand-green px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-green-darker2"
                      onClick={() => setUpgradeSuccessModalOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Navbar>
  );
};
export default Settings;
